import { Component, Inject, OnInit } from "@angular/core";
import { ViewdescripeComponent } from "../viewdescripe/viewdescripe.component";
import { MAT_DIALOG_DATA, MatDialog, Sort } from "@angular/material";
import { GlobalserviceService } from "../providers/globalservice.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { log } from "console";
import { element } from "protractor";
import { EmployeeTimesheetViewdescriptionComponent } from "../employee-timesheet-viewdescription/employee-timesheet-viewdescription.component";

@Component({
  selector: "app-time-sheet",
  templateUrl: "./time-sheet.component.html",
  styleUrls: ["./time-sheet.component.css"],
})
export class TimeSheetComponent implements OnInit {
  projectId: number = 0;
  projectList: any = [];
  taskList: any;
  projectStatus: any = "0";
  timesheetDate: any;
  p: any;
  user_type: string;
  search: any;
  userType: any;
  isData: boolean;
  taskId: number = 0;
  timesheetreport: any = [];
  isproject: boolean;
  nodata = false;
  loader = false;
  public searchFilter: any = "";
  query: any = "";
  beforepage: any;
  mySelect: any = 5;
  employeelist: any = [];
  employeeId: any;
  totalTime: any;
  projectlist: any;
  selectedemployeeId: any;
  startDate: any;
  endDate: any;
  disable_project: boolean = true;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  tasktotalTime: any;
  today: any;

  employee_required: boolean = false;

  constructor(
    private gs: GlobalserviceService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.data;
    console.log("data:", this.data);

    console.log("taskId:", this.data.taskId);
    console.log("empId:", this.data.empolyeeTasks[0].employee.employeeId);

    this.taskId = this.data.taskId;
    this.employeeId = this.data.empolyeeTasks[0].employee.employeeId;

    // console.log("empId:", this.data[0].empolyeeTasks.employee.employeeId);

    this.gs.taskDetailByTaskEmployee(this.taskId, this.employeeId).subscribe(
      (res) => {
        console.log("res", res);
        console.log(
          "taskId",
          res[0].task.taskId,
          "empId",
          res[0].employee.employeeId
        );

        this.timesheetreport = res;

        const detailTaskId = res[0].task.taskId;
        const detailEmployeeId = res[0].employee.employeeId;
        if (
          this.taskId === detailTaskId &&
          this.employeeId === detailEmployeeId
        ) {
          this.nodata = false;
          this.timesheetreport = res;

          // this.nodata =  true;

          this.totalTime = res[0].totalTime;
          console.log("this.timesheetreport", this.timesheetreport[0]);
          console.log("totaltime");
        }
      },
      (error) => {
        //  this.loader = false;
        // this.timesheetreport.length = 0;
        if (this.timesheetreport.length <= 0) {
          this.nodata = true;
        }
        console.log("error");
        // if(error.status == 404){

        //   console.log("404")
        // }
      }
    );
    this.today = new Date();
    this.today = this.datepipe.transform(this.today, "YYYY-MM-dd");

    this.gs.getResource().subscribe(
      (d: any) => {
        //this.loader = false;
        this.employeelist = d;
      },
      (error) => {
        //  this.loader = false;
        //  this.nodata = true;
        console.log("error");
        // if(error.status == 404){
        //   this.nodata = true;
        //   console.log("404")
        // }
      }
    );
  }

  getTask() {
    this.employee_required = false;
    this.disable_project = false;
    console.log(this.disable_project);
    console.log("employeeId" + this.selectedemployeeId);
    if (this.selectedemployeeId == undefined) {
      this.projectlist.length = 0;
    } else {
      this.gs
        .getprojectbyEmployeeId(this.selectedemployeeId)
        .subscribe((data: any) => {
          console.log(data);
          this.projectlist = data;
        });
    }
  }
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  changefirstpage() {
    this.p = 1;
  }

  datechange(event: any) {
    console.log(event);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return;
    }
    if (i.task.taskStatusName == "To Do") {
      return "#ffc107";
    }
    if (i.task.taskStatusName == "Completed") {
      return "#28a745";
    }
    if (i.task.taskStatusName == "In Progress") {
      return "#f44336";
    }
  }

  approvalStatus(i) {
    if (i.approval.approvalStatusName == null) {
      return;
    }
    if (i.approval.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approval.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approval.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  description(e) {
    const dialogRef = this.dialog.open(
      EmployeeTimesheetViewdescriptionComponent,
      {
        width: "500px",
        data: e, // Pass the entire object 'e' to the dialog
      }
    );
  }
  sortData(sort: Sort) {
    const data = this.timesheetreport.slice();
    if (!sort.active || sort.direction === "") {
      this.timesheetreport = data;
      return;
    }

    this.timesheetreport = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "employeeName":
          return this.compare(
            a.employee.employeeName,
            b.employee.employeeName,
            isAsc
          );
        case "projectName":
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case "taskName":
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "workedHours":
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case "taskStatusName":
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );
        case "approvalStatusName":
          return this.compare(
            a.approvalStatusName,
            b.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  remove() {
    console.log("remove");
    this.dialog.closeAll();
  }
}
