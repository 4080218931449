import { Injectable, Output, EventEmitter } from "@angular/core";
import { globalTimeOut, url } from "./properties";
import { Observable, Subject } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError, timeout } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
// import { HttpErrorResponse } from '@angular/common/http/src/response';
import Swal from "sweetalert2";

const httpOptionsPlain = {
  headers: new HttpHeaders({
    Accept: "text/plain",
    "Content-Type": "text/plain",
  }),
  responseType: "text",
};

@Injectable({
  providedIn: "root",
})
export class GlobalserviceService {


 
  organzationid: any;

  @Output() collapsedEvent = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {
    const data = JSON.parse(localStorage.getItem("UserData"));
    console.log(data);
    if (data != undefined) {
      this.organzationid = data.organization.organizationId;
      console.log("organzationid" + this.organzationid);
    }
  }
  collapsed = false;
  dataId: any;
  toggleCollapsed() {
    this.collapsed = !this.collapsed;
    return this.collapsedEvent.emit(this.collapsed);
  }

  // getcatogory() {
  //   return this.http.get(`http://${this.organzationid}92.${this.organzationid}68.${this.organzationid}.${this.organzationid}60/vasantabhavan_ae/api/BranchCategorys/20`)
  //     .pipe(map(res => res), timeout((globalTimeOut)));
  // }

  //---------------------getmethod sample---------------------------------
  // userType
  getUserType() {
    return this.http.get(`${url}user_type`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // resource+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  createResource(data): Observable<any> {
    return this.http.post(`${url}employee`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  getempolyeeNumber(employeeNumber: any, employeeid: any) {
    return this.http
      .get(`${url}employee/empolyeenumber/${employeeNumber}/${employeeid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getempolyeeEmail(emailid: any, employeeid: any) {
    return this.http.get(`${url}employee/email/${emailid}/${employeeid}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getprojectcode(projectCode: any, projectid: number) {
    return this.http
      .get(
        `${url}project/projectcode/${projectCode}/${projectid}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getprojectName(projectName: any, projectid: number) {
    return this.http
      .get(
        `${url}project/projectname/${projectName}/${projectid}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getResource(): Observable<any> {
    return this.http
      .get(`${url}employee_by_organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmp_Project(empProId): Observable<any> {
    return this.http.get(`${url}employee_project/${empProId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getRole(): Observable<any> {
    return this.http
      .get(`${url}user_role_by_organziation/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getRoleName(rolename: any, userroleId: number) {
    return this.http
      .get(
        `${url}user_role/userrolename/${rolename}/${userroleId}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getCommonTaskName(): Observable<any> {
    return this.http
      .get(`${url}common_task/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getCommonTaskNameById(id): Observable<any> {
    return this.http
      .get(
        `${url}common_task/organization/commontasktype/${this.organzationid}/${id}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getCommontaskName(commontaskname: any, genericTaskid: number) {
    return this.http
      .get(
        `${url}common_task/commontaskname/${commontaskname}/${genericTaskid}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getResourceById(id) {
    return this.http.get(`${url}employee/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getResourceImageById(id) {
    return this.http.get(`${url}resource_profile/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  public getResourceByOrganization(id): Observable<any> {
    return this.http.get(`${url}resource_by_organization/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getresourcebyproject(id, orgId) {
    return this.http.get(`${url}resource_by_project/${id}/${orgId}`);
  }
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++ team +++++++++++++++++++++++++++++++++++++++++++++++
  getTeamByOrganization(id) {
    return this.http.get(`${url}team_by_organization/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTeam() {
    return this.http.get(`${url}team`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTeamById(id) {
    return this.http.get(`${url}team/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  //  get team resource
  getTeamLeader() {
    return this.http.get(`${url}team_by_teamleader`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTeamResource() {
    return this.http.get(`${url}team_by_resource`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // project++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getProjectByOrganization(id) {
    return this.http.get(`${url}project_by_organization/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getProjectList() {
    return this.http
      .get(`${url}project_by_organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpProjectList(id) {
    return this.http.get(`${url}project_by_empolyee/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getprojectByID(id) {
    return this.http.get(`${url}project/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getprojectif_task_(taskId, orgId) {
    return this.http
      .get(`${url}project_by_resource/task/${taskId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut),
        catchError(this.handleError)
      );
  }
  gettaskByProject_if_resource_task_present(proId, resId, orgId) {
    return this.http
      .get(`${url}task/project/resource/${proId}/${resId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  GetResourceProjects(resId, orgId) {
    return this.http.get(`${url}project_by_resource/${resId}/${orgId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // +++++++++++++++++++++++++++++++++++++deliverable+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getDeliverables(): Observable<any> {
    return this.http.get(`${url}deliverable`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getDeliverablesById(id): Observable<any> {
    return this.http.get(`${url}deliverable/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getDeliverableOnlyIf_TaskPresent(orgId) {
    return this.http.get(`${url}deliverable_by_organization/task/${orgId}`);
  }
  getdeliverablebyProjectId(id, orgId) {
    return this.http.get(`${url}deliverable_by_project/${id}/${orgId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getDeliverableByOrganization(id) {
    return this.http.get(`${url}deliverable_by_organization/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getdeliverableByProject(proId, OrgId) {
    return this.http.get(`${url}deliverable_by_project/${proId}/${OrgId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getDeliverableByProjectId_if_Task(proId, orgId) {
    return this.http
      .get(`${url}deliverable_by_project/task/${proId}/${orgId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  //timeput ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getdata(): Observable<any> {
    return this.http.get(`${url}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // profile ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getProfileData(id) {
    return this.http.get(`${url}resource/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // task++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  getTaskList() {
    return this.http
      .get(`${url}task_by_organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmployeeTask() {
    return this.http.get(`${url}employee_task`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getemployeetask(employeeId: any) {
    return this.http
      .get(`${url}employee_task_by_employee/limit/${employeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmployeetaskvalidate(empolyeeId: any, taskId: any) {
    return this.http
      .get(`${url}employee_task/empolyeetask/${empolyeeId}/${taskId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmployeeTaskByEmpolyee(employeeId) {
    return this.http.get(`${url}employee_task_by_employee/${employeeId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getEmployeeTaskById(empTaskId) {
    return this.http.get(`${url}employee_task/${empTaskId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getTask(taskId) {
    return this.http.get(`${url}task/${taskId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getEmpTimeLogs(empId) {
    return this.http.get(`${url}taskDetail_by_employee/${empId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTaskById(id) {
    return this.http.get(`${url}task_by_project/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTaskByTaskId(id) {
    return this.http.get(`${url}task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTaskDetailByTaskId(id) {
    return this.http.get(`${url}taskDetail_by_task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTaskDetailByTaskIdAndEmployee(id, employee) {
    return this.http
      .get(`${url}taskDetail_by_task_and_employee/${id}/${employee}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByEmployee(empId, projectId) {
    return this.http
      .get(`${url}task_by_empolyee/project/${empId}/${projectId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpByPro(projectId, empId, approverStatus) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/project/approvalStatus/${projectId}/${empId}/${approverStatus}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpByProject(projectId, empId) {
    return this.http
      .get(`${url}taskDetail_by_employee/project/${projectId}/${empId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getMenuList() {
    return this.http.get(`${url}menu`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getProjectsIfDeliverable(id) {
    return this.http.get(`${url}project_by_organization/deliverable/${id}`);
  }
  getTaskbyDeliverable_id(delId, orgId) {
    return this.http.get(`${url}task_by_deliverable/${delId}/${orgId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // resource's tasklist
  getresourceTasks(resId, orgId) {
    return this.http.get(`${url}task_by_resource/${resId}/${orgId}`);
    // .pipe(map(res => res), timeout(globalTimeOut), catchError(this.handleError));
  }
  getTaskByDeliverable(delId) {
    return this.http.get(`${url}deliverable_by_task/${delId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  // profile
  getResourceProfilePic(resId) {
    return this.http.get(`${url}resource_profile/${resId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetListById(id) {
    return this.http.get(`${url}list-type-values/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetProjectStage() {
    return this.http
      .get(`${url}project_stage/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  GetCommonTaskEntry() {
    return this.http
      .get(`${url}common_task/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetGenericTaskEntry() {
    return this.http
      .get(`${url}generictask/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetGenericTaskEntryById(id) {
    return this.http.get(`${url}generictaskdetail/employee/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  Get_Customer() {
    return this.http
      .get(`${url}customer/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getCustomerName(data) {
    return this.http.post(`${url}customer/customernamevalidate`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getProjectStage() {
    return this.http
      .get(`${url}project_stage/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getApprovers() {
    return this.http
      .get(`${url}approvers/organziation/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getApproverRuleName(rulename: any, approverId: number) {
    return this.http
      .get(
        `${url}approvers/approversrulename/${rulename}/${approverId}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getApproverPolicy() {
    return this.http
      .get(`${url}approverspolicy/organziation/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getapproverpolicyById(approversPolicyId: any) {
    return this.http.get(`${url}approverspolicy/${approversPolicyId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getApproverPolicyName(approverspolicyname: any, approverspolicyId: any) {
    return this.http
      .get(
        `${url}approverspolicy/approverspolicyname/${approverspolicyname}/${approverspolicyId}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getApproverPolicyDefault() {
    return this.http
      .get(`${url}approverspolicy/default/organziation/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  createapproverHiearchy(approverpolicy: any) {
    return this.http.post(`${url}approverspolicy`, approverpolicy).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  updateapproverHiearchy(approverpolicyupdate: any) {
    return this.http
      .put(
        `${url}approverspolicy/${approverpolicyupdate.approversPolicyId}`,
        approverpolicyupdate
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogByApproverhierarchy(empId: any) {
    return this.http
      .get(`${url}taskDetail_by_approver/taskdetail/${empId}/3`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpApproversById(empId) {
    return this.http.get(`${url}approvers/apporver/${empId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getEmpApprovers(empId) {
    return this.http.get(`${url}approvers/employee/${empId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getApproversById(approverId) {
    return this.http.get(`${url}approvers/${approverId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getEmployeeByApprover() {
    return this.http.get(`${url}employeeapprovers`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getProjectApprover(organziationId) {
    return this.http
      .get(`${url}projectapprovers_by_organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getprojectApproversByProject(id: any) {
    return this.http.get(`${url}projectapprovers_by_project/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  getprojectApprovers(project: any) {
    return this.http.get(`${url}projectapprovers/validate/${project}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  createProjectApprover(projectapprover: any) {
    return this.http.post(`${url}projectapprovers`, projectapprover).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  updateProjectApprover(projectapproverupdate: any, projectApproverId: any) {
    return this.http
      .put(`${url}projectapprovers/${projectApproverId}`, projectapproverupdate)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskReportByProject(timesheetDate: any, projectId: number) {
    return this.http
      .get(`${url}taskDetail_report_by_project/${timesheetDate}/${projectId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskReportByTask(timesheetDate: any, taskId: number) {
    return this.http
      .get(`${url}taskDetail_report_by_task/${timesheetDate}/${taskId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogByApprover(data) {
    return this.http
      .post(`${url}taskDetail_by_employees/approvalStatus/3`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskReportByEmpolyee(timesheetDate) {
    return this.http
      .get(`${url}taskDetail_report/${timesheetDate}/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getnotsubmittedTaskReportByEmpolyee(timesheetDate) {
    return this.http
      .get(
        `${url}taskDetail_report/notsubmitted/${timesheetDate}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogByProjectApprover(data: any) {
    return this.http
      .post(`${url}taskDetail_by_employees/project/approvalStatus/3`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogbyProjectId(projectId: any) {
    return this.http.get(`${url}employee_task_by_project/${projectId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getEmpTimeLogbyEmployeeIdandProjectId(employeeId: any, projectId: any) {
    return this.http
      .get(`${url}employee_task_by_employee/project/${employeeId}/${projectId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogbyEmployeeIdandProjectIdandDate(
    projectId: any,
    employeeId: any,
    date: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/project/${projectId}/${employeeId}/${date}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getEmpTimeLogbyEmployeeIdandTaskIdandDate(
    taskId: any,
    employeeId: any,
    date: any
  ) {
    return this.http
      .get(`${url}taskDetail_by_employee/task/${taskId}/${employeeId}/${date}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getprojectbyEmployeeId(employeeId: any) {
    return this.http.get(`${url}employee_project/employee/${employeeId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getManagereportByEmployeeId(employeeId: any, approvalStatus: any) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/approvalStatus/${employeeId}/${approvalStatus}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeIdAndProjectId(
    projectId: any,
    employeeId: any,
    approvalStatus: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/project/approvalStatus/${projectId}/${employeeId}/${approvalStatus}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeIdAndStartDateAndEndDate(
    employeeId: any,
    approvalStatus: any,
    fromdate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employees/date/approvalStatus/${employeeId}/${approvalStatus}/${fromdate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getManagereportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
    employeeId: any,
    projectId: any,
    approvalStatus: any,
    fromdate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employees/employee/project/date/approvalStatus/${employeeId}/${projectId}/${approvalStatus}/${fromdate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeId(employeeId: any) {
    return this.http.get(`${url}taskDetail_by_employee/${employeeId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }
  getTimesheetReportByEmployeeIdAndProjectId(projectId: any, employeeId: any) {
    return this.http
      .get(`${url}taskDetail_by_employee/project/${projectId}/${employeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeIdAndStartDateAndEndDate(
    employeeId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/report/${employeeId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
    employeeId: any,
    projectId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/report/project/${employeeId}/${projectId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getEmpTimeLogbyEmployeeIdandStartDateAndEndDate(
    taskId: number,
    employeeId: any,
    startDate: any,
    endDate: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/report/task/${employeeId}/${taskId}/${startDate}/${endDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getSubmittedTimesheetByEmployeeId(date: any, employeeId: any) {
    return this.http
      .get(`${url}taskDetail_report_by_employee/${date}/${employeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getSubmittedTimesheetByEmployeeIdAngProjectId(
    date: any,
    employeeId: any,
    projectId: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_report_by_employee/${date}/${employeeId}/${projectId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getDetailSubmittedTimesheetByEmployeeId(date: any, employeeId: any) {
    return this.http
      .get(`${url}taskDetail_by_employee/date/${date}/${employeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getDetailSubmittedTimesheetByEmployeeIdAndProjectId(
    date: any,
    employeeId: any,
    ProjectId: any
  ) {
    return this.http
      .get(
        `${url}taskDetail_by_employee/date/${date}/${employeeId}/${ProjectId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskByProjectandTaskGroup(Id: any, selectedemployeeId: any) {
    return this.http
      .get(`${url}task/project/task-group/${Id}/${selectedemployeeId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // getTaskByProjectandTaskGroupdate(Id: any, selectedetaskGroupId: any, startDate: any, endDate: any) {
  //   return this.http.get(`${url}task/project/task-group/${Id}/${selectedetaskGroupId}`)
  //   .pipe(map(res => res), timeout(globalTimeOut));
  // }

  // My task filter
  getTaskByProjectAndEmployeeAndTaskGroup(
    projectId: any,
    employeeId: any,
    taskgroupId: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/taskgroup/${projectId}/${employeeId}/${taskgroupId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskstatus(
    projectId: any,
    employeeId: any,
    taskStatusId: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/taskstatus/${projectId}/${employeeId}/${taskStatusId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskGroupAndTaskstatus(
    projectId: any,
    employeeId: any,
    taskgroupId: any,
    taskStatusId: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/taskgroup/taskStatus/${projectId}/${employeeId}/${taskgroupId}/${taskStatusId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndDate(
    projectId: any,
    employeeId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/startdate/enddate/${projectId}/${employeeId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskGroupAndDate(
    projectId: any,
    employeeId: any,
    taskgroupId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/taskgroup/startdate/enddate/${projectId}/${employeeId}/${taskgroupId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskstatusAndDate(
    projectId: any,
    employeeId: any,
    taskStatus: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/taskstatus/startdate/enddate/${projectId}/${employeeId}/${taskStatus}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndEmployeeAndTaskGroupAndTaskstatusAndDate(
    projectId: any,
    employeeId: any,
    taskgroupId: any,
    taskStatusId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/employee/taskgroup/taskstatus/startdate/enddate/${projectId}/${employeeId}/${taskgroupId}/${taskStatusId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // Assign task filter
  getTaskByProjectAndTaskgroupAndTaskstatus(
    projectId: any,
    taskstatusId: any,
    taskgroupId: any
  ) {
    return this.http
      .get(
        `${url}task/project/taskstatus/taskgroup/${projectId}/${taskstatusId}/${taskgroupId}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskstatus(projectId: any, taskstatusId: any) {
    return this.http
      .get(`${url}task/project/taskstatus/${projectId}/${taskstatusId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskgroup(projectId: any, taskGroupId: any) {
    return this.http
      .get(`${url}task/project/task-group/${projectId}/${taskGroupId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskgroupAndTaskstatusAndDate(
    projectId: any,
    taskstatusId: any,
    taskgroupId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/taskstatus/taskgroup/startdate/enddate/${projectId}/${taskstatusId}/${taskgroupId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskDetailByProject(projectId: number, approvalStatus: number) {
    return this.http
      .get(
        `${url}taskDetail_by_project/approvalStatus/${projectId}/${approvalStatus}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  getTaskByProjectAndTaskstatusAndDate(
    projectId: any,
    taskstatusId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/taskstatus/startdate/enddate/${projectId}/${taskstatusId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndTaskgroupAndDate(
    projectId: any,
    taskGroupId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.http
      .get(
        `${url}task/project/taskgroup/startdate/enddate/${projectId}/${taskGroupId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  getTaskByProjectAndDate(projectId: any, fromDate: any, toDate: any) {
    return this.http
      .get(
        `${url}task/project/startdate/enddate/${projectId}/${fromDate}/${toDate}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  // Forget password
  forgetpassword(data: any) {
    return this.http.post(`${url}Forgotpassword/OrganizationEmail`, data);
  }
  //GetEmployeeByEmail
  getemployyebyEmail(organizationEmail: any) {
    return this.http
      .get(`${url}employee/email/${organizationEmail}`)
      .pipe(map((data) => data));
  }
  ///otp
  otp(otpdata: any): Observable<any> {
    return this.http
      .post(`${url}login/Otpvaildation`, otpdata)
      .pipe(map((data) => data));
  }
  ////// Reset Password /////
  resetpassword(passwordResetdata: any, employeeId: any): Observable<any> {
    return this.http
      .patch(`${url}employee/resetpassword/${employeeId}`, passwordResetdata)
      .pipe(map((data) => data));
  }
  validatepassword(currentPassword: any): Observable<any> {
    return this.http
      .post(`${url}employee/currentpassword`, currentPassword)
      .pipe(map((data) => data));
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //deliverable_by_organization/task/{organizationId}
  //-------------------------  ----------------------------------------------
  //----------------- post method sample ------------------------------------
  //registration
  // register(params: FormData): Observable<any> {
  //   return this.http.post(`${url}resource`, params)
  //     .pipe(map(res => res), timeout(globalTimeOut), catchError(this.handleError));
  // }
  // //login
  // login(data): Observable<any> {
  //   return this.http.post(`${url}user_validation`, data)
  //     .pipe(map(res => res), timeout(globalTimeOut), catchError(this.handleError));
  // }
  //create project
  createProject(data) {
    return this.http.post(`${url}project`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  postDeliverables(data) {
    return this.http.post(`${url}deliverable`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  createTeam(data) {
    return this.http.post(`${url}team`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  //
  createTask(data) {
    return this.http.post(`${url}task`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  createMutipletask(taskarray: any) {
    return this.http.post(`${url}task/multiple`, taskarray).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  createGenericTask(data) {
    return this.http.post(`${url}generictask`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  createCommonTask(data) {
    return this.http.post(`${url}common_task`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  createMenu(data) {
    return this.http.post(`${url}menu`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  AssignEmpRole(data) {
    return this.http.post(`${url}employee_project/multiple`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  forgotPassword(data) {
    return this.http.post(`${url}forgot_password`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  assignEmployee(data) {
    return this.http.post(`${url}employee_task/multiple`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  getprojecttaskvalidate(taskName: any, projectId: any) {
    return this.http
      .get(`${url}task/validatetask/${taskName}/${projectId}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  gettaskvalidate(taskName: any) {
    return this.http.get(`${url}task/validatetask/${taskName}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  //Get
  getDesignation() {
    return this.http.get(`${url}designation`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetEmployeTimeEntry(empID) {
    return this.http.get(`${url}taskDetail_by_employee/${empID}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetEmployeTimeEntryLimit(Id: any) {
    return this.http.get(`${url}taskDetail_by_employee/limit/${Id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut)
    );
  }

  GetEmployeTimeSheet() {
    return this.http
      .get(`${url}taskDetail_by_empolyee/org/${this.organzationid}/3`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  GetEmployeTimeSheetByID(empId, approverStatus) {
    return this.http
      .get(`${url}taskDetail_by_empolyee/${empId}/${approverStatus}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }

  timeEntry(data) {
    return this.http.post(`${url}taskDetail`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updatetimeEntry(data: any) {
    return this.http.put(`${url}taskDetail/${data.taskDetailId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  GenericTimeEntry(data) {
    return this.http.post(`${url}generictaskdetail`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  Create_Customer(data) {
    return this.http.post(`${url}customer`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  Create_Role(data) {
    return this.http.post(`${url}user_role`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  addProjectStage(data) {
    return this.http.post(`${url}project_stage`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  addApprover(data) {
    return this.http.post(`${url}approvers`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  createEmployeeApprover(data) {
    return this.http.post(`${url}employeeapprovers/mutiple`, data);
  }

  createtaskgroup(createtaskgroup) {
    return this.http.post(`${url}task-group`, createtaskgroup);
  }

  taskImport(body: any) {
    return this.http.post(`${url}task/data/import`, body);

    // let result = this.http.post(`${base_url}v2/employee/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
    // return result;
  }

  // http://192.168.18.25:6009/timeentry/employee-task/data/import

  employeeTaskImport(body: any) {
    return this.http.post(`${url}employee-task/data/import`, body);
  }

  employeeProjectImport(body: any) {
    return this.http.post(`${url}employee-project/data/import`, body);

    // let result = this.http.post(`${base_url}v2/employee/data/import/`,formData,ImporthttpOptions).pipe(map(data=>data))
    // return result;
  }
  gettaskgroup() {
    return this.http.get(`${url}task-group/organization/${this.organzationid}`);
  }
  gettaskgroupbyId(taskgroupid) {
    return this.http.get(`${url}task-group/${taskgroupid}`);
  }
  updatetaskgroup(updatetaskgroup, taskgroupid) {
    return this.http.put(`${url}task-group/${taskgroupid}`, updatetaskgroup);
  }

  assigningTaskByEmployee(employeeId: number) {
    return this.http.get(`${url}task_by_empolyee/${employeeId}`);
  }

  selectingTaskByEmployee(employeeId: number, projectId: number) {
    return this.http.get(
      `${url}task_by_empolyee/project/${employeeId}/${projectId}`
    );
  }

  getEmpIdProIdStardateEnddate(
    projectId: number,
    employeeId: number,
    taskStartDate: any,
    taskEndDate: any
  ) {
    return this.http.get(
      `${url}task/project/employee/startdate/enddate/${projectId}/${employeeId}/${taskStartDate}/${taskEndDate}`
    );
  }
  selectingEmployeeIdDates(
    projectId: number,
    taskStartDate: any,
    taskEndDate: any
  ) {
    return this.http.get(
      `${url}task/project/startdate/enddate/${projectId}/${taskStartDate}/${taskEndDate}`
    );
  }

  selectingDatesEmployeeIdProjectId(
    employeeId: number,
    taskStartDate: any,
    taskEndDate: any
  ) {
    return this.http.get(
      `${url}task/employee/startdate/enddate/${employeeId}/${taskStartDate}/${taskEndDate}`
    );
  }

  taskDetailByTaskEmployee(taskID: number, employeeId: number) {
    return this.http.get(
      `${url}taskDetail_by_task_and_employee/${taskID}/${employeeId}`
    );
  }

  taskDetailByEmployees(
    projectId: number,
    approvalStatus: number,
    startDate: any,
    endDate: any
  ) {
    return this.http.get(
      `${url}taskDetail_by_employees/project/date/approvalStatus/${projectId}/${approvalStatus}/${startDate}/${endDate}`
    );
  }

  getEmployeeProject(projectId: number) {
    return this.http.get(`${url}employee_project/project/${projectId}`);
  }

  projectStatus() {
    return this.http.get(`${url}list-type-values/8`);
  }

  taskByProject(projectId: any) {
    return this.http.get(`${url}task_by_project/${projectId}`);
  }

  getTaskProjectStartEndDate(projectId: number, startdate: any, enddate: any) {
    return this.http.get(
      `${url}task/project/startdate/enddate/${projectId}/${startdate}/${enddate}`
    );
  }

  getTaskDetailByProjectId(projectId: number) {
    return this.http.get(`${url}taskDetail_by_project/${projectId}`);
  }

  getTaskDetailByProjectIdDate(
    projectId: number,
    startdate: any,
    enddate: any
  ) {
    return this.http.get(
      `${url}taskDetail_by_project/report/${projectId}/${startdate}/${enddate}`
    );
  }

  getTaskDetailReportByProject(date: any, projectId: number) {
    return this.http.get(
      `${url}taskDetail_report/date/project/${date}/${projectId}`
    );
  }

  //localhost:6009/timeentry/taskDetail_report/date/project/2023-12-01/66

  //-----------------------------------------------------------------------
  //-----------------put method sample-------------------------------------
  // resource ++
  updateResource(data, id): Observable<any> {
    return this.http.put(`${url}employee/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updateProfile(data: FormData, id) {
    return this.http.put(`${url}profile/${id}`, data);
  }
  updateCommonTask(data, id): Observable<any> {
    return this.http.put(`${url}common_task/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  changepassword(data, id): Observable<any> {
    return this.http.put(`${url}change_password/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updateProject(data: FormData, id): Observable<any> {
    return this.http.put(`${url}project/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updateDeliverable(data, id): Observable<any> {
    return this.http.put(`${url}deliverable/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updateTeam(data, id): Observable<any> {
    return this.http.put(`${url}team/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updateTask(data, id): Observable<any> {
    return this.http.put(`${url}task/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  resetPassword(data, id): Observable<any> {
    return this.http.put(`${url}reset_password/${id}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  Time_Entry_Approval(data, taskid): Observable<any> {
    return this.http.put(`${url}taskDetailUpdateStatus/${taskid}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  Generic_Task_Approval(data, generictaskdetailId): Observable<any> {
    return this.http
      .put(`${url}generictaskdetail/${generictaskdetailId}`, data)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut),
        catchError(this.handleError)
      );
  }
  updateGenericTask(genericId, data) {
    return this.http.put(`${url}generictask/${genericId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  updateCustomer(customerId, data) {
    return this.http.put(`${url}customer/${customerId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  updateProjectStage(projectstageId, data) {
    return this.http.put(`${url}project_stage/${projectstageId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  getProjectStageName(projectstagename: any, projectstageId: number) {
    return this.http
      .get(
        `${url}project_stage/projectstagename/${projectstagename}/${projectstageId}/${this.organzationid}`
      )
      .pipe(
        map((res) => res),
        timeout(globalTimeOut),
        catchError(this.handleError)
      );
  }

  updateApprover(approverId, data) {
    return this.http.put(`${url}approvers/${approverId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  updateEmployeeTask(empTaskId, data) {
    return this.http.put(`${url}employee_task/${empTaskId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  update_Role(data) {
    return this.http.put(`${url}user_role`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  update_EmpRole(empProId, data) {
    return this.http.put(`${url}employee_project/${empProId}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  //-----------------------------------------------------------------------

  //-----------------delete method sample--------------------------------

  deleteProject(id) {
    return this.http.delete(`${url}project/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  deleteResource(id) {
    return this.http.delete(`${url}employee/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteDeliverable(id) {
    return this.http.delete(`${url}deliverable/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteTeam(id) {
    return this.http.delete(`${url}team/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteTask(id) {
    return this.http.delete(`${url}task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteCommonTask(id) {
    return this.http.delete(`${url}common_task/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteGenericTask(id) {
    return this.http.delete(`${url}generictask/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteSkill(id) {
    return this.http.delete(`${url}skill/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteTag(id) {
    return this.http.delete(`${url}tag/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteCustomer(id) {
    return this.http.delete(`${url}customer/${id}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }
  deleteProjectStage(projectstageId) {
    return this.http.delete(`${url}project_stage/${projectstageId}`).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  Getmaxworkhours() {
    return this.http
      .get(`${url}maxworkhours/organization/${this.organzationid}`)
      .pipe(
        map((res) => res),
        timeout(globalTimeOut)
      );
  }
  updatemaxworkhours(organzationid, data) {
    return this.http.put(`${url}maxworkhours/${organzationid}`, data).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  createtaskemployee(taskdata: any) {
    return this.http.post(`${url}task/employeetask`, taskdata).pipe(
      map((res) => res),
      timeout(globalTimeOut),
      catchError(this.handleError)
    );
  }

  ApprovalSubmit(fromDate: any, toDate: any, data: any) {
    return this.http.put(
      `${url}taskDetail/apporvalsubmit/${fromDate}/${toDate}`,
      data
    );
  }
  updateBulkApprovalStatus(data: any) {
    return this.http.put(`${url}taskDetail/updateapprovalstatus`, data);
  }

  //---------------------getmethod sample---------------------------------

  RegisetrOrgUser(data: any) {
    return this.http.post(`${url}organization/user/registration`, data);
  }

  Create_project(data: any) {
    return this.http.post(`${url}project`, data);
  }

  Create_mom(data: any) {
    return this.http.post(`${url}create/mom`, data);
  }

  Create_Nonmom(data: any) {
    return this.http.post(`${url}mom`, data);
  }

  Update_mom(momId, data: any) {
    return this.http.put(`${url}update/mom/${momId}`, data);
  }

  Update_project(projectId: any, x: any) {
    return this.http.put(`${url}project/${projectId}`, x);
  }

  Delete_project(projectId: any) {
    return this.http.delete(`${url}project/${projectId}`);
  }

  get_projects() {
    return this.http.get(`${url}project`);
  }

  get_projectsByUserId(id: any) {
    return this.http.get(`${url}mom/user/${id}`);
  }

  get_actionItem() {
    return this.http.get(`${url}action-item`);
  }

  get_actionItemByProject(startDate, endDate, actionStatus, projectId) {
    return this.http.get(
      `${url}action-item/${startDate}/${endDate}/${actionStatus}/${projectId}`
    );
  }

  get_actionItemByNonProject(startDate, endDate, actionStatus) {
    return this.http.get(
      `${url}action-item/non-project/${startDate}/${endDate}/${actionStatus}`
    );
  }

  get_momByProject(startDate, endDate, projectId) {
    return this.http.get(`${url}mom/${startDate}/${endDate}/${projectId}`);
  }

  // public get_mombyproject(id:number): Observable<any>{
  //   return this.http.get<any>(`${url}mom/project/${id}`);
  // }

  ////////////  Minutes of meetingd API ///////////

  get_mombyproject(id: number) {
    let ul = `${url}mom/project/${id}`;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.get<any>(ul, options);
  }

  get_mom() {
    let ul = `${url}mom`;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.get<any>(ul, options);
  }

  get_momById(momId) {
    return this.http.get(`${url}mom/${momId}`);
  }

  get_NONProjectmom() {
    let ul = `${url}mom/non-project`;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.get<any>(ul, options);
  }

  get_location(userId: any) {
    return this.http.get(`${url}locationDetails/user/${userId}`);
  }

  login(formData) {
    return this.http.post<any>(`${url}login`, formData);
  }

  register(formData) {
    return this.http.post<any>(`${url}user`, formData);
  }

  UpdateEmployeeProfile(id: any, data: any) {
    return this.http.put(`${url}employee_image/${id}`, data);
  }

  UpdateActionItem(id: any, data: any) {
    return this.http.put(`${url}action-item/${id}`, data);
  }

  gettaskbyEmployeeAndStartDateAndEndDate(selectedemployeeId: any, startDate: any, endDate: any) {
    return this.http.get(`${url}task/employee/startdate/enddate/${selectedemployeeId}/${startDate}/${endDate}`);
  }

  getTaskByProjectAndEmployee(projectId: any, selectedemployeeId: any) {
    return this.http.get(`${url}task_by_empolyee/project/${selectedemployeeId}/${projectId}`);
  }

  getEmpIdProIdStardateEnddateAndStatus(projectId: any, selectedemployeeId: any, startDate: any, endDate: any, taskstatus: any) {
    return this.http.get(`${url}task/project/employee/taskstatus/startdate/enddate/${projectId}/${selectedemployeeId}/${taskstatus}/${startDate}/${endDate}`);
  }

  getTaskByProjectAndEmployeeAndStatus(projectId: any, selectedemployeeId: any, taskstatus: any) {
     return this.http.get(`${url}task/project/employee/taskstatus/${projectId}/${selectedemployeeId}/${taskstatus}`);
  }
  taskByProjectAndStatus(projectId: any, taskstatus: any) {
    return this.http.get(`${url}task/taskstatus/task_by_project/${projectId}/${taskstatus}`);
  }
  getTaskByProjectAndDateAndStatus(projectId: any, startDate: any, endDate: any, taskstatus: any) {
    return this.http.get(`${url}task/project/startdate/enddate/${projectId}/${startDate}/${endDate}/${taskstatus}`);
  }

  gettaskbyEmployeeAndStartDateAndEndDateAndStatus(selectedemployeeId: any, startDate: any, endDate: any, taskstatus: any) {
    return this.http.get(`${url}task/employee/startdate/enddate/${selectedemployeeId}/${startDate}/${endDate}/${taskstatus}`);
  }

  getTaskByDate(startDate: any, endDate: any) {
    return this.http.get(`${url}task/startdate/enddate/${startDate}/${endDate}`);
  }

  getTaskByDateAndTaskStatus(startDate: any, endDate: any , taskstatus :any) {
    return this.http.get(`${url}task/startdate/enddate/taskstatus/${startDate}/${endDate}/${taskstatus}`);
  }

   getTaskDetailByDate(startDate: any, endDate: any) {
    return this.http.get(`${url}taskDetail_by_employee/report/date/${startDate}/${endDate}`);
  }

  


  //--------------------------------------------------------------------
  private handleError(error: HttpErrorResponse) {
    console.log(error.error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
      alert(`An error occurred: ${error.error.message}`);
      // alert('Something bad happened; please try again later.');
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error === undefined) {
        alert(error.name);
      } else {
        if (error.error.message == undefined) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            text: "net::ERR_CONNECTION_REFUSED",
            showConfirmButton: false,
            timer: 4500,
          });
        } else if (error.error.message != "No Data") {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.error.message,
            showConfirmButton: false,
            timer: 4500,
          });
        } else {
          console.log(error);
        }
      }
      console.error(
        `Backend returned code ${error.error.statusCode}, ` +
          `body was: ${error.error.message}`
      );
    }
    // return an ErrorObservable with a user-facing error message
    return new ErrorObservable();
    // 'Something went wrong; please try again later.'
  }
}
