import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from '../providers/globalservice.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  usertype:any;
  organizationEmail:any;
  otpForm: FormGroup;
  create_sucess_msg:any;
  update_data_error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  backdrop:any= false;
  error_msg:any;
  
  constructor(private router:Router ,
              private otpservice:GlobalserviceService,
              private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
   
    this.organizationEmail = this.activatedRoute.snapshot.params.organizationEmail;
    console.log(this.organizationEmail);

    this.otpForm = new FormGroup(
      {
          "otp" : new FormControl(this.otpForm,[Validators.required,Validators.pattern(/^[0-9]*$/)]),
      }
    );
  }
 
  submitData()
  {
    if(this.otpForm.invalid)
    {
      return;
    }
    var otpdata =
    { 
      organizationEmail : this.organizationEmail,
      otp : this.otpForm.value.otp,
     
    };
    console.log(otpdata);
    this.backdrop=true;
    this.loader=true;
    this.otpservice
    .otp(otpdata)
    .subscribe( (data:any) =>
    {
    
      this.backdrop = false;
      this.loader = false;
      this.success = true;
      this.create_sucess_msg = data.description;

      this.otpservice
      .getemployyebyEmail(this.organizationEmail)
      .subscribe( (data:any) =>
      {
        console.log(data);
        setTimeout(() => {
          this.router.navigate([`/new-password/${data.employeeId}`]); 
        }, 3000)

      })
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
         this.error_msg = err.error.description;
      }
    )
  } 
  get otp()
  {
    return this.otpForm.get('otp');  
  }


}