import { DatePipe } from "@angular/common";
import { Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { GlobalserviceService } from "src/app/providers/globalservice.service";
import { ViewdescripeComponent } from "../viewdescripe/viewdescripe.component";

@Component({
  selector: "app-timesheetreports",
  templateUrl: "./timesheetreports.component.html",
  styleUrls: ["./timesheetreports.component.scss"],
})
export class TimesheetreportsComponent implements OnInit {
  projectId: any;
  projectList: any = [];
  taskList: any;
  projectStatus: any = "0";
  timesheetDate: any;
  p: any;
  user_type: string;
  search: any;
  userType: any;
  isData: boolean;
  taskId: number = 0;
  timesheetreport: any = [];
  isproject: boolean;
  nodata = false;
  loader = false;
  public searchFilter: any = "";
  query: any = "";
  beforepage: any;
  mySelect: any = 5;
  employeelist: any = [];
  employeeId: any;

  projectlist: any;
  selectedemployeeId: any;
  startDate: any;
  endDate: any;
  disable_project: boolean = true;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  tasktotalTime: any;
  today: any;
  employee_required: boolean = false;
  empId: any;
  organizationId: any;
  showEmptyData: boolean = false;
  project_required: boolean =  false;
  organizationemployeelist: any;
  constructor(
    private gs: GlobalserviceService,
    private router: Router,
    public dialog: MatDialog,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.today = new Date();
    this.today = this.datepipe.transform(this.today, "YYYY-MM-dd");
    const data = JSON.parse(localStorage.getItem("UserData"));
    this.empId = data.employeeId;
    this.organizationId = data.organization.organizationId;
    console.log("organzationid" + this.organizationId);


    this.gs
    .getProjectByOrganization(this.organizationId)
    .subscribe((data: any) => {
      console.log("data", data);

      console.log("data", data[0].projectId);
      // this.projectList.push(data);
      this.projectList = data;
      this.projectlist =  data;

      console.log('this.projectlist',this.projectlist[0].projectName);
      
    });
    this.gs.getResource().subscribe(
      (d: any) => {
        //this.loader = false;
         this.organizationemployeelist = d;
        d.forEach(element => {
          let employee = {
            employee: {
              employeeId : element.employeeId,
              employeeName : element.employeeName
            }
          
          }

          this.employeelist.push(employee);
        });
      },
      (error) => {
        //  this.loader = false;
        //  this.nodata = true;
        console.log("error");
      }
    );
  }

  getTask() {
    this.employeelist.length = 0;
    this.employee_required = false;
    this.disable_project = false;
    console.log(this.disable_project);
    console.log("employeeId" + this.selectedemployeeId);
    if (this.projectId == undefined) {
      this.employeelist.length = 0;
      this. organizationemployeelist.forEach(element => {
          let employee = {
            employee: {
              employeeId : element.employeeId,
              employeeName : element.employeeName
            }
          
          }
          this.employeelist.push(employee);
        });
    

      
    } else {
      this.gs
        .getEmployeeProject(this.projectId)
        .subscribe((data: any) => {
          console.log(data);
          this.employeelist = data;
        });
    }
  }
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  changefirstpage() {
    this.p = 1;
  }

  resetForms() {
   
      this.selectedemployeeId = undefined;
       this.projectId= undefined;
       this.startDate= undefined;
       this.endDate  = undefined;
       this.timesheetreport.length = 0; 
       this.showEmptyData = true;
       
  }

  // searchTimesheet() {
  //   this.changefirstpage();
  //   this.startDate = this.datepipe.transform(this.startDate, "YYYY-MM-dd");
  //   this.endDate = this.datepipe.transform(this.endDate, "YYYY-MM-dd");
  //   // if (this.selectedemployeeId == undefined) {
  //   //   this.employee_required = true;
  //   //   return;
  //   // }
  //   this.timesheetreport.length = 0;
  //   this.employee_required = false;
  //   if (this.projectId  ) {
  //     if (this.startDate == undefined && this.endDate == undefined) {
  //       this.gs
  //         .getTaskDetailByProjectId(this.projectId)
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;

  //             console.log('dataaaaaaaa',this.timesheetreport);
              
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     } else {
  //       if (this.startDate == undefined || this.endDate == undefined) {
  //         if (this.startDate == undefined) {
  //           this.startdate_required = true;
  //         } else if (this.endDate == undefined) {
  //           this.enddate_required = true;
  //         }
  //         return;
  //       }
  //       console.log(this.startDate);
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //       this.gs
  //         .getTaskDetailByProjectIdDate(
  //           this.projectId,
  //           this.startDate,
  //           this.endDate
  //         )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     }
  //   } else {
  //     if (this.startDate == undefined && this.endDate == undefined) {
  //       this.gs
  //         .getTimesheetReportByEmployeeIdAndProjectId(
  //           this.projectId,
  //           this.selectedemployeeId
  //         )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     } else {
  //       if (this.startDate == undefined || this.endDate == undefined) {
  //         if (this.startDate == undefined) {
  //           this.startdate_required = true;
  //         } else if (this.endDate == undefined) {
  //           this.enddate_required = true;
  //         }
  //         return;
  //       }
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //       this.gs
  //         .getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
  //           this.selectedemployeeId,
  //           this.projectId,
  //           this.startDate,
  //           this.endDate
  //         )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     }
  //   }
  // }


  // searchTimesheet() {
  //   this.changefirstpage();
  //   this.startDate = this.datepipe.transform(this.startDate, "YYYY-MM-dd");
  //   this.endDate = this.datepipe.transform(this.endDate, "YYYY-MM-dd");
  //   // if (this.selectedemployeeId == undefined) {
  //   //   this.employee_required = true;
  //   //   return;
  //   // }
  //   this.timesheetreport.length = 0;
  //   this.employee_required = false;
  //   if (this.selectedemployeeId == undefined || this.projectId) {
  //     if (this.startDate == undefined && this.endDate == undefined) {
  //       this.gs
  //         .getTaskDetailByProjectId(this.projectId)
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     } else {
  //       if (this.startDate == undefined || this.endDate == undefined) {
  //         if (this.startDate == undefined) {
  //           this.startdate_required = true;
  //         } else if (this.endDate == undefined) {
  //           this.enddate_required = true;
  //         }
  //         return;
  //       }
  //       console.log(this.startDate);
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //       this.gs
  //         .getTaskDetailByProjectIdDate(
  //                     this.projectId,
  //                     this.startDate,
  //                     this.endDate
  //                   )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     }
  //   } else {
  //     if (this.startDate == undefined && this.endDate == undefined ) {
  //       this.gs
  //         .getTimesheetReportByEmployeeIdAndProjectId(
  //           this.projectId,
  //           this.selectedemployeeId
  //         )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     } else {
  //       if (this.startDate == undefined || this.endDate == undefined) {
  //         if (this.startDate == undefined) {
  //           this.startdate_required = true;
  //         } else if (this.endDate == undefined) {
  //           this.enddate_required = true;
  //         }
  //         return;
  //       }
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //       this.gs
  //         .getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate(
  //           this.selectedemployeeId,
  //           this.projectId,
  //           this.startDate,
  //           this.endDate
  //         )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             this.timesheetreport = data;
  //             this.nodata = false;
  //             this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //           }
  //         );
  //     }
  //   }
  // }

  showErrorMessage = false;

  @ViewChild('projectContainer') projectContainer: ElementRef;
  @ViewChild('projectSelect') projectSelect: ElementRef;

  onSelectionChange() {
    this.showErrorMessage = true;
    this. getTask()
  }

  // Detect clicks outside the select box
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (
      !this.projectContainer.nativeElement?.contains(event.target) &&
      !this.projectSelect.nativeElement?.contains(event.target)
    ) {
      if(this.projectId)
      {
        this.showErrorMessage = false;
      }
    
    }
  }



  
  searchTimesheet(){


    this.changefirstpage();
    this.timesheetreport.length=0;
    this.startDate = this.datepipe.transform( this.startDate , 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform( this.endDate , 'YYYY-MM-dd');
    console.log("projectId"+this.projectId)
    // if(this.projectId == undefined || this.projectId == "undefined"){
    //   this.showErrorMessage =true;
    //   console.log(" this.showErrorMessage : " +  this.showErrorMessage)
    //   return
    // }

    // if (this.projectId == 0 || this.projectId == undefined) {
    //   // this.employee_required = true;
    //   this.project_required = true;
    //   return;
    // }else{
    //   this.project_required = false;
    // }

    this.employee_required =false;
    if(this.selectedemployeeId == undefined  && this.projectId !=undefined  ){
      if(this.startDate == undefined  && this.endDate == undefined ){
        this.gs.getTaskDetailByProjectId
        (this.projectId)
        .subscribe((data:any)=>{
          console.log(data);
          this.timesheetreport = data;
          // console.log('this.timesheetreport',  this.timesheetreport);
          // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
          this.nodata = false;
          this.tasktotalTime = this.timesheetreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
          this.showErrorMessage = true;
          this.timesheetreport.pop();
          this.timesheetreport.splice(0, this.timesheetreport.length);
        });
      }
      else{
        if(this.startDate == undefined  || this.endDate == undefined ){
          if(this.startDate == undefined){
            this.startdate_required = true;
          }
          else if(this.endDate==undefined ){
            this.enddate_required = true;
          }
          return
        }
        console.log(this.startDate);
        this.enddate_required = false;
        this.startdate_required = false;
        this.gs.getTaskDetailByProjectIdDate
        (this.projectId,this.startDate,this.endDate)
        .subscribe((data:any)=>{
          console.log(data);
          this.timesheetreport = data;
          console.log('this.timesheetreport',  this.timesheetreport);
          this.timesheetreport = data;
          this.nodata = false;
          this.tasktotalTime = this.timesheetreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
          this.showErrorMessage = true;
          this.timesheetreport.pop();
          this.timesheetreport.splice(0, this.timesheetreport.length);
        });
      }
    } else if(this.selectedemployeeId == undefined  && this.projectId ==undefined  && this.startDate != undefined  && this.endDate != undefined  )
    {
      this.gs.getTaskDetailByDate
      (this.startDate , this.endDate)
      .subscribe((data:any)=>{
        console.log(data);
        this.timesheetreport = data;
        // console.log('this.timesheetreport',  this.timesheetreport);
        // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
        this.nodata = false;
        this.tasktotalTime = this.timesheetreport[0].totalTime;
      },
      (error) => {
        this.nodata = true;
        this.showErrorMessage = true;
        this.timesheetreport.pop();
        this.timesheetreport.splice(0, this.timesheetreport.length);
      });
    }
    else if(this.selectedemployeeId != undefined  && this.projectId ==undefined  && this.startDate != undefined  && this.endDate != undefined  )
    {
      this.gs.getTimesheetReportByEmployeeIdAndStartDateAndEndDate
      (this.selectedemployeeId , this.startDate , this.endDate)
      .subscribe((data:any)=>{
        console.log(data);
        this.timesheetreport = data;
        // console.log('this.timesheetreport',  this.timesheetreport);
        // console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
        this.nodata = false;
        this.tasktotalTime = this.timesheetreport[0].totalTime;
      },
      (error) => {
        this.nodata = true;
        this.showErrorMessage = true;
        this.timesheetreport.pop();
        this.timesheetreport.splice(0, this.timesheetreport.length);
      });
    }
    else{
      if(this.startDate ==undefined && this.endDate==undefined && this.selectedemployeeId != undefined && this.projectId !=undefined){
        this.gs.getTimesheetReportByEmployeeIdAndProjectId
        (this.projectId,this.selectedemployeeId)
        .subscribe((data:any)=>{
          console.log(data);
          this.timesheetreport = data;
          console.log('this.timesheetreport',  this.timesheetreport);
          this.timesheetreport = data;
          this.nodata = false;
          this.tasktotalTime = this.timesheetreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
          this.showErrorMessage = true;
          this.timesheetreport.pop();
          this.timesheetreport.splice(0, this.timesheetreport.length);
        });
      }
      else{
        if(this.startDate == undefined  || this.endDate==undefined ){
          if(this.startDate == undefined){
            this.startdate_required = true;
          }
          else if(this.endDate==undefined){
            this.enddate_required = true;
          }
          return
        }
        this.enddate_required = false;
        this.startdate_required = false;
        this.gs.getTimesheetReportByEmployeeIdAndProjectIdAndStartDateAndEndDate
        (this.selectedemployeeId,this.projectId,this.startDate,this.endDate)
        .subscribe((data:any)=>{
          console.log(data);
          this.timesheetreport =data;
          console.log('this.timesheetreport',  this.timesheetreport);
          this.timesheetreport = data;
          this.nodata = false;
          this.tasktotalTime = this.timesheetreport[0].totalTime;
        },
        (error) => {
         
          this.nodata = true;
          this.showErrorMessage = true;
          this.timesheetreport.pop();
          this.timesheetreport.splice(0, this.timesheetreport.length);
        });
      }
    }
  }

  datechange(event: any) {
    console.log(event);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return;
    }
    if (i.task.taskStatusName == "To Do") {
      return "#ffc107";
    }
    if (i.task.taskStatusName == "Completed") {
      return "#28a745";
    }
    if (i.task.taskStatusName == "In Progress") {
      return "#f44336";
    }
  }

  approvalStatus(i) {
    if (i.approval?.approvalStatusName == null) {
      return;
    }
    if (i.approval?.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approval?.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approval?.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  description(e) {
    console.log(e.description);
    const dialogRef = this.dialog.open(ViewdescripeComponent, {
      width: "500px",
      data: e,
    });
  }

  sortData(sort: Sort) {
    const data = this.timesheetreport.slice();
    if (!sort.active || sort.direction === "") {
      this.timesheetreport = data;
      return;
    }

    this.timesheetreport = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "employeeName":
          return this.compare(
            a.employee.employeeName,
            b.employee.employeeName,
            isAsc
          );
        case "projectName":
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case "taskName":
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "workedHours":
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case "taskStatusName":
          return this.compare(
            a.task.taskStatusName,
            b.task.taskStatusName,
            isAsc
          );
        case "approvalStatusName":
          return this.compare(
            a.approval.approvalStatusName,
            b.approval.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
