<html>
    <body class="container-fluid d-flex h-100 login-background">   
        <div class="card-body login-card-body card-background col-md-5 align-self-center">
            <form [formGroup]="emailForm" (ngSubmit)="submitData()">                  
                <div class="col-md-12 content">
                    <h1 class="login-title">Forget Password</h1>
                </div>                                            
                <div class="col-md-12" id="loginform">
                    <input type="email" class="form-control email-input" formControlName="organizationEmail" placeholder="Email">
                    <span class="material-icons email-icon">mail</span>
                    <div class="valid-msg" *ngIf="(organizationEmail.invalid && organizationEmail.touched) || organizationEmail.dirty">
                        <small *ngIf="organizationEmail.errors?.required" class="text-danger">Please enter email</small>
                        <small *ngIf="organizationEmail.errors?.pattern" class="text-danger">Please enter valid email</small>
                    </div>
                </div>                                     
                <div class="col-md-12 text-center login-button">
                    <Button type="submit" [disabled]="emailForm.invalid" class="btn btn-block login-button-text">Submit</Button>
                </div>
            </form>  
        </div>
    </body>
    <div *ngIf="loader" class="align-items-center text-center align-self-center" style="position: relative;bottom: 54vh; z-index: 2;">
        <div class="spinner-border align-self-center" style="width: 3rem; height: 3rem;" role="status"></div>
    </div>  
</html>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
<div class="text-center" *ngIf="success"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_data_error_msg}}
</div>

