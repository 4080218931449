<!-- <p>navbar works!</p> -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">Js</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
            <a  class="nav-item nav-link" [routerLink] = "['/home']" routerLinkActive = 'active'>Home </a>
            <a  class="nav-item nav-link">Features</a>
            <a  class="nav-item nav-link">Pricing</a>
            <a  class="nav-item nav-link">Customers</a>
            <a  class="nav-item nav-link">Partners</a>
            <a  class="nav-item nav-link">Support</a>
            <a  class ="nav-item nav-link">Resources</a>
            <a  class ="nav-item nav-link" routerLink = '/register' routerLinkActivated = "active" >Register</a>

        </div>
   
    </div>
    <!-- <div style="margin-right: 200px;">
        <a class =" navbar-nav nav-item nav-link">
         <div routerLink = '/register'>
            Register
         </div>    
        </a>
 </div> -->
</nav>