<div class="row">
    <div class="col-md-10">
        <h4 align="center">Task Group</h4>
    </div>
    <div class="col-md-2" align="end">
        <span class="material-icons" style="color:red;" mat-dialog-close>cancel</span>
    </div>
</div>
<form [formGroup]="taskGroup" (ngSubmit)="onSubmit()">
    <div>
        <div class="full-width" id="loginform">
          <label class="form-control-label">Task Group Name<span class="text-danger">*</span></label>
          <input
            formControlName="taskgroupname"
            placeholder="Enter Form Group Name"
            class="form-control email-input select-placeholder"/>
          <mat-error *ngIf="taskgroupname.hasError('required') && submitted">
            Task group name is required
          </mat-error>
        </div>
        <!-- <span *ngIf="roleNameexist"  class="span-error"> Role Name Already Exist</span> -->
        <div class="full-width">
            <label class="form-control-label">Task Group Description<span class="text-danger">*</span></label>
            <textarea
                formControlName="description"
                placeholder="Enter Description"
                class="form-control email-input select-placeholder">
            </textarea>
          <mat-error *ngIf="description.hasError('required') && submitted">
            Description is required
          </mat-error>
        </div>
        <div align="end" mat-dialog-actions>
            <button class="btn create-button" type="submit"
            style="margin:15px 0px;">{{ this.data.taskGroupId ? "Update" : "Create" }}</button>
          </div>
    </div>
</form>
