import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { TaskGroupComponent } from '../task-group/task-group.component';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-task-group-list',
  templateUrl: './task-group-list.component.html',
  styleUrls: ['./task-group-list.component.css']
})
export class TaskGroupListComponent implements OnInit {

  taskgroupList:any = [];
  // p:any;
  search:any;
  beforesearchpage: any;
  Taskgrouplist: any = [];
  nodata= false;
  loader = true;

  p: number = 1;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;

  constructor(private gs:GlobalserviceService,
              private dialog:MatDialog,) { }

  ngOnInit(): void {
    this.gs.gettaskgroup().subscribe(
      (d:any)=>{
        this.loader = false;
        this.taskgroupList = d;
        this.Taskgrouplist = d;
        console.log(this.Taskgrouplist)
        if(this.Taskgrouplist.length == 0)
          {
            this.nodata = true;
            console.log("nodata")
          }
      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        console.log("error")
        if(error.status == 404){
          this.nodata = true;
          console.log("404")
        }
      }
    );
  }


  addtaskgroup(){
    const dialogRef = this.dialog.open(TaskGroupComponent,{
      width:"500px",
      data:""
    })
  }
  updatetaskgroup(e){
    let projectData = e
  const dialogRef = this.dialog.open(TaskGroupComponent,{
    width:"500px",
    data:projectData
  })
  }


  filterByValue() {
    console.log(this.search);
    // console.log("before if " + this.Taskarray.length);
      if(this.search.length != 0)
      {
      
        // console.log("page number" +  this.beforesearchpage);
        this.p = 1;
      console.log("after if " + this.Taskgrouplist.length);
        this.taskgroupList = this.Taskgrouplist.filter(o =>
          Object.keys(o).some(k => String(o[k]).toLowerCase().includes(this.search.toLowerCase())));
        
      //   console.log( "task list length "+this.EmpList.length);
      }else{
                this.p = this.beforesearchpage;
              this.taskgroupList = this.Taskgrouplist;
      }

  }

  sortData(sort: Sort) {
    const data = this.taskgroupList.slice();
    if (!sort.active || sort.direction === '') {
      this.taskgroupList = data;
      return;
    }
  
    this.taskgroupList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'taskGroupName':
          return this.compare(a.taskGroupName, b.taskGroupName, isAsc);     
          case 'taskGroupDescription':
            return this.compare(a.taskGroupDescription, b.taskGroupDescription, isAsc);         
            case 'statusName':
              return this.compare(a.statusName, b.statusName, isAsc);             
        default:
          return 0;
      }
    });
  }
  
   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
