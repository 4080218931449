import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalserviceService } from '../app/providers/globalservice.service';
import { MaterialDesign} from '.././app/material/material.module';
import { mydesign} from '.././app/material/mystyle.module';
// import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgbModule, NgbPaginationModule, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { NavbarComponent } from './components/modules/navbar/navbar.component';
import { HomeComponent } from './components/modules/home/home.component';
import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { StatusPipe } from './status.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { RegisterComponent } from './components/register/register.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDialogModule} from'@angular/material';
import { ManageProjectApproversComponent } from './my-components/manage-project-approvers/manage-project-approvers.component';
import { AddProjectApproversComponent } from './my-components/add-project-approvers/add-project-approvers.component';
import { ProjectapproverPipe } from './my-components/manage-project-approvers/projectapprover.pipe';
import { DatePipe } from '@angular/common';
import { ViewApproversComponent } from './my-components/view-approvers/view-approvers.component';
import { AddApproverPolicyHierarchyComponent } from './my-components/approvers-policy-hierarchy/add-approver-policy-hierarchy/add-approver-policy-hierarchy.component';
import { MyTaskComponent } from './my-components/my-task/my-task.component';
import { MyTaskPipe } from './my-components/my-task/mytask.pipe';
import { ViewManageApproversComponent } from './view-manage-approvers/view-manage-approvers.component';
import { TimeSettingsComponent } from './time-settings/time-settings.component';
import { CreateAssignTaskComponent } from './create-assign-task/create-assign-task.component';
import { ResourcePipe } from './my-components/emp-report/resource/resource.pipe';
import { AssignEmpProjectComponent } from './assign-emp-project/assign-emp-project.component';
import { AddMultipleTaskComponent } from './add-multiple-task/add-multiple-task.component';
import { AssignEmployeeTaskComponent } from './assign-employee-task/assign-employee-task.component';
import { TaskGroupComponent } from './task-group/task-group.component';
import { TaskGroupListComponent } from './task-group-list/task-group-list.component';
import { TimesheetreportsComponent } from './timesheetreports/timesheetreports.component';
import { ViewdescripeComponent } from './viewdescripe/viewdescripe.component';
import { ViewtimesheetReportComponent } from './my-components/viewtimesheet-report/viewtimesheet-report.component';
import { TimesheetPipe } from './my-components/timesheet.pipe';
import { ExcelService} from './providers/excel.service'
import  { BeautifulChartsModule } from "ngx-beautiful-charts"
import { NgApexchartsModule } from "ng-apexcharts"
// import { AdmindashboadPipe } from './my-components/admin-dashboard/admindashboad.pipe';
import { MatCardModule } from '@angular/material';
import { AdminDashboardComponent } from './my-components/admin-dashboard/admin-dashboard.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { OtpComponent } from './otp/otp.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { MomReportComponent } from './mom-report/mom-report.component';
import { ActionItemComponent } from './action-item/action-item.component';
import { CreateMomComponent } from './create-mom/create-mom.component';
import { UpdateActionComponent } from './action-item/update-action/update-action.component';
import { PreviewFileComponent } from './mom-report/preview-file/preview-file.component';
import { UpdateMomComponent } from './mom-report/update-mom/update-mom.component';
import { ActionitemPipe } from './action-item/actionitem.pipe';
import { CustomPipe } from './custom.pipe';
import { EmployeeTaskReportComponent } from './employee-task-report/employee-task-report.component';
import { TimeSheetComponent } from './time-sheet/time-sheet.component';
import { EmployeeTimesheetViewdescriptionComponent } from './employee-timesheet-viewdescription/employee-timesheet-viewdescription.component';
import { ImportEmployeeComponent } from './my-components/import-employee/import-employee.component';
import { ShowErrorComponent } from './my-components/show-error/show-error.component';
import { ShiftemployeeComponent } from './my-components/shiftemployee/shiftemployee.component';
import { SearchFilterPipe } from './my-components/search-filter.pipe';
import { AssignEmployeeComponent } from './assign-employee/assign-employee.component';
import { ShowErrorEmployeeComponent } from './show-error-employee/show-error-employee.component';
import { AssignEmployeeImportComponent } from './assign-employee-import/assign-employee-import.component';
import { EmployeeProjectImportComponent } from './employee-project-import/employee-project-import.component';
import { AssignEmployeeErrorComponent } from './assign-employee-error/assign-employee-error.component';

import { EmployeeTaskPipe } from './employee-task.pipe';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    StatusPipe,
    RegisterComponent,
    ManageProjectApproversComponent,
    AddProjectApproversComponent,
     ProjectapproverPipe,
     ViewApproversComponent,
     AddApproverPolicyHierarchyComponent,
     ViewManageApproversComponent,
     TimeSettingsComponent,
     CreateAssignTaskComponent,
     ResourcePipe,
     AssignEmpProjectComponent,
     AddMultipleTaskComponent,
     AssignEmployeeTaskComponent,
     TaskGroupComponent,
     TaskGroupListComponent,
     TimesheetreportsComponent,
     ViewdescripeComponent,
     ViewtimesheetReportComponent,
     TimesheetPipe,
    //  AdmindashboadPipe,
     AdminDashboardComponent,
    ForgetPasswordComponent,
    OtpComponent,
    NewPasswordComponent,
    MomReportComponent,
    ActionItemComponent,
    CreateMomComponent,
    UpdateActionComponent,
    PreviewFileComponent,
    UpdateMomComponent,
    ActionitemPipe,
    CustomPipe,
    EmployeeTaskReportComponent,
    TimeSheetComponent,
    EmployeeTimesheetViewdescriptionComponent,
    ImportEmployeeComponent,
    ShowErrorComponent,
    ShiftemployeeComponent,
    SearchFilterPipe,
    AssignEmployeeComponent,
    ShowErrorEmployeeComponent,
    AssignEmployeeImportComponent,
    EmployeeProjectImportComponent,
    AssignEmployeeErrorComponent,
    EmployeeTaskPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialDesign,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    mydesign,
    // ShowHidePasswordModule,
    LyThemeModule.setTheme('minima-light'),
    NgbModule,
    NgbPaginationModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    BeautifulChartsModule,
    NgApexchartsModule,
    MatMenuModule,
    MatDialogModule
  ],
  providers: [GlobalserviceService, { provide: LY_THEME,  useClass: MinimaLight,  multi: true } , DatePipe , ExcelService],
  bootstrap: [AppComponent],
})
export class AppModule { }
