<div class="d-flex">
  <h4 style="margin-bottom: 15px;width: 97%;">Minutes Of Meeting</h4>
  <span class="material-icons" mat-dialog-close  style="float: right;cursor: pointer;color: red;">cancel</span>
</div>
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class=" col-md-6 full-width">
        <label class="form-control-label">MOM Name<span class="text-danger"> *</span></label>
        <input matInput class="form-control email-input select-placeholder" 
        formControlName="momName" placeholder="Enter MOM name" (keypress)="keyPress($event)">
        <div *ngIf="(f.momName.invalid && submitted) || f.momName.dirty">
          <small *ngIf="f.momName.errors?.required" class="text-danger">MOM name is required</small>
        </div>
        <!-- <mat-error *ngIf="f.momName.hasError('required')">
          MOM Name is required
        </mat-error> -->
      </div>
      <div class="col-md-6 full-width">
        <label class="form-control-label">MOM Date<span class="text-danger"> *</span></label>
        <input matInput [matDatepicker]="picker1" formControlName="momDate"
        [max]="tomorrow"  style="height: 43px;" class="form-control email-input" placeholder="Select MOM date" (dateChange)="dateChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"> </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <div *ngIf="(f.momDate.invalid && submitted) || f.momDate.dirty">
          <small *ngIf="f.momDate.errors?.required" class="text-danger">MOM date is required</small>
        </div>
        <!-- <mat-error *ngIf="f.momDate.hasError('required')">
          MOM Date is required
        </mat-error> -->
      </div>
    </div>
    <div style="position: relative;bottom:15px">
      <div class="row">
        <div class="form-group col-md-6 full-width">
          <label class="form-control-label">Client Name<span class="text-danger"> *</span></label>
          <!-- <input matInput formControlName="client" (keypress)="keyPress($event)"
            class="form-control email-input select-placeholder" style="height: 43px;"
            placeholder="Enter Client Name"> -->
            <mat-select formControlName="client" (keypress)="keyPress($event)"
            class="form-control email-input select-placeholder" style="height: 43px;"
            placeholder="Enter client name">
              <mat-option *ngFor="let e of customerList" [value]="e.customername">
                  {{e.customername}}
              </mat-option>
            </mat-select>
          <div *ngIf="(f.client.invalid && submitted) || f.client.dirty">
            <small *ngIf="f.client.errors?.required" class="text-danger">Client name is required</small>
          </div>
          <!-- <mat-error *ngIf="f.client.hasError('required')">
            Client Name is required
          </mat-error> -->
        </div>
        <div class="form-group col-md-6 full-width">
          <label class="form-control-label">Meeting Place<span class="text-danger"> *</span></label>
          <input matInput formControlName="meetingPlace" class="form-control email-input select-placeholder"
          placeholder="Enter meeting place" >
          <div *ngIf="(f.meetingPlace.invalid && submitted) || f.meetingPlace.dirty">
            <small *ngIf="f.meetingPlace.errors?.required" class="text-danger">Meeting place is required</small>
          </div>
          <!-- <mat-error *ngIf="f.meetingPlace.hasError('required')">
            Meeting Place is required
          </mat-error> -->
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 full-width">
          <label class="form-control-label">Attendees<span class="text-danger"> *</span></label>
          <textarea matInput formControlName="momAttendees" row="200"
            placeholder="Enter attendees" class="form-control email-input text-placeholder"></textarea>
          <div *ngIf="(f.momAttendees.invalid && submitted) || f.momAttendees.dirty">
            <small *ngIf="f.momAttendees.errors?.required" class="text-danger">Attendees are required</small>
          </div>
          <!-- <mat-error *ngIf="f.momAttendees.hasError('required')">
            Attendees are required
          </mat-error> -->
        </div>
        <div class="form-group col-md-6 full-width">
          <label class="form-control-label">Project Type<span class="text-danger"> *</span></label>
          <mat-select  class="form-control email-input select-placeholder" formControlName="projectValue" 
            placeholder="Project">
            <mat-option *ngFor="let pro of myProject" (click)="onChange(pro.id)" [value]="pro.id">
              {{pro.value}}
            </mat-option>
          </mat-select>
          <div *ngIf="(f.projectValue.invalid && submitted) || f.projectValue.dirty">
            <small *ngIf="f.projectValue.errors?.required" class="text-danger">MOM type is required</small>
          </div>
          <!-- <mat-error *ngIf="f.projectValue.hasError('required')">
            Project Type is required
          </mat-error> -->
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6 full-width" *ngIf="isShow">
          <label class="form-control-label">Project<span class="text-danger"> *</span></label>
          <mat-select formControlName="project" class="form-control email-input select-placeholder"
            placeholder="Select project">
            <mat-option *ngFor="let pro of projectList" [value]="pro.projectId">
              {{pro.projectName}}
            </mat-option>
          </mat-select>
          <div *ngIf="(f.project.invalid && submitted) || f.project.dirty">
            <small *ngIf="f.project.errors?.required" class="text-danger">Project name is required</small>
          </div>
          <!-- <mat-error *ngIf="f.project.hasError('required')">
            Project Name is required
          </mat-error> -->
        </div>
      </div>
      <div formArrayName="pointsDiscussed" >
        <div class="row">
          <!-- <div class="float-left col-md-12">
            <b>The following points were discussed in the meeting.</b>
            <button type="button" class="btn add-buttons" (click)="add()"><mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon></button>
          </div> -->
          <div class="col-md-12 p-0">
            <div class="col-md-6 float-left">
              <b>The following points were discussed in the meeting.</b>
            </div>
            <div class="col-md-6 float-left">
              <button type="button" class="btn add-buttons" (click)="add()">
               <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
               </button>
             </div>
          </div>
        </div>
        <!-- <div align="end">
          <button type="button" class="btn add-buttons" (click)="add()">Add</button>
        </div> -->
        <div *ngFor="let con of content().controls; let i=index">
          <div [formGroupName]="i" class="d-flex">
            <div class="form-group" style="width: 95%;">
              <label class="form-control-label">Discussed Points<span class="text-danger"> *</span></label>
              <textarea formControlName="pointsDiscussedDetails" matInput 
                class="form-control email-input text-placeholder"
                placeholder="Enter discussed points" ></textarea>
              <div *ngIf="(submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty">
                <small *ngIf="con['controls'].pointsDiscussedDetails.errors?.required" class="text-danger">Discussed points are required</small>
              </div> 
              <!-- <mat-error *ngIf="content().controls[i].get('contentTitle').errors?.required">
                Discussed Points is required
              </mat-error> -->
            </div>
            <div class="form-group aligncenter">
              <label></label>
              <mat-icon matSuffix (click)="remove(i,con)" *ngIf="i >= 1"
              class="material-icons-outlined delete-icon">delete</mat-icon>
            </div>
            <!-- <div formArrayName="subContent">
              <div *ngFor="let sub of myContent(i).controls; let contentIndex=index">
                <div [formGroupName]="contentIndex">
                  <div class="form-group col-md-12 full-width">
                    <label class="form-control-label">SubContentTitle</label>
                    <mat-icon matSuffix (click)="removesubContent(i,contentIndex)" class="material-icons-outlined delete-icon">delete</mat-icon>
                    <input matInput formControlName="subContentTitle"  
                    class="form-control email-input select-placeholder" 
                    placeholder="Enter Sub Content"/>
                    <div *ngIf="(submitted && sub['controls'].subContentTitle.invalid) || sub['controls'].subContentTitle.dirty">
                      <small *ngIf="sub['controls'].subContentTitle.errors?.required" class="text-danger">Sub Content Title is required</small>
                    </div> 
                    <!-- <mat-error *ngIf="myContent(i).controls[contentIndex].get('subContentTitle').errors?.required">
                      Sub Content Title is required
                    </mat-error> -->
                  <!--</div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div formArrayName="pointsAgreed" >
          <div class="row">
            <!-- <div class="float-left col-md-12">
            <b>The following points were agreed in the meeting.</b>
            <button type="button" class="btn add-buttons" (click)="addAgree()">
              <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
            </button>
          </div> -->
          <div class="col-md-12 p-0">
            <div class="col-md-6 float-left">
              <b>The following points were agreed in the meeting.</b>
            </div>
            <div class="col-md-6 float-left">
             <button type="button"  class="btn add-buttons" (click)="addAgree()">
               <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
             </button>
            </div>
          </div>
        </div>
        <!-- <div align="end">
          <button type="button"   class="btn add-buttons" (click)="addAgree()">Add</button>
        </div> -->
        <div *ngFor="let con of agree().controls; let i=index">
          <div [formGroupName]="i" class="d-flex">
            <div class="form-group" style="width: 95%;">
              <label class="form-control-label">Agreed point<span class="text-danger"> *</span></label>
              <textarea formControlName="pointsAgreedDetails" matInput class="form-control email-input text-placeholder"
              placeholder="Enter agreed points"></textarea>
              <div *ngIf="(submitted && con['controls'].pointsAgreedDetails.invalid) || con['controls'].pointsAgreedDetails.dirty">
                <small *ngIf="con['controls'].pointsAgreedDetails.errors?.required" class="text-danger">Agreed points are required</small>
              </div> 
              <!-- <mat-error *ngIf="agree().controls[i].get('agreeTitle').errors?.required">
                Agreed point is required
              </mat-error> -->
            </div>
            <div class="form-group aligncenter">
              <mat-icon matSuffix (click)="removeAgree(i,con)"
              *ngIf="i >= 1" class="material-icons-outlined delete-icon">delete</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="actionItem">
        <div class="row">
          <!-- <div class="float-left col-md-12">
            <b>The following points are action items in the meeting.</b>
            <button *ngIf="myForm.value.actionItem" type="button" class="btn add-buttons" (click)="addItem()"><mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon></button>
          </div> --> 
          <div class="col-md-12 p-0">
            <div class="col-md-6 float-left">
              <b>The following points were action items in the meeting.</b>
            </div>
             <div class="col-md-6 float-left">
               <button type="button" class="btn add-buttons" (click)="addItem()">
                 <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
               </button>
             </div>
          </div>
        </div>
        <!-- <div align="end" *ngIf="myForm.value.actionItem">
            <button type="button" class="btn add-buttons" (click)="addItem()">Add</button>
        </div> -->
        <div *ngIf="myForm.value.actionItem">
          <div *ngFor="let action of actionItem().controls; let i=index">
            <div [formGroupName]="i" >
              <div class="row">
                <div class="col-md-4 full-width">
                    <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                    <input matInput formControlName="actionItemName" (keypress)="keyPress($event)"
                    class="form-control email-input select-placeholder"
                    placeholder="Enter action item name"/>
                    <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                      <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action item name is required</small>
                    </div> 
                    <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemName').errors?.required">
                      Action Item Name is required
                    </mat-error> -->
                </div>
                <div class="col-md-3 full-width">
                    <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                    <input matInput [matDatepicker]="picker" formControlName="actionItemTargetDate"  
                      class="form-control email-input"  
                      style="height: 43px;"
                      placeholder="Select target date"
                      [min]="momdate">
                      <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                    <mat-datepicker-toggle matSuffix [for]="picker"  placeholder="Select target date"
                    style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                      <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                    </div> 
                    <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemTargetDate').errors?.required">
                      Action Item TargetDate is required
                    </mat-error> -->
                </div>
                <div class="col-md-5 full-width M-responsive">
                    <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                    <input matInput formControlName="actionItemOwner" (keypress)="keyPress($event)"
                    placeholder="Enter action item owner"
                    class="form-control email-input select-placeholder"/>
                    <div *ngIf="(submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty">
                      <small *ngIf="action['controls'].actionItemOwner.errors?.required" class="text-danger">Action item owner is required</small>
                    </div> 
                    <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemOwner').errors?.required">
                      Action Item Owner is required
                    </mat-error> -->
                </div>
                <div class="aligncenter1">
                  <mat-icon matSuffix (click)="removeItem(i,action)" *ngIf="i >= 0" 
                    class="material-icons-outlined deleteitem-icon">delete</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div align="end">
        <button mat-raised-button type="button" class="btn cancel-button m-1"
        mat-dialog-close  style="color: black;">Cancel</button>
        <button mat-raised-button type="submit" class="btn create-button m-1">
          {{momId ? 'Update' : 'Submit'}}</button>
      </div>
    </div>
  </form>
  
