<div class="row">
  <div class="col-md-11">
    <h5 style="color: #03c9d7; font-weight:600">Employee Timesheet</h5>
  </div>
  <div class="col-md-1">
    <h3 class="material-icons float-right cancel-icon" mat-dialog-close>
      cancel
    </h3>
  </div>
</div>
<div class="row p-0 m-0">
    <div class="col-md-10">
      <span class="badge badge-light totaltime">Total Time : {{tasktotalTime}}</span>
    </div>
    <div class="col-md-2">
      <div class="col-xl-12 col-lg-12 p-0">
          <div class="select-pagination">
              <span>Show : </span>
              <select class="select_list new-select_list" [(ngModel)]="mySelectpopup">
                  <option class="form-control" value="5"> 5</option>
                  <option class="form-control" value="10">10</option>
                  <option class="form-control" value="15">15</option>
                  <option class="form-control" value="20">20</option>
                  <option class="form-control" value="25">25</option>
              </select>
          </div>
      </div>
    </div>
</div>
<table class="table" matSort (matSortChange)="sortData($event)"  >
    <thead>
      <tr>
        <!-- <th style="text-align: center;" scope="col">Sl.No</th> -->
        <th mat-sort-header="employeeName"  scope="col">Employee&nbsp;&nbsp;Name</th>
        <th mat-sort-header="projectName"  scope="col">Project&nbsp;&nbsp;Name</th>
        <th mat-sort-header="taskName"  scope="col">Task&nbsp;&nbsp;Name</th>
        <th mat-sort-header="date" style="text-align: center;" scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
        <th mat-sort-header="workedHours" style="text-align: center;" scope="col">Worked&nbsp;&nbsp;Hours</th>
        <th mat-sort-header="taskStatusName" scope="col">Task&nbsp;&nbsp;Status</th>
        <th scope="col">Approval&nbsp;&nbsp;Status</th>
        <th style="text-align: center;" scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor ="let e of submittedreport |  timesheet:search ;
      let i = index">
        <td>{{ e.employee.employeeName | titlecase }}</td>
        <td>{{ e.project.projectName | titlecase }}</td>
        <td>{{ e.task.taskName | titlecase }}</td>
        <td>{{ e.date}}</td>
        <td style="text-align: center;" >{{ e.workedHours }}</td>
        <td [style.color]="withDrawStatus(e)" class="status">{{ e.task.taskStatusName }}</td>
        <td [style.color]="approvalStatus(e)"  class="status">{{ e.approvalStatusName }}</td>
        <td class="more" align="center">
          <span
            class="material-icons"
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu">
            more_vert
          </span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="description(e)">
              <mat-icon class="edit-icon">date_range</mat-icon>
              <span class="edit-form">View Description</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
</table>
<!-- <pagination-controls (pageChange)="page = $event ; beforesearchpagepopup  = $event" align="end"></pagination-controls> -->

<mat-card class="card-content" *ngIf="nodata">
<div class="card-body">
  <div class="alert alert-primary">
    <h4 align="center">Timesheet Not Found</h4>
  </div>
</div>
</mat-card>
