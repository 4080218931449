<html>
    <body class="container-fluid d-flex h-100 login-background">     
        <div class="card-body login-card-body card-background col-md-5 align-self-center">
            <form [formGroup]="otpForm" (ngSubmit)="submitData()">                  
                <div class="col-md-12 content">
                    <h1 class="login-title">OTP</h1>
                </div> 
                <div class="col-md-12" id="loginform">
                    <input type="text" class="form-control email-input"  formControlName="otp" maxlength="6" placeholder="123456">
                    <div *ngIf="(otp.invalid && otp.touched) || otp.dirty">
                        <small *ngIf="otp.errors?.required" class="text-danger">Please enter OTP</small>
                        <small *ngIf="otp.errors?.pattern" class="text-danger">Please enter valid OTP</small>   
                    </div> 
                </div>   
                <div class="col-md-12" id="loginform">
                <span><a routerLink="/email/{{organizationEmail}}" class="Resend" >Resend OTP</a></span>
                </div>
                         
                <div class="col-md-12 text-center login-button">
                    <Button type="submit" [disabled]="otpForm.invalid" class="btn btn-block login-button-text">Submit</Button>
                </div>
                <div>
                    <p class="text-danger text-center mt-3 mb-0">{{error_msg}}</p>
                </div>
            </form>  
        </div>
    </body>
</html>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
<div *ngIf="loader" class="align-items-center text-center  align-self-center" style="position: relative;bottom: 54vh; z-index: 2;">
    <div class="spinner-border align-self-center" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
