import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalserviceService } from '../providers/globalservice.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-mom',
  templateUrl: './create-mom.component.html',
  styleUrls: ['./create-mom.component.css']
})
export class CreateMomComponent implements OnInit {

  submitted:boolean = false;
  myForm: FormGroup;
  @ViewChild('select') select: MatSelect;

  allSelected=false;
  employeeList:any = []
  myProject = [
    {
      id:1,
      value:'Project'
    },
    {
      id:2,
      value:'Non Project'
    }
  ]
  momId:any
  projectList:any = []
  isShow:Boolean = false;
  tomorrow = new Date();
  User_Type: any;
  customerList: any;
  momdate: any;
  modifiedmomdate: string;
  modifiedTargetDate: string;

  constructor(private gs:GlobalserviceService,
              private fb:FormBuilder,
              private route:ActivatedRoute,
              private router:Router,
              private datepipe : DatePipe,) {
              }

  ngOnInit() {
    // const myData = JSON.parse(localStorage.getItem('MOMdata'));
    const myData = JSON.parse(localStorage.getItem('UserData'));
    console.log(myData);
    this.gs.getResourceById(myData.employeeId).subscribe(
      (x:any)=>{
        this.User_Type = x.userType.userType;
        console.log(this.User_Type)
      }
    )
    this.gs.Get_Customer().subscribe((x:any)=>{
      this.customerList = x;
      console.log(this.customerList)
    })
    this.route.paramMap.subscribe(
      d=>{
        this.momId = d.get('id')
        if(this.momId){
          this.myForm = this.fb.group({
            momName:['',Validators.required],
            momAttendees:['',Validators.required],
            client:['',Validators.required],
            meetingPlace:['',Validators.required],
            momDate:['',Validators.required],
            projectValue:['',Validators.required],
            project:[''],
            pointsDiscussed: this.fb.array([
            ]),
            pointsAgreed:this.fb.array([
            ]),
            actionItem:this.fb.array([
            ])
          });
          this.gs.get_momById(this.momId).subscribe(
            (data:any)=>{
              if(data.project){
                  this.isShow = true
                  this.myForm.patchValue({
                    momName:data.momName,
                    momAttendees:data.momAttendees,
                    momDate:data.momDate,
                    client:data.client,
                    projectValue:1,
                    project:data.project.projectId,
                    meetingPlace:data.meetingPlace,
                    pointsDiscussed:data.pointsDiscussed.forEach(
                      e=>{
                        this.content().push(
                          this.fb.group({
                            pointsDiscussedId:e.pointsDiscussedId,
                            pointsDiscussedDetails: e.pointsDiscussedDetails,
                          })
                        )
                      }
                    ),
                    pointsAgreed:data.pointsAgreed.forEach(
                      e=>{
                        this.agree().push(
                          this.fb.group({
                            pointsAgreedId:e.pointsAgreedId,
                            pointsAgreedDetails: e.pointsAgreedDetails,
                          })
                        )
                      }
                    ),
                    actionItem:data.actionItem.forEach(
                      e=>{
                        this.actionItem().push(
                          this.fb.group({
                            actionItemId:e.actionItemId,
                            actionItemName:e.actionItemName,
                            actionItemTargetDate:e.actionItemTargetDate,
                            actionItemOwner:e.actionItemOwner,
                            actionItemStatus:e.actionItemStatus
                          })
                        )
                      }
                    )
                  })
              }
              else{
                this.myForm.patchValue({
                  momName:data.momName,
                  momAttendees:data.momAttendees,
                  momDate:data.momDate,
                  client:data.client,
                  projectValue:2,
                  project:'',
                  meetingPlace:data.meetingPlace,
                  pointsDiscussed:data.pointsDiscussed.forEach(
                    e=>{
                      this.content().push(
                        this.fb.group({
                          pointsDiscussedId:e.pointsDiscussedId,
                          pointsDiscussedDetails: e.pointsDiscussedDetails,
                        })
                      )
                    }
                  ),
                  pointsAgreed:data.pointsAgreed.forEach(
                    e=>{
                      this.agree().push(
                        this.fb.group({
                          pointsAgreedId:e.pointsAgreedId,
                          pointsAgreedDetails: e.pointsAgreedDetails,
                        })
                      )
                    }
                  ),
                  actionItem:data.actionItem.forEach(
                    e=>{
                      this.actionItem().push(
                        this.fb.group({
                          actionItemId:e.actionItemId,
                          actionItemName:e.actionItemName,
                          actionItemTargetDate:e.actionItemTargetDate,
                          actionItemOwner:e.actionItemOwner,
                          actionItemStatus:e.actionItemStatus
                        })
                      )
                    }
                  )
                })
              }
            }
          )
        }
        else{
          this.myForm = this.fb.group({
            momName:['',Validators.required],
            momAttendees:['',Validators.required],
            client:['',Validators.required],
            meetingPlace:['',Validators.required],
            momDate:['',Validators.required],
            projectValue:['',Validators.required],
            project:[''],
            pointsDiscussed: this.fb.array([
              this.newContent()
            ]),
            pointsAgreed:this.fb.array([
              this.newAgree()
            ]),
            actionItem:this.fb.array([
              // this.newAction()
            ])
          });
        }
      }
    )

    // this.gs.get_projectsByUserId(myData.employeeId).subscribe(
    //   (a:any)=>{
    //     this.projectList = a
    //     console.log(this.projectList);
    //   }
    // )
    this.gs.getEmpProjectList(myData.employeeId)
    .subscribe((pa:any)=>{
      this.projectList = pa;
    });
  }


  // setProjects(x) {
  //   let arr = new FormArray([]);
  //   x.subContent.forEach(y => {
  //     arr.push(
  //       this.fb.group({
  //         subContentId:y.subContentId,
  //         subContentTitle: y.subContentTitle
  //       })
  //     );
  //   });
  //   return arr;
  // }


  content(): FormArray {
    return this.myForm.get('pointsDiscussed') as FormArray;
  }

  agree(): FormArray {
    return this.myForm.get('pointsAgreed') as FormArray;
  }

  actionItem(): FormArray {
    return this.myForm.get('actionItem') as FormArray;
  }

  newContent(): FormGroup {
    return this.fb.group({
      pointsDiscussedDetails: ['',Validators.required],
    });
  }

  newAgree(): FormGroup {
    return this.fb.group({
      pointsAgreedDetails: ['',Validators.required],
    });
  }

  newAction(): FormGroup {
    return this.fb.group({
      actionItemName: ['',Validators.required],
      actionItemTargetDate:['',Validators.required],
      actionItemOwner:['',Validators.required],
      actionItemStatus:['']
    });
  }

  get f(){
    return this.myForm.controls;
  }

  add() {
    this.content().push(this.newContent());
  }

  addAgree(){
    this.agree().push(this.newAgree());
  }
  dateChange(event) {
    console.log(event.value);
    this.momdate = event.value;
  }

  onChange(e){
   if(e == 1){
     this.isShow = true
   }
   else{
    this.isShow = false
   }
  }

  remove(index: number) {
    if(index == 0){
      return
    }
    else{
      this.content().removeAt(index);
    }
  }

  addItem() {
    this.actionItem().push(this.newAction());
  }

  removeItem(index: number) {
    // if(index == 0){
    //   return
    // }
    // else{
      this.actionItem().removeAt(index);
    // }
  }

  removeAgree(index: number) {
    if(index == 0){
      return
    }
    else{
      this.agree().removeAt(index);
    }
  }

  // myContent(index: number): FormArray {
  //   return this.content()
  //     .at(index)
  //     .get('subContent') as FormArray;
  // }

  // newSkill(): FormGroup {
  //   return this.fb.group({
  //     subContentTitle: ['',Validators.required],
  //   });
  // }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z0-9_ .-]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // addsubContent(index: number) {
  //   this.myContent(index).push(this.newSkill());
  // }

  // removesubContent(index: number, i: number) {
  //   this.myContent(index).removeAt(i);
  // }

  cancel(){
    this.router.navigate(['./MOM-report'])
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  // addEmpList(){
  //   let x = this.myForm.value;
  //   if(x.momAttendees == ''){
  //     alert("Please select the options")
  //   }
  //   else{
  //     this.select.close()
  //   }
  // }

  // cancel(){
  //   this.myForm.patchValue({momAttendees:''})
  //   this.select.close()
  // }

  onSubmit() {
    this.submitted=true;
    console.log(this.submitted);

    this.modifiedmomdate= this.datepipe.transform(this.myForm.value.momDate, 'YYYY-MM-dd');
    this.myForm.value['momDate'] =this.modifiedmomdate;
    
    this.myForm.value.actionItem.forEach(
      e=>{
        this.modifiedTargetDate= this.datepipe.transform(e.actionItemTargetDate, 'YYYY-MM-dd');
        e['actionItemTargetDate'] =this.modifiedTargetDate;
      }
    )

    if(this.myForm.invalid){
      return
    }
    if(this.content().status == 'INVALID'){
        return
    }
    if(this.agree().status == 'INVALID'){
        return
    }
    let x = this.myForm.value;
    console.log("Form Values"+x);

    // let text =  x.momAttendees.toString();
    // x.momAttendees = text

    if(this.momId){
      let x = this.myForm.value;
      x['momId'] = this.momId;
      if(x.projectValue == 1){
        x['project'] = {
          projectId : Number(x.project)
        }
      }
      if(x.projectValue == 2){
        delete x.project;
      }

      // this.actionItem().controls.forEach(
      //   e=>{
      //     if(e.get('actionItemName').value == ''){
      //       e.get('actionItemName').setErrors({ 'required': true })
      //     }
      //     if(e.get('actionItemOwner').value == ''){
      //       e.get('actionItemOwner').setErrors({ 'required': true })
      //     }
      //     if(e.get('actionItemTargetDate').value == ''){
      //       e.get('actionItemTargetDate').setErrors({ 'required': true })
      //     }
      //     if(e.get('actionItemStatus').value == ''){
      //       e.get('actionItemStatus').setErrors({ 'required': true })
      //     }
      //   }
      // )
      // this.content().controls.forEach(
      //   e=>{
      //     console.log(e)
      //     if(e.get('pointsDiscussedDetails').value == ''){
      //       e.get('pointsDiscussedDetails').setErrors({ 'required': true })
      //     }
      //   }
      // )
      // this.agree().controls.forEach(
      //   e=>{
      //     if(e.get('pointsAgreedDetails').value == ''){
      //       e.get('pointsAgreedDetails').setErrors({ 'required': true })
      //     }
      //   }
      // )
      // if(this.actionItem().status == 'INVALID'){
      //   return
      // }
      // if(this.content().status == 'INVALID'){
      //   return
      // }
      // if(this.agree().status == 'INVALID'){
      //   return
      // }
      console.log(JSON.stringify(x))
      return
      this.gs.Update_mom(this.momId,x).subscribe(
        (d:any)=>{
          Swal.fire({
            text:d.description,
            icon:'success'
          })
          this.router.navigate(['./MOM-report'])
        },
        err=>{
          Swal.fire({
            text:err.error.description,
            icon:'error'
          })
        }
      )
    }
    else{
      if(x.projectValue == 1 && x.project == ''){
        this.myForm.controls['project'].setErrors({ 'required': true })
        return
      }
      if(x.projectValue == 1){
        x['project'] = {
          projectId : Number(x.project)
        }
      }
      if(x.projectValue == 2){
        delete x.project;
      }
      if(x.actionItem){
        x.actionItem.map(
          e=>{
            e['actionItemStatus'] = "Pending"
          }
        )
      }
      if(this.myForm.value.actionItem){
        this.myForm.value.actionItem.map(
          e=>{
            if(e.actionItemName == ''){
              this.myForm.removeControl('actionItem');
            }
          }
        )
      }
      console.log(JSON.stringify(x));
      console.log(x);
      console.log(this.myForm.value);
        this.gs.Create_mom(this.myForm.value).subscribe(
          (d:any)=>{
            Swal.fire({
              text:d.description,
              icon:'success'
            })
           this.router.navigate(['./MOM-report']);
          },
          err=>{
            Swal.fire({
              text:err.error.description,
              icon:'error'
            })
          }
        )
    }
  }

}
