

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LyAvatarModule } from '@alyle/ui/avatar';
import { LyIconModule } from '@alyle/ui/icon';
import { LyGridModule } from '@alyle/ui/grid';
import { LyButtonModule } from '@alyle/ui/button';
import { LyTypographyModule } from '@alyle/ui/typography';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyBadgeModule } from '@alyle/ui/badge';


@NgModule({
  declarations: [],

  exports: [
    CommonModule,
    LyAvatarModule,
    LyIconModule,
    LyGridModule,
    LyButtonModule,
    LyTypographyModule,
    LyResizingCroppingImageModule,    
    LyDialogModule,
    LyButtonModule,
    LyToolbarModule,
    LyTypographyModule,
    LyButtonModule,
    LyBadgeModule,
    LyIconModule
  ],
})


export class mydesign {

}