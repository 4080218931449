<!-- <div id="bg"> -->
  <!-- <div class="container">
    <h4 align="center" style="color:#03c9d7!important;">Action Item</h4>
  </div>
  <div class="col-md-2" align="end">
    <span class="material-icons" style="color:red;" mat-dialog-close>cancel</span>
</div> -->
  <div class="row">
    <div class="col-md-10">
        <h4 align="center"  style="color:#03c9d7!important;">Action Item</h4>
    </div>
    <div class="col-md-2" align="end">
        <span class="material-icons" style="color:red;" mat-dialog-close>cancel</span>
    </div>
 </div>
  <form [formGroup]="actionForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="form-group col-sm-12 full-width">
        <!-- <mat-form-field class="full-width"> -->
        <!-- <mat-label id="lab">Action Item Name</mat-label> -->
        <!-- <input matInput  formControlName="actionItemName" placeholder="Enter Action Item Name" class="form-control"> -->
        <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
        <input matInput  class="form-control email-input select-placeholder" formControlName="actionItemName" placeholder="Enter Action Item Name">
        <!-- <input matInput class="form-control email-input select-placeholder" 
        formControlName="momName" placeholder="Enter MOM Name" (keypress)="keyPress($event)"> -->
        <mat-error *ngIf="f.actionItemName.hasError('required')">
          Action Item Name is required
        </mat-error>
        <!-- </mat-form-field> -->
      </div>
    </div>
    <!-- <div class="row"> -->
      <!-- <div class="form-group col-sm-12 full-width"> -->
         <!-- <mat-form-field class="full-width">  -->
         <!-- <mat-label id="lab">Target Date</mat-label> -->
        <!-- <input matInput [matDatepicker]="picker"  class="form-control" formControlName="actionItemTargetDate" -->
        <!-- placeholder="Ex-09/08/2021">  -->
        <!-- <label class="form-control-label">Target Date<span class="text-danger"> *</span></label> -->
        <!-- <input matInput [matDatepicker]="picker" class="form-control" formControlName="actionItemTargetDate"> -->
        <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
        <!-- <mat-datepicker #picker></mat-datepicker> -->
          <!-- <mat-error *ngIf="f.actionItemTargetDate.hasError('required')"> -->
          <!-- Target Date is required -->
          <!-- </mat-error> -->
         <!-- </mat-form-field>  -->
      <!-- </div> -->
    <!-- </div> -->
    <div class="row">
      <div class="col-sm-12 full-width">
       <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
       <input matInput [matDatepicker]="picker1" formControlName="actionItemTargetDate"
        style="height: 43px;" class="form-control" placeholder="Choose Date"  class="form-control email-input select-placeholder">
       <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"> </mat-datepicker-toggle>
       <mat-datepicker #picker1></mat-datepicker>
       <mat-error *ngIf="f.actionItemTargetDate.hasError('required')"> 
           Target Date is required 
       </mat-error> 
      </div>
    </div>
    <div style="position: relative;bottom: 20px;">
      <div class="row">
        <div class="form-group col-sm-12 full-width">
          <label class="form-control-label">Action Item Status<span class="text-danger"> *</span></label>
          <mat-select formControlName="actionItemStatus"  class="form-control email-input select-placeholder" style="height: 43px;">
            <mat-option value="Pending">Pending</mat-option>
            <mat-option value="Completed">Completed</mat-option>
          </mat-select>
          <mat-error *ngIf="f.actionItemStatus.hasError('required')">
            Action Item Status is required
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 full-width">
          <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
          <input matInput  class="form-control email-input select-placeholder" formControlName="actionItemOwner" placeholder="Enter Action Item Owner">
          <mat-error *ngIf="f.actionItemOwner.hasError('required')">
            Action Item Owner is required
          </mat-error>
        </div>
      </div>
    </div>
    <div class="form-group" align="end">
      <!-- <button mat-stroked-button type="button" color="warn" class="btns" mat-dialog-close id="cancel">Cancel</button> -->
      <button mat-stroked-button type="submit"  class="btn action" id="submit">Update</button>
    </div>
  </form>
  <!-- </div> -->
  
