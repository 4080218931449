<div class="container">

  <br>
  <!-- <h4 align="center">Assign Employee to this project</h4><br> -->
  <div class="top-content">
             
    <h4>Approvers</h4>
      <span class="material-icons c" style="color:red;"  mat-dialog-close>cancel</span>

</div>

    <div >
          <div >
            <div class="row" >
              <div class="col-md-8">
               <h6>{{ data.approval.approver.approverOneObject.employeeName}}</h6>
               <h6 class="email-id-new">{{ data.approval.approver.approverOneObject.organizationEmail }}</h6>
              </div>
              <div class="col-md-4">               
               <h6  class="approver-status" [style.color]="approvalStatus(data.approval.approverOneStatusName)" 
               [style.backgroundColor]="approvalBackground(data.approval.approverOneStatusName)" align="center"> {{ data.approval.approverOneStatusName }} </h6>
              </div>                
           </div>
           <hr/>
          </div>
          <div  *ngIf="data.approval.approver.approverTwoObject">
            <div class="row" >
              <div class="col-md-8">
               <h6>{{ data.approval.approver.approverTwoObject.employeeName}}</h6>
               <h6 class="email-id-new">{{ data.approval.approver.approverTwoObject.organizationEmail }}</h6>
              </div>
              <div class="col-md-4">               
               <h6 class="approver-status"  [style.color]="approvalStatus(data.approval.approverTwoStatusName)" 
               [style.backgroundColor]="approvalBackground(data.approval.approverTwoStatusName)" align="center"> {{ data.approval.approverTwoStatusName }}</h6>
              </div>                
           </div>
           <hr/>
          </div>
          <div  *ngIf="data.approval.approver.approverThreeObject">
            <div class="row" >
              <div class="col-md-8">
               <h6>{{ data.approval.approver.approverThreeObject.employeeName}}</h6>
               <h6 class="email-id-new">{{ data.approval.approver.approverThreeObject.organizationEmail }}</h6>
              </div>
              <div class="col-md-4">               
               <h6 class="approver-status" [style.color]="approvalStatus(data.approval.approverThreeStatusName)" 
               [style.backgroundColor]="approvalBackground(data.approval.approverThreeStatusName)" align="center"> {{ data.approval.approverThreeStatusName }}</h6>
              </div>                
           </div>
           <hr/>
          </div>
       
          <div  *ngIf="data.approval.approver.approverFourObject">
            <div class="row" >
              <div class="col-md-8">
               <h6>{{ data.approval.approver.approverFourObject.employeeName}}</h6>
               <h6 class="email-id-new">{{ data.approval.approver.approverFourObject.organizationEmail }}</h6>
              </div>
              <div class="col-md-4">               
               <h6  class="approver-status" [style.color]="approvalStatus(data.approval.approverFourStatusName)" 
               [style.backgroundColor]="approvalBackground(data.approval.approverFourStatusName)" align="center" > {{ data.approval.approverFourStatusName }}</h6>
              </div>                
           </div>
           <hr/>
          </div>
         
          <div  *ngIf="data.approval.approver.approverFiveObject">
            <div class="row" >
              <div class="col-md-8">
               <h6>{{ data.approval.approver.approverFiveObject.employeeName}}</h6>
               <h6 class="email-id-new">{{ data.approval.approver.approverFiveObject.organizationEmail }}</h6>
              </div>
              <div class="col-md-4">               
               <h6 class="approver-status" [style.color]="approvalStatus(data.approval.approverFiveStatusName)" 
               [style.backgroundColor]="approvalBackground(data.approval.approverFiveStatusName)" align="center"> {{ data.approval.approverFiveStatusName }}</h6>
              </div>                
           </div>
          <hr/>
          </div>
          <div class="top-content">
             
            <h4>FYI</h4>
        
        </div>
          <div  >
            <div class="row" >
              <div class="col-md-6">
               <h6>{{ data.approval.approver.fyiObject.employeeName}}</h6>
               <h6 class="email-id-new">{{ data.approval.approver.fyiObject.organizationEmail }}</h6>
              </div>                 
           </div>
          </div>
        </div>
      
        
       
    
 
</div>

