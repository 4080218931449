import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ViewdescripeComponent } from 'src/app/viewdescripe/viewdescripe.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-viewtimesheet-report',
  templateUrl: './viewtimesheet-report.component.html',
  styleUrls: ['./viewtimesheet-report.component.css']
})
export class ViewtimesheetReportComponent implements OnInit {
  submittedreport:any;
  employeeId:any;
  today:any
  timesheetDate: any;
  currenttimesheetDate:any;
  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelectpopup:any =5;
  search: any;
  page:any;
  beforesearchpagepopup: any;
  nodata;
  tasktotalTime:any;

  constructor( public dialog: MatDialog,
    private gs: GlobalserviceService,
    private datepipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    this.employeeId=this.data;

    if(this.data.timesheetDate == undefined && this.data.projectId==0 ){
      this.today = new Date();
      this.currenttimesheetDate = this.datepipe.transform(this.today, 'YYYY-MM-dd');
      console.log(this.currenttimesheetDate);
      this.gs.getDetailSubmittedTimesheetByEmployeeId(this.currenttimesheetDate,this.data.employeeId)
      .subscribe((data)=>{
        console.log(data);
        this.submittedreport =data;
        this.nodata = false;
        this.tasktotalTime = this.submittedreport[0].totalTime;
      },
      (error) => {
        this.nodata = true;
      });
    }
    else{
      if(this.data.projectId==0 || this.data.projectId==undefined){
        this.gs.getDetailSubmittedTimesheetByEmployeeId(this.data.timesheetDate,this.data.employeeId)
        .subscribe((data)=>{
          console.log(data);
          this.submittedreport =data;
          this.nodata = false;
          this.tasktotalTime = this.submittedreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
        });
      }
      else{
        this.gs.getDetailSubmittedTimesheetByEmployeeIdAndProjectId(this.data.timesheetDate,this.data.employeeId,this.data.projectId)
        .subscribe((data)=>{
          console.log(data);
          this.submittedreport =data;
          this.nodata = false;
          this.tasktotalTime = this.submittedreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
        });
      }
    }
  }
  changepage(){
    if(this.search.length != 0)
    {
      this.page = 1;
    }else{
      this.page = this.beforesearchpagepopup;
    }
  }
  x: boolean;
  sort() {
    this.x = !this.x
    if (this.x == true) {

      this.submittedreport.sort(function (a: any, b: any) { return b.empNo - a.empNo });
    }
    else {
      this.submittedreport.sort(function (a: any, b: any) { return a.empNo - b.empNo });
    }
  }
  withDrawStatus(i) {
    if (i.task.taskStatusName == null) {
      return
    }
    if (i.task.taskStatusName == 'To Do') {
      return '#ffc107'
    }
    if (i.task.taskStatusName == 'Completed') {
      return '#28a745'
    }
    if (i.task.taskStatusName == 'In Progress') {
      return '#f44336'
    }
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return
    }
    if (i.approvalStatusName == 'Pending') {
      return '#ffc107'
    }
    if (i.approvalStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.approvalStatusName == 'Denied') {
      return '#f44336'
    }
  }
  description(e) {
    console.log(e.description)
    const dialogRef = this.dialog.open(ViewdescripeComponent, {
      width: '500px',
      data: e
    });
  }
  sortData(sort: Sort) {
    const data = this.submittedreport.slice();
    if (!sort.active || sort.direction === '') {
      this.submittedreport = data;
      return;
    }

    this.submittedreport = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        case 'projectName':
          return this.compare(a.project.projectName, b.project.projectName, isAsc);
        case 'taskName':
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case 'employeeName':
          return this.compare(a.employee.employeeName, b.employee.employeeName, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'workedHours':
            return this.compare(a.workedHours, b.workedHours, isAsc);
        case 'taskStatusName':
            return this.compare(a.task.taskStatusName, b.task.taskStatusName, isAsc);


        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
