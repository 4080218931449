import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(status): any {
    if(status == 'Active') {
      
    }
    return null;
  }

}
