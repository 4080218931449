import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-approver-policy-hierarchy',
  templateUrl: './add-approver-policy-hierarchy.component.html',
  styleUrls: ['./add-approver-policy-hierarchy.component.scss']
})
export class AddApproverPolicyHierarchyComponent implements OnInit {
  organzationId: any;
  ApproverPolicyForm: FormGroup;
  approvertype: any = [];
  approverpolicyobject: any = {};
  approverpolicyexist: boolean;
  approvertypesame: boolean;
  approverpolicydata :any = {};
  constructor(public dialogRef: MatDialogRef<AddApproverPolicyHierarchyComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private gs: GlobalserviceService , private dialog:MatDialog) { }

  ngOnInit(): void {

    const Data = JSON.parse(localStorage.getItem('UserData'))
    this.organzationId = Data.organization.organizationId;
    console.log("organziationid " + this.organzationId);
   
    this.ApproverPolicyForm = this.fb.group({
      organization: {
        organizationId:  this.organzationId
      },
      approverpolicyname: ['', Validators.required ,],
      firstapprovertype: ['', Validators.required],
      secondapprovertype: ['', Validators.required],
      default : false
    })

    
    this.gs.GetListById(8).subscribe(
      (data)=>{
        this.approvertype = data;
      }
    )

      if(this.data.approversPolicyId)
      {
        this.gs.getapproverpolicyById(this.data.approversPolicyId).subscribe(
          (data)=>{
            this.approverpolicydata = data;
            console.log( this.approverpolicydata);
                    let  approversPolicyHierarchy = this.approverpolicydata.approversPolicyHierarchy;
                    let firstapprovertype ;
                     let secondapprovertype;
             for(let i= 0 ; i<approversPolicyHierarchy.length ; i++)
             {
                   if(approversPolicyHierarchy[i].approversHierarchyLevel == 1)
                   {
                    firstapprovertype = approversPolicyHierarchy[i].approversType;
                   }else if(approversPolicyHierarchy[i].approversHierarchyLevel == 2){
                    secondapprovertype = approversPolicyHierarchy[i].approversType;
                   }
             }
            this.ApproverPolicyForm.patchValue({
              approverpolicyname: this.data.approversPolicyName,
              firstapprovertype : firstapprovertype,
              secondapprovertype : secondapprovertype,
              default : this.data.approversPolicyDefault , 
              organization : this.data.organization
              })
          }
        )
      }
  }

  get f() {
    return this.ApproverPolicyForm.controls;
  }

  approverpolicyvalidate()
  {
   
    let approversPolicyId = 0;
    if(this.data)
    {
      approversPolicyId = this.data.approversPolicyId;
    }
   
        if(this.ApproverPolicyForm.value.approverpolicyname != 0)
        {
           
          this.gs.getApproverPolicyName(this.ApproverPolicyForm.value.approverpolicyname , approversPolicyId).subscribe(a => {
           //   console.log(a);
              this.approverpolicyobject = a;
                if(this.approverpolicyobject.isexist)
                {
                  //  console.log("role name isexist");
                    this.approverpolicyexist = true;
                }else{
                  this.approverpolicyexist = false;
                }
          })
        }else{
          this.approverpolicyexist = false;
        }
    
   
   

  }

  addapproverpolicy()
  {
         
    var formdata = this.ApproverPolicyForm.value;
       // console.log("formdata" + JSON.stringify(formdata));
        if(this.ApproverPolicyForm.invalid)
        {
             return
        }

        if(this.ApproverPolicyForm.valid && this.approverpolicyexist || this.approvertypesame)
        {
            return
        }

       
            

        var approverpolicy = {
          "approversPolicyName": this.ApproverPolicyForm.value.approverpolicyname,
          "approversPolicyStatus": 1,
          "approversPolicyDefault": this.ApproverPolicyForm.value.default,
          "organization": this.ApproverPolicyForm.value.organization,
      
          "approversPolicyHierarchy": [
            {
             
              "approversType":  this.ApproverPolicyForm.value.firstapprovertype,
              "approversHierarchyLevel": 1    
              },
              {          
              "approversType": this.ApproverPolicyForm.value.secondapprovertype,
              "approversHierarchyLevel": 2           
              }
          ]
        }

          console.log(approverpolicy);

          if(this.data.approversPolicyId)
          {

              console.log()
            var approverpolicyupdate = {

              "approversPolicyId": this.approverpolicydata.approversPolicyId,
              "approversPolicyName": this.ApproverPolicyForm.value.approverpolicyname,
              "approversPolicyStatus": 1,
              "approversPolicyDefault": this.ApproverPolicyForm.value.default,
              "organization": this.ApproverPolicyForm.value.organization,
          
              "approversPolicyHierarchy": [
                {
                  "approversPolicyHierarchyId":  this.approverpolicydata.approversPolicyHierarchy[0].approversPolicyHierarchyId,
                  "approversType":  this.ApproverPolicyForm.value.firstapprovertype,
                  "approversHierarchyLevel": 1    
                  },
                  {          
                    "approversPolicyHierarchyId": this.approverpolicydata.approversPolicyHierarchy[1].approversPolicyHierarchyId,
                  "approversType": this.ApproverPolicyForm.value.secondapprovertype,
                  "approversHierarchyLevel": 2           
                  }
              ]
            }

            this.gs.updateapproverHiearchy(approverpolicyupdate).subscribe(a => {
              Swal.fire({
                icon: 'success',
                text: " Approver  Policy Updated Successfully"
              })
              window.location.reload()
                this.dialog.closeAll()
            } ,
            err =>{
              Swal.fire({
                icon: 'error',
                text: err.error.message
              })
            }
            )

                 console.log("update apprver data" + JSON.stringify(approverpolicyupdate));

          }else{
            

             
            this.gs.createapproverHiearchy(approverpolicy).subscribe(a => {
              Swal.fire({
                icon: 'success',
                text: " Approver  Policy Created Successfully"
              })
              window.location.reload()
                this.dialog.closeAll()
            } ,
            err =>{
              Swal.fire({
                icon: 'error',
                text: err.error.message
              })
            }
            )
          }
      
  }

  approvertypevalidate(){

    if(this.ApproverPolicyForm.value.firstapprovertype != 0 && this.ApproverPolicyForm.value.secondapprovertype != 0)
    {
               if(this.ApproverPolicyForm.value.firstapprovertype == this.ApproverPolicyForm.value.secondapprovertype)
               {
                     this.approvertypesame = true;
               }else{
                     
                 this.approvertypesame = false;
               }
    }
 }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  omit_special_char(event) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }

}
