import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-project-approvers',
  templateUrl: './add-project-approvers.component.html',
  styleUrls: ['./add-project-approvers.component.scss']
})
export class AddProjectApproversComponent implements OnInit {

  projectApproverForm: FormGroup;
  projectList: any = [];
  ApproverList: any = [];
  empId :any;
  projectreadonly :boolean ;
  projectapproverobject: any = {};
  projectapproverexist: boolean;
  ruleNameexist: boolean;
  organzationId :any;
  submitted1=false;
  constructor(public dialogRef: MatDialogRef<AddProjectApproversComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private gs: GlobalserviceService , private dialog:MatDialog, ) { }

  ngOnInit(): void {

    const Data = JSON.parse(localStorage.getItem('UserData'))
    this.organzationId = Data.organization.organizationId;
    console.log("organziationid " + this.organzationId);
   
    this.projectApproverForm = this.fb.group({
      organization: {
        organizationId:  this.organzationId
      },
      project: ['', Validators.required],
      approver: ['', Validators.required],
    })
  

    console.log("create project apporver id : " + this.data.projectApproverId);

    
    this.empId = Data.employeeId
    this.gs.getProjectList().subscribe(
      (d: any[]) => {
        this.projectList = d.sort((a, b) => a.projectName.localeCompare(b.projectName))
             if(this.data.projectApproverId){

                     this.projectreadonly = true;
                           this.projectApproverForm.patchValue({
                            organization: this.data.organization,
                            project : this.data.project.projectId,
                            approver : this.data.approvers.approverId
        
                  })
             }
   
      }
    )
    this.gs.getApprovers().subscribe(
      (d: any[]) => {
        this.ApproverList = d.sort((a, b) => a.rulename.localeCompare(b.rulename))
      }
    )
}

  //control values of form values
  get f() {
    return this.projectApproverForm.controls;
  }
  Addprojectapprover()
  {
    this.submitted1=true;
        if(this.projectApproverForm.valid && this.projectapproverexist == true)
        {
          return
        }

      // console.log("form sumbitted");
        // return
    let proappform = this.projectApproverForm.value;
    console.log(proappform)

      if(this.data.projectApproverId)
      { 
        
        console.log("update form ")
        console.log(proappform)     
        var projectapproverupdate  = { 
              
          
            "projectApproverId" : this.data.projectApproverId,
            "projectApproverStatus": this.data.projectApproverStatus,
            "project": {
            "projectId": proappform.project
            },
            "approvers": {
            "approverId":  proappform.approver
            },
            "organization": {
              "organizationId": this.organzationId
            }           
            }

            console.log(projectapproverupdate);


            this.gs.updateProjectApprover(projectapproverupdate , this.data.projectApproverId).subscribe(a => {
              Swal.fire({
                icon: 'success',
                text: "Project Approver updated Successfully"
              })
              window.location.reload()
                this.dialog.closeAll()
            } ,
            err =>{
              Swal.fire({
                icon: 'error',
                text: err.error.description
              })
            }
            )
      }else
      {
            var projectapprover  = { 
              
              "project": {		
                      "projectId": proappform.project
                }
                    , "approvers": {		
                      "approverId": proappform.approver
                    }	,
                    "organization": {
                      "organizationId":  this.organzationId
                    }           
                  }	
                
                    
                
                console.log(projectapprover);
            this.gs.createProjectApprover(projectapprover).subscribe(a => {
              Swal.fire({
                icon: 'success',
                text: "Project Approver Created Successfully"
              })
              window.location.reload()
                this.dialog.closeAll()
            } ,
            err =>{
              Swal.fire({
                icon: 'error',
                text: err.error.message
              })
            }
            )
      }
      
  }

  projectapprovervalidate(){


    console.log("project : " +  this.projectApproverForm.value.project);
  
    if(!this.data.projectApproverId)
    {
        if(this.projectApproverForm.value.project != 0)
        {
           
          this.gs.getprojectApprovers(this.projectApproverForm.value.project).subscribe(a => {
              console.log(a);
              this.projectapproverobject = a;
                if(this.projectapproverobject.isexist)
                {
                    console.log("role name isexist");
                    this.projectapproverexist = true;
                }else{
                  this.projectapproverexist = false;
                }
          })
        }else{
          this.projectapproverexist = false;
        }
    }
   
   }
}
