<!-- <style>
  table{
  width: 100%;
}

.table tr:nth-child(even) {
  background-color: #dddddd;
}
.myTable{
  padding:20px;
}
</style>
<div class="float-left">
  <button mat-raised-button type="button" (click)="exportPDF()" class="action">Export as PDF</button>
</div>
<div class="float-right">
  <button mat-stroked-button mat-dialog-close type="button">
    Close
  </button>

</div>

<br><br>
<div  id="pdfTable"  #pdfTable >
  <br>
  <h4>Minutes Of Meeting</h4>
  <br>
  <div style="border:1px solid black;margin-left:15px">
    <br>
    <div style="border-bottom: 1px solid black;padding:0 15px 15px 15px">
        <div>
          <b>Meeting Date</b>: {{data.momDate}}
          <span style="float: right;">
            <b>Client & Place</b>: {{data.client}}&{{data.meetingPlace}}
          </span>
        </div>
        <div *ngIf="data.project">
          <b>Project:</b> {{data.project.projectName}}
        </div>
        <div>
          <b style=" text-decoration: underline;
          text-decoration-color: #6495ED;color:#6495ED">Attendees:</b><br>
           {{data.momAttendees}}
        </div>
    </div>
    <br>

    <div style="padding:0 15px 15px 15px">
        <table border="1" style="width: 100%;">
          <tr *ngFor="let data of myData.content">
            <td ><b>{{data.contentTitle}}</b> </td>
            <td >
              <p *ngFor="let dato of data.subContent;let i=index">{{i+1}}. {{dato.subContentTitle}}</p>
            </td>
          </tr>
        </table>
    </div><br>

    <div style="padding:0 15px 15px 15px">
      <h6><b>Action Items:</b></h6>
      <table border="1" class="table">
        <tr>
          <th style="background-color: #000;color: #FFF;">Action Item </th>
          <th style="background-color: #000;color: #FFF;">Owner</th>
          <th style="background-color: #000;color: #FFF;">Target Date</th>
        </tr>
        <tr *ngFor="let data of myData.actionItem">
          <td ><b>{{data.actionItemName}} </b></td>
          <td >{{data.actionItemOwner}}</td>
          <td>{{data.actionItemTargetDate | date}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
-->
<div class="container">
  <div class="float-left">
    <!-- <button mat-stroked-button (click)="close()" type="button">
      Close
    </button> -->
    <mat-icon class="material-icons-outlined" style="font-size: 22px;" (click)="close()">arrow_back</mat-icon>
  </div>
  <div class="float-right">
    <button mat-raised-button type="button" (click)="exportPDF(mombyIddata.momName)" class="action btn create-button">
      <mat-icon class="material-symbols-outlined" style="position:relative; top:2px;font-size:22px">file_download</mat-icon>
    </button>
  </div>
  <div class="float-right">
    <button mat-raised-button type="button" (click)="print(mombyIddata.momName)" class="action btn create-button">
      <mat-icon class="material-symbols-outlined" style="position:relative; top:2px;font-size:22px">print</mat-icon>
    </button>
  </div>
  <br><br>
  
  <div id="pdfTable"  #pdfTable >
    <div style="display:none;">
      <table class="table table-bordered" style="font-size:14px;" >
        <thead>
          <tr>
            <h3 style="text-align: center;">Minutes Of Meeting</h3>
            <hr>
          </tr>
          <tr style="border:red;">
            <td style="border:0;width:100px;"><b>Meeting Date</b></td>
            <td style="border:0">:</td>
            <td style="border:0;width:202px;">{{mombyIddata.date}}-{{mombyIddata.month}}-{{mombyIddata.year}}</td>
            <td style="border:0;width:100px;"><b>Client</b></td>
            <td style="border:0">:</td>
            <td style="border:0;width:202px;">{{mombyIddata.client}}</td>
          </tr>
          <tr *ngIf="mombyIddata.project">
            <td style="border:0"><b>Project</b></td>
            <td style="border:0">:</td>
            <td style="border:0">{{mombyIddata.project.projectName}}</td>
            <td style="border:0"><b>Place</b></td>
            <td style="border:0">:</td>
            <td style="border:0">{{mombyIddata.meetingPlace}}</td>
          </tr>
          <tr>
            <td style="border:0"><b>MOM Name</b></td>
            <td style="border:0">:</td>
            <td style="border:0" colspan="4">{{mombyIddata.momName}}</td>
          </tr>
          <tr style="margin-bottom:15px;">
            <td style="border:0"><b>Attendees</b></td>
            <td style="border:0">:</td>
            <td style="border:0" colspan="4">{{mombyIddata.momAttendees}}</td>
          </tr>
          <tr>
            <td colspan="6" style="border:0;background-color: #dddddd"><b>Points Discussed</b></td>
          </tr>
          <tr *ngFor="let data of mombyIddata.pointsDiscussed" style="margin-bottom:5px;">
            <td style="border:0;" colspan="6">
              <ul style="margin-bottom: 0px;padding-left: 20px;">
                <li>
                  {{data.pointsDiscussedDetails}}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td colspan="6" style="border:0;background-color: #dddddd"><b>Points Agreed</b></td>
          </tr>
          <tr *ngFor="let data of mombyIddata.pointsAgreed" style="margin-bottom:5px;">
            <td style="border:0" colspan="6">
              <ul style="margin-bottom: 0px;padding-left: 20px;">
                <li>
              {{data.pointsAgreedDetails}}
              </li>
              </ul>
            </td>
          </tr>
          <tr *ngIf="mombyIddata.actionItem.length !=0">
            <td colspan="6" style="border:0;background-color: #dddddd"><b>Action Items</b></td>
          </tr>
        </thead>
      </table>
      <table style="border: 1px solid #555555;" data-pdfmake="{'heights':10}" *ngIf="mombyIddata.actionItem.length !=0">
        <thead>
          <tr style="font-size:15px;">
            <th style="padding: 10.65px 10.75px;width:7%;text-align: center;">S.No</th>
            <th style="width:50%">Action Items</th>
            <th style="width:23%">Owner</th>
            <th style="width:20%;text-align: center;">Target Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let action of mombyIddata.actionItem;let i=index" style="font-size:14px;">
            <td style="padding: 10.65px 10.75px;text-align: center;">{{i+1}}</td>
            <td>{{action.actionItemName}}</td>
            <td>{{action.actionItemOwner}}</td>
            <td style="text-align: center;">{{action.actionItemTargetDate | date}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  
  <div style="font-size:14px;">
    <div style="border:1px solid black;" >
      <div style="border-bottom: 1px solid black;text-align: center;padding:10px;">
          <h5 class="text-center" style="margin: 0px;font-weight:600 ;">Minutes Of Meeting</h5>
      </div>
      <div style="border-bottom: 1px solid black;padding:10px;">
        <div class="row">
          <div class="col-md-6">
            <p class="d-flex">
              <span class="" style="display: inline-block;min-width: 100px;"><b>Meeting&nbsp;Date</b></span>
              <span class="" style="margin: 0px 10px 0px 0px;">:</span>
              <span class="">{{mombyIddata.date}}-{{mombyIddata.month}}-{{mombyIddata.year}}</span>
            </p>
          </div>
          <div class="col-md-6">
            <p class="d-flex">
              <span class="" style="display: inline-block;min-width: 100px;"><b>Client</b></span>
              <span class="" style="margin: 0px 10px 0px 0px;">:</span>
              <span class="">{{mombyIddata.client}}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" *ngIf="mombyIddata.project">
            <p class="d-flex">
              <span class="" style="display: inline-block;min-width: 100px;"><b>Project</b></span>
              <span class="" style="margin: 0px 10px 0px 0px;">:</span>
              <span class="">{{mombyIddata.project.projectName}}</span>
            </p>
          </div>
          <div class="col-md-6">
            <p class="d-flex">
              <span class="" style="display: inline-block;min-width: 100px;"><b>Place</b></span>
              <span class="" style="margin: 0px 10px 0px 0px;">:</span>
              <span class="">{{mombyIddata.meetingPlace}}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="d-flex">
              <span class="" style="display: inline-block;min-width: 100px;"><b>MOM&nbsp;Name</b></span>
              <span class="" style="margin: 0px 10px 0px 0px;">:</span>
              <span class="">{{mombyIddata.momName}}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="d-flex">
              <span class="" style="display: inline-block;min-width: 100px;"><b>Attendees</b></span>
              <span class="" style="margin: 0px 10px 0px 0px;">:</span>
              <span class="">{{mombyIddata.momAttendees}}</span>
            </p>
          </div>
        </div>
      </div>
      <div style="border-bottom: 1px solid black;padding:10.65px 10.75px ;">
          <b>Points Discussed</b>
      </div>
      <div style="border-bottom: 1px solid black;padding:10.65px 10.75px ;">
        <div *ngFor="let data of mombyIddata.pointsDiscussed">
          <ul style="margin-bottom: 5px;padding-left: 20px;">
            <li>
              {{data.pointsDiscussedDetails}}
            </li>
          </ul>
        </div>
      </div>
      <div style="border-bottom: 1px solid black;padding:10.65px 10.75px ;">
        <b>Points Agreed</b>
      </div>
      <div style="border-bottom: 1px solid black;padding:10.65px 10.75px ;">
        <div *ngFor="let data of mombyIddata.pointsAgreed">
          <ul style="margin-bottom: 5px;padding-left: 20px;">
            <li>
              {{data.pointsAgreedDetails}}
            </li>
          </ul>
        </div>
      </div>
      <div style="padding-left: 20px;padding:10.65px 10.75px ;" *ngIf="mombyIddata.actionItem.length !=0">
        <b>Action Items</b>
      </div>
      <table  class="table-responsive" border="1" style="border: 0px solid #525252;" *ngIf="mombyIddata.actionItem.length !=0">
        <thead>
          <tr>
            <th class="text-center" style="padding: 10.65px 10.75px;width:5%">S.No</th>
            <th style="padding: 10.65px 10.75px;width:70%" id="sp1">Action&nbsp;Items</th>
            <th style="padding: 10.65px 10.75px;width:16%" id="sp2">Owner&nbsp;&nbsp;</th>
            <th class="text-center" style="padding: 10.65px 10.75px;width:15%" id="sp3">Target&nbsp;&nbsp;Date&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let action of mombyIddata.actionItem;let i=index">
            <td class="text-center" style="padding: 10.65px 10.75px;width:5%">{{i+1}}</td>
            <td style="padding: 10.65px 10.75px;width:70%" id="sp1">{{action.actionItemName}}</td>
            <td style="padding: 10.65px 10.75px;width:16%" id="sp2">{{action.actionItemOwner}}</td>
            <td class="text-center" style="padding: 10.65px 10.75px;width:15%" id="sp2">{{action.actionItemTargetDate | date}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


