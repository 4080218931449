import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MatDialog } from '@angular/material';
import { ImportEmployeeComponent } from '../my-components/import-employee/import-employee.component';
import { responseMsgTimeOut} from '../../app/providers/properties';
import { ShowErrorComponent } from '../my-components/show-error/show-error.component';
@Component({
  selector: 'app-add-multiple-task',
  templateUrl: './add-multiple-task.component.html',
  styleUrls: ['./add-multiple-task.component.css']
})
export class AddMultipleTaskComponent implements OnInit {
  Taskform :FormGroup;
  control: FormArray;
  mode: boolean;
  touchedRows: any;
  projectList = [];
  taskList: any;
  min: Date;
  max: Date;
  success = false;
  error:any=false;
  sucess_msg:any;
  errormessagejson: any;
  importerror: boolean;
  startDate_disable: boolean  =true;
  endDate_disable: boolean  =true;
  minStart: Date;
  projecttaskexist: any = {};
  submitted : boolean  = false;
  organzationid :any;
  taskarray :any = [];
  istaskexist ={istaskexist  : false};
  tasknameexist  : boolean = false;
  taskgrouplist :any;
  EmpId:any
  backdrop:any= false;
  loader:any= false;
  error_msg:any;
  constructor(private fb: FormBuilder , private gs : GlobalserviceService , private datepipe  : DatePipe,   private dialog:MatDialog,) { }

  ngOnInit(): void {
    

    const Data = JSON.parse(localStorage.getItem('UserData'))
    this.EmpId = Data.employeeId;
    this.organzationid =   Data.organization.organizationId;
  
    this.gs.getProjectByOrganization(Data.organization.organizationId).subscribe((projectslist:any ) => {
              this.projectList = projectslist;
    })
    this.gs.getEmpProjectList(this.EmpId).subscribe((pa:any)=>{
      this.projectList = pa;
    });

    this.touchedRows = [];
    this.Taskform = this.fb.group({
      tableRows: this.fb.array([]) ,
      project :  ['', Validators.required],
      taskgroup : ['', Validators.required]
    });
    this.addRow();



    this.gs.gettaskgroup()
    .subscribe((data:any) =>{
       this.taskgrouplist = data;
       console.log(this.taskgrouplist);
    })
  }

  ngAfterOnInit() {
    this.control = this.Taskform.get('tableRows') as FormArray;
  }

  initiateForm(): FormGroup {
    return this.fb.group({
 
      taskname: ['', [Validators.required] ] ,
      taskdescription: ['', Validators.required],
      startdate: ['', [Validators.required]],
      enddate: ['', [Validators.required]],
      // hours: ['', [Validators.required]],
      // minutes: ['', [Validators.required]],
      isEditable: [true]
    });
  }


  //  TaskNameValidator(control : FormControl) { 
  // //   console.log("validot called")
  //    console.log("Task Name : " ,  control.value)
  //    if( this.Taskform.value.project &&  control.value)
  //    {
      
  //     let control =  this.Taskform.get('tableRows') as FormArray;
  //   control.controls.forEach(element => {
  //          console.log(element);
  //     });
         

  //     this.gs.getprojecttaskvalidate(control.value, this.Taskform.value.project)
  //     .subscribe(a => {
  //          this.projecttaskexist = a;
  //         //  console.log(this.projecttaskexist);
  //   //        console.log(this.projecttaskexist.isexist);
  //             if(this.projecttaskexist.isexist)
  //            {
  //             let istaskexist = 
  //             {istaskexist  : true}
  //             ;

  //             return istaskexist;
              
  //           }
  //         return null;   
  //   })
  //   }
  // }

  validateTaskname(event , rowindex)
  {
 
     console.log("taskname" + event.target.value);
     let taskname = event.target.value;
     let control =  this.Taskform.get('tableRows') as FormArray;
   
  }


    TaskNameValidatortest(taskname : any) { 
      //   console.log("validot called")
     //    console.log("Task Name : " ,  taskname.value)
          if( this.Taskform.value.project && taskname)
          {
            this.gs.getprojecttaskvalidate(taskname, this.Taskform.value.project)
            .subscribe(a => {
                 this.projecttaskexist = a;
                //  console.log(this.projecttaskexist);
          //        console.log(this.projecttaskexist.isexist);
                    if(this.projecttaskexist.isexist)
                   {
                    let istaskexist = 
                    {istaskexist  : true}
                    ;

                    return istaskexist;
                    
                  }
                return null;   
          })
          }
           
             
          
        }

  //   validateprojecttask(event)
  //   {
  //     console.log(event.target.value);
  // //      let obj = this.TaskNameValidatortest(event.target.value);
  //  //     console.log(obj)
  //         this.gs.getprojecttaskvalidate(event.target.value, this.Taskform.value.project)
  //         .subscribe(a => {
  //               this.projecttaskexist = a;
  //              console.log(this.projecttaskexist);
  //             console.log(this.projecttaskexist.isexist);
                
  //       })
  //       }
  // date validation
  mintaskendDate(date: any , index : any) {
  this.minStart = new Date(date.value);
  document.getElementById("enddtPicker"+index).setAttribute("min" ,date.target.value);
  this.endDate_disable = false;
}


  
  projectData(project) {
    this.gs.getTaskById(project.projectId).subscribe(
      (x: any) => {
        this.taskList = x;
      }
    )
    this.min = project.projectStartDate.substring(0,10);

    this.startDate_disable = false;
    let control =  this.Taskform.get('tableRows') as FormArray;
      control.controls.forEach(element => {
            element.patchValue({
              startdate: "",
              enddate: "",
            })
        });
  }



  addRow() {
    const control =  this.Taskform.get('tableRows') as FormArray;
    control.push(this.initiateForm());
  }

  deleteRow(index: number) {
    const control =  this.Taskform.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.Taskform.value);
  }

  get getFormControls() {
    const control = this.Taskform.get('tableRows') as FormArray;
    return control;
  }


  submitForm() {
    this.submitted = true;
     if(this.Taskform.invalid)
     {
      const invalid = [];
      const controls = this.Taskform.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
            console.log(name);
              invalid.push(name);
          }
      }
      return
     }

    
     const fromcontrol = this.Taskform.get('tableRows') as FormArray;
   
     var valueArr = fromcontrol.controls.map(function(item){ return item.value.taskname });
      var isDuplicate = valueArr.some(function(item, idx){ 
          return valueArr.indexOf(item) != idx 
      });

      console.log(isDuplicate);
     
  //    console.log("this.tasknameexist" + this.tasknameexist)
       
      if(this.Taskform.valid && isDuplicate)
      {
        Swal.fire({
          icon: 'error',
          text: "Task Name Exist in Form"
          
        })
        return
      }
       
    const control = this.Taskform.get('tableRows') as FormArray;
    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    console.log(this.touchedRows);
      
    this.touchedRows.forEach( (element) => {
      
      let startdate  =  this.datepipe.transform(element.startdate , 'YYYY-MM-dd');
      let enddate  =  this.datepipe.transform(element.enddate, 'YYYY-MM-dd');

        var taskobj = {
          
            
              taskName: this.capitalizeWordsName(element.taskname) ,
              taskStartDate:startdate,
              taskEndDate:enddate ,
              taskDescription:this.capitalizeWordsDescription(element.taskdescription),
              project: {
              projectId: this.Taskform.value.project
              },
              organization: {
              organizationId: this.organzationid
              },
              // workhours: element.hours ,
              // workminutes:element.minutes ,
              taskStatus : 31,
              taskGroup : {
                taskGroupId : this.Taskform.value.taskgroup
              }
            }
              this.taskarray.push(taskobj);
  });
      
  console.log(this.taskarray);
      
      this.gs.createMutipletask(this.taskarray).subscribe((a: any) => {
        Swal.fire({
          icon: 'success',
          text: a.message
        })
        window.location.reload()
      } , err =>{
        //alert("error")
         // console.log(err.error);
          this.taskarray.length = 0;
      //  Swal.fire({
      //    icon: 'error',
      //    text: err.error.message
         
      //  })
      //  setTimeout(() => {
      //   window.location.reload();
      //  }, 3000);
     })

  }


  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  get taskgroup()
  {
    return this.Taskform.get('taskgroup');  
  }
  get project()
  {
     return this.Taskform.get('project')
  }


  capitalizeWordsName(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
  
  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }




  importEmployee() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(ImportEmployeeComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
    // height: 'fit-content',
    // maxWidth: '90%', 
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},responseMsgTimeOut)
      }
      else 
      {
        if(result.length != 0)
       {

        this.error = true;
        // this.error_msg = result.message;
        this.importerror = true;
        this.errormessagejson = result;
        // this.showerrormessage();
        // setTimeout(() => {this.error = false}, responseMsgTimeOut)
       }
        else
        {
        if(result.statusCode > 400)
        {
          this.error = true;
        
          this.importerror = false;
        }
        }
      
      }
    });
  }


  // importEmployee() //Add Dialog Box
  // {
  //   const dialogRef = this.dialog.open(ImportEmployeeComponent, {
  //     //height: 'auto',width: '600px',
  //     width: '500px',height:'fit-content',
  //   });

  //   dialogRef.afterClosed().subscribe(result =>
  //   {
  //     //this.departmentList();
  //     if(result.statusCode == 200)
  //     {
  //       this.success = true;
	// 			this.sucess_msg = result.description;
  //       setTimeout(() => {this.success  = false;},responseMsgTimeOut)
  //     }
  //     else 
  //     {
  //       if(result.length != 0)
  //      {

  //       this.error = true;
  //       //this.error_msg = result.message;
  //       this.importerror = true;
  //       this.errormessagejson = result;
  //       // this.showerrormessage();
  //       // setTimeout(() => {this.error = false}, responseMsgTimeOut)
  //      }
  //       else
  //       {
  //       if(result.statusCode > 400)
  //       {
  //         this.error = true;
        
  //         this.importerror = false;
  //       }
  //       }
      
  //     }
  //   });
  // }

  showerrormessage()
  {
    const dialogRef = this.dialog.open(ShowErrorComponent, {
      //height: 'auto',width: '600px',
      width: '600px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }
}

