import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { EmpTimelogsComponent } from '../emp-timelogs/emp-timelogs.component';

@Component({
  selector: 'app-view-approvers',
  templateUrl: './view-approvers.component.html',
  styleUrls: ['./view-approvers.component.scss']
})
export class ViewApproversComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EmpTimelogsComponent>,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private gs: GlobalserviceService , private dialog:MatDialog) { }

  ngOnInit(): void {
      console.log('this.data',this.data);
  }

   approvalStatus(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#a68b00'
    }
    if (approvalStatusName == 'Approved') {
      return '#388e3c'
    }
    if (approvalStatusName == 'Denied') {
      return '#c62828'
    }
  }

  approvalBackground(approvalStatusName){
    if (approvalStatusName == null) {
      return
    }
    if (approvalStatusName == 'Pending') {
      return '#fff0c2'
    }
    if (approvalStatusName == 'Approved') {
      return '#c8e6c9'
    }
    if (approvalStatusName == 'Denied') {
      return '#ffcdd2'
    }
  }
}
