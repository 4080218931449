<mat-card-title>
    {{"Description"}}
  </mat-card-title>
  <mat-dialog-content class="mat-typography">
    <div class="input-width mb-2">
      <!-- <label class="form-control-label">Description<span class="text-danger">*</span></label> -->
      <textarea name="" 
        matInput id="" 
        [(ngModel)]="data.description" 
        readonly
        placeholder="Enter description"
        class="form-control email-input select-placeholder"></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button 
      [mat-dialog-close]="true" 
      cdkFocusInitial
      class="btn create-button mb-2">Ok</button>
  </mat-dialog-actions>
