import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { add } from 'timelite/time'
import { sub } from 'timelite/time'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-assign-task',
  templateUrl: './create-assign-task.component.html',
  styleUrls: ['./create-assign-task.component.css']
})
export class CreateAssignTaskComponent implements OnInit {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('mySelct') private mySelect;
  taskForm: FormGroup;
  taskassignForm:FormGroup;
  employeeList: any[];
  minStart:any;
  endDate_disable: boolean = true;
  description:any;
  min: any;
  max:any;
  taskType: any;
  arrayData: any[] = [];
  commonTaskId;
  projectName:any;
  projecttaskobject: any = {};
  projecttaskexist: boolean;
  userData:any;
  projectList:any;
  selectedProjectIndex:number = 0;
  disableTab:boolean = true;
  submitted1 = false;
  submitted2 = false;
  disableButton = true;

  minData: any;
  maxdata: any;
  employeeTask: any = []
  hourDisable: boolean
  Id: any;
  consumedHours: any;
  consumedMinutes: any;
  assignedMinutes: any;
  assignedHours: any;
  empTaskId: any;
  myTask:any = []
  employeetaskobject: any = {};
  employeetaskexist: boolean;
  taskList: any = [];
  projectapproverobject: any = {};
  projectapproverexist: boolean;
  proId:any;
  
  constructor(
    private router:Router,
    private fb: FormBuilder, 
    private gs: GlobalserviceService,
    private route: ActivatedRoute,
    private datepipe : DatePipe) { }

  ngOnInit() {
    this.selectedProjectIndex = 0;

     ////////// Create Task ///////
    this.userData = JSON.parse(localStorage.getItem("UserData"))
    this.gs.getCommonTaskName().subscribe(
      (data: any) => {
        this.taskType = data
      }
    )
    { this.CreateTaskFrom()  }
    this.gs.getProjectList().subscribe(
      (d: any[]) => {
        this.projectList = d;
        console.log(d);
      }
    )
        // this.gs.getprojectByID(this.data).subscribe(
        //   (x:any)=>{
        //     console.log(x);
        //     if(x.projectActualStartDate != 0)
        //     {
        //       this.min = x.projectActualStartDate;
        //     }else{
        //       this.min = x.projectStartDate;
        //     }
    
        //      console.log(" x.projectActualEndDate" +  x.projectActualEndDate.length)
        //     if( x.projectActualEndDate.length != 0)
        //     {
        //     //  alert("yes actual enddate")
        //       this.max = x.projectActualEndDate;
        //     }else{
        //       alert("no actual enddate")
        //       this.max = x.projectEndDate;
        //     }
           
         
        //     this.projectName = x.projectName;
        //     this.employeeList = x.employee
        //   }
        // )

   


  ////////////// assign task /////////


    { this.assignTaskFrom() }
    
    this.gs.getResource().subscribe(
      (data: any) => {
        this.employeeList = data
      }
    )

    this.route.paramMap.subscribe(
      (x: any) => {

        
        this.empTaskId = x.get('id')
        console.log("this.empTaskId"+ this.empTaskId);
        if(this.empTaskId != null)
        {
          this.endDate_disable = false;
          this.gs.getEmployeeTaskById(this.empTaskId).subscribe(
            (d: any) => {
              this.gs.getTaskById(d.task.project.projectId).subscribe(
                (x: any) => {
                  this.taskList = x;
                }
              )
              this.gs.getEmpProjectList(d.employee.employeeId).subscribe(
                (x: any) => {
                  this.projectList = x
                }
              )
              this.gs.getTask(d.task.taskId).subscribe(
                (h: any) => {
                  let hour = 0
                  let minute = 0
                  this.minData = h.taskStartDate;
                  this.maxdata = h.taskEndDate;
                  if (h.taskHours != '') {
                    hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
                      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                    })
                    minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
                      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                    })
                  }
                  else {
                    hour = 0
                    minute = 0
                  }
  
                  let data: any = 0
                  let data1: any = 0
  
                  if (h.workhours) {
                    data = Number(h.workhours) + Number(hour)
                    data1 = Number(h.workminutes) + Number(minute)
                  }
                  else {
                    data = Number(0) + Number(hour)
                    data1 = Number(0) + Number(minute)
                  }
  
                  if (data1 >= 60) {
                    data += Math.floor(data1 / 60);
                    data1 = data1 % 60;
                  }
  
                  data = ('0' + data).slice(-2)
                  data1 = ('0' + data1).slice(-2)
  
                  let x, y;
                  this.myTask = h.empolyeeTasks
                  if (h.empolyeeTasks != '') {
                    x = h.empolyeeTasks.map(a => a.assignedhours).reduce(function (a, b) {
                      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                    })
                    y = h.empolyeeTasks.map(a => a.assignedminutes).reduce(function (a, b) {
                      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
                    })
  
                    if (y >= 60) {
                      x += Math.floor(y / 60);
                      y = y % 60;
                    }
                    console.log(x,y)
                    this.consumedHours = x
                    this.consumedMinutes = y
                    this.taskassignForm.patchValue({
                      consumedhours: x,
                      consumedminutes: y
                    })
                    let _totalHM = `${data}:${data1}`
                    let _consumedHM = `${x}:${y}`
                    let _availableHM = sub([_totalHM, _consumedHM])
                    this.taskassignForm.patchValue({
                      avaliablehours: _availableHM[0],
                      avaliableminutes: _availableHM[1],
                    })
                  }
                  else {
                    this.taskassignForm.patchValue({
                      consumedhours: 0,
                      consumedminutes: 0,
                    })
                    this.taskassignForm.patchValue({
                      avaliablehours: data,
                      avaliableminutes: data1,
                    })
                  }
  
                  this.hourDisable = false
                  this.taskassignForm.patchValue({
                    totalhours: data,
                    totalminutes: data1,
                  })
                }
              )
  
              this.taskassignForm.patchValue({
                project: d.task.project.projectId,
                task1: d.task.taskId,
                avaliablehours: d.avaliablehours,
                avaliableminutes: d.avaliableminutes,
                employee: d.employee.employeeId,
                assignedhours: d.assignedhours,
                assignedminutes: d.assignedminutes,
                // consumedhours: d.consumedhours,
                // consumedminutes: d.consumedminutes,
                startdate: d.startdate,
                enddate: d.enddate,
              })
              this.assignedHours = d.assignedhours
              this.assignedMinutes = d.assignedminutes
            }
          )
        }
        
      }
    )
  }











  ////////// Create Task ///////
  private CreateTaskFrom() {

    this.taskForm = this.fb.group({
      organization: {
        organizationId: []
      },
      projects: ['', Validators.required],
      // task: ['', Validators.required],
      taskName: ['', Validators.required],
      employee: ['', Validators.required],
      taskDescription: ['', Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      taskStartDate: ['', Validators.required],
      taskEndDate: ['', Validators.required],
    })
  }
  tosslePerOne(all){
    if (this.allSelected.selected) {
     this.allSelected.deselect();
     return false;
 }
   if(this.taskForm.controls.employee.value.length==this.employeeList.length)
     this.allSelected.select();
 }
   toggleAllSelection() {
     if (this.allSelected.selected) {
       this.taskForm.controls.employee
         .patchValue([...this.employeeList.map(item => item.employeeId), 0]);
     } else {
       this.taskForm.controls.employee.patchValue([]);
     }
   }

  add(){
    let x = this.taskForm.value;
    if(x.employee == ''){
      alert("Please select the options")
    }
    else{
      this.mySelect.close()
    }
  }

  cancel(){
    this.taskForm.patchValue({employee:''})
    this.mySelect.close()
  }
  // keys

  // omit_special_char(event) {
  //   let k;
  //   let n;
  //   n = event.keyCode;
  //   k = event.charCode;
  //   return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  // }
  // keyPress(event: any) {
  //   const pattern = /^[a-zA-Z ]*$/;
  //   const inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode !== 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }

  changeProjectTab(e) {
    this.submitted1 = true;
    const controls = this.taskForm.controls;
    console.log( "projects  " + this.taskForm.value.projects);

    for (const name in controls) {
      if (controls[name].invalid) {
          console.log(name)
      }
  }

  console.log("start date"+this.taskForm.controls.taskStartDate.value);
  console.log("end date"+this.taskForm.controls.taskEndDate.value)

    if(this.taskForm.invalid){
      this.disableTab = true
      return
    }

    if(this.taskForm.controls.hours.value == 0 &&  this.taskForm.controls.minutes.value == 0){
   //   this.disableTab = true
      alert('Task Hours Should be greater than 0')
      return
    }

    this.disableTab = false
    this.selectedProjectIndex = e
     if(this.taskForm.controls.hours.value  && this.taskForm.controls.minutes.value)
     {
      this.taskassignForm.patchValue({
        totalhours: this.taskForm.controls.hours.value,
        totalminutes:this.taskForm.controls.minutes.value,
        consumedhours : "0",
        consumedminutes : "0",
        avaliablehours: this.taskForm.controls.hours.value,
        avaliableminutes: this.taskForm.controls.minutes.value,
      })

          if(this.taskForm.controls.taskStartDate  &&  this.taskForm.controls.taskEndDate)
          {
         //   console.log(this.taskForm.controls.taskStartDate.value);
         //   console.log(this.taskForm.controls.taskEndDate.value);
                this.minData = this.taskForm.controls.taskStartDate.value;
                 this.maxdata = this.taskForm.controls.taskEndDate.value;
               //  console.log("minData" +this.minData);
               //  console.log("maxdata" +this.maxdata);
          }
     }
  }
  get f() {
    return this.taskForm.controls;
  }
  // date validation
  someMethodName(date: any) {
    this.minStart = new Date(date.value)
    this.endDate_disable = false
  }
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
  change(id,t){
    this.description = t;
    this.commonTaskId = id;
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // addTask(){
  //   this.dialogRef.close()
  //   this.router.navigate(['./add-task'])
  // }
  Create_task() {
    this.submitted2 = true;

    let x = this.taskForm.value;

    if (this.taskForm.invalid) {
      return
    }
    if(this.taskassignForm.invalid)
    {
       return
    }


     if(this.taskForm.valid && this.projecttaskexist == true  )
     {
     //  console.log("has error")
       return    
     }

     if( this.taskassignForm.value.assignedhours == 0 &&  this.taskassignForm.value.assignedminutes == 0){
      alert("Assigned Time Should be greater than 0")
      return
    }

    //end date
    let a = x.taskStartDate;
    let i = new Date(a);
    i.setMinutes(i.getMinutes() + 480);
    let j = i.toISOString()
    x['taskStartDate'] = j

    //end date
    let b = x.taskEndDate;
    let y = new Date(b);
    y.setMinutes(y.getMinutes() + 480);
    let t = y.toISOString()
    x['taskEndDate'] = t

    x['taskStatus'] = 31;
    // x['workingHours'] = `${x.hours}:${x.minutes}:00`
    if(x.hours == 0 && x.minutes == 0){
      alert('Task Hours Should be greater than 0')
      return
    }


    x['workhours'] = x.hours
    x['workminutes'] = x.minutes
    x['organization'] ={
      organizationId:this.userData.organization.organizationId
    }
    // x['project'] = {
    
    // }
    // x['commonTasks'] = {
    //   commontaskId:this.commonTaskId
    // }


   // console.log("start date" +  this.datepipe.transform(this.taskassignForm.value.startdate , 'YYYY-MM-dd'))
   // console.log("end  date" +  this.datepipe.transform(this.taskassignForm.value.enddate , 'YYYY-MM-dd'))

     let startdate  =  this.datepipe.transform(this.taskassignForm.value.startdate , 'YYYY-MM-dd');
     let enddate  =  this.datepipe.transform(this.taskassignForm.value.enddate , 'YYYY-MM-dd');

     this.taskassignForm.value.enddate.toISOString().substring(0,10)

        var taskobj = {
          
            "organization":{
               "organizationId":this.userData.organization.organizationId
            },
            "project":{
               "projectId":this.taskForm.value.projects
            },
            "employee": {
              "employeeId": this.taskForm.value.employee
            },
            "taskName": this.taskForm.value.taskName,
            "taskDescription":this.taskForm.value.taskDescription,
            "workhours": this.taskForm.value.hours,
            "workminutes": this.taskForm.value.minutes,
            "taskStartDate": this.taskForm.value.taskStartDate.substring(0,10),
            "taskEndDate":this.taskForm.value.taskEndDate.substring(0,10),
             "taskStatus" : 31,
               "avaliablehours": this.taskassignForm.value.avaliablehours,
               "avaliableminutes":this.taskassignForm.value.avaliableminutes,
               "startdate":startdate,
               "enddate":enddate,
              // "employee": {
              //    "employeeId": this.taskassignForm.value.employee
              //  },
              "assignedhours":  this.taskassignForm.value.assignedhours,
              "assignedminutes":this.taskassignForm.value.assignedminutes,
              "consumedhours":this.taskassignForm.value.consumedhours,
              "consumedminutes": this.taskassignForm.value.consumedminutes
             
             
        }

     console.log(JSON.stringify(taskobj))
    //return
    this.gs.createtaskemployee(taskobj).subscribe((a: any) => {
      Swal.fire({
        icon: 'success',
        text: a.message
      })
      this.disableButton =false;
      window.location.reload()
    },
    (err)=> 
    {
      Swal.fire({
        icon: 'error',
        text: a.message
      })
      this.disableButton =false;
    } 
    )
  }
  validateprojecttask()
  {
   console.log("employee : " +  this.taskForm.value.taskName);
       if(this.taskForm.value.taskName.length != 0 )
       {          
        //  this.gs.getprojecttaskvalidate(this.taskForm.value.taskName ,this.proId).subscribe(a => {
          this.gs.gettaskvalidate(this.taskForm.value.taskName).subscribe(a => {
             console.log(a);
             this.projecttaskobject = a;
               if(this.projecttaskobject.isexist)
               {
                   console.log("role name isexist");
                   this.projecttaskexist = true;
               }else{
                 this.projecttaskexist = false;
               }
         })
       }else{
         this.projecttaskexist = false;
       }   
  }
  projectapprovervalidate(){
  
    console.log("project : " +  this.taskForm.value.projects);
    this.proId = this.taskForm.value.projects;
    if(!this.proId.projectApproverId)
    {
        if(this.taskForm.value.project != 0)
        {
           
          this.gs.getprojectApprovers(this.taskForm.value.project).subscribe(a => {
              console.log(a);
              this.projectapproverobject = a;
                if(this.projectapproverobject.isexist)
                {
                    console.log("role name isexist");
                    this.projectapproverexist = true;
                }else{
                  this.projectapproverexist = false;
                }
          })
        }else{
          this.projectapproverexist = false;
        }
    }
   
}
  /* validation ++++++++++++++++ */
  get projects()
  {
    return this.taskForm.get('projects');  
  }
  get employee()
  {
    return this.taskForm.get('employee');  
  }
    get task()
  {
    return this.taskForm.get('task');  
  }
  get taskName()
  {
    return this.taskForm.get('taskName');  
  }
  get taskDescription()
  {
    return this.taskForm.get('taskDescription');  
  }
  get hours()
  {
    return this.taskForm.get('hours');  
  }
  get minutes()
  {
    return this.taskForm.get('minutes');  
  }
  get taskStartDate()
  {
    return this.taskForm.get('taskStartDate');  
  }
  get taskEndDate()
  {
    return this.taskForm.get('taskEndDate');  
  }
  



















/////// assign task /////////

empData(emp) {
  this.gs.getEmpProjectList(emp.employeeId).subscribe(
    (x: any) => {
      this.projectList = x
    } , err =>{
    
      this.projectList.length = 0;
      this.taskForm.patchValue({
        projects: null
      
      });
   //   console.log("this.taskForm.value.projects" +this.taskForm.value.projects);
   }
  )
}

private assignTaskFrom() {

  this.taskassignForm = this.fb.group({
    avaliablehours: ['', Validators.required],
    avaliableminutes: ['', Validators.required],
    consumedhours: ['', Validators.required],
    consumedminutes: ['', Validators.required],
    totalhours: ['', Validators.required],
    totalminutes: ['', Validators.required],
    // employee: ['', Validators.required],
    assignedhours: ['', Validators.required],
    assignedminutes: ['', Validators.required],
    startdate: ['', Validators.required],
    enddate: ['', Validators.required],
  })
}
get g(){
  return this.taskassignForm.controls;
}
projectData(project) {
  this.gs.getTaskById(project.projectId).subscribe(
    (x: any) => {
      this.taskList = x;
    }
  )
  this.min = new Date(project.projectStartDate)
  this.max = new Date(project.projectEndDate)

  this.taskForm.controls.taskStartDate.setValue("");
  this.taskForm.controls.taskEndDate.setValue("");
}

taskData(h) {
  this.minData = h.taskStartDate;
  this.maxdata = h.taskEndDate;
  let hour = 0
  let minute = 0
  if (h.taskHours != '') {
    hour = h.taskHours.map(a => a.extrahours).reduce(function (a, b) {
      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
    })
    minute = h.taskHours.map(a => a.extraminutes).reduce(function (a, b) {
      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
    })
  }
  else {
    hour = 0
    minute = 0
  }

  let data: any = 0
  let data1: any = 0

  if (h.workhours) {
    data = Number(h.workhours) + Number(hour)
    data1 = Number(h.workminutes) + Number(minute)
  }
  else {
    data = Number(0) + Number(hour)
    data1 = Number(0) + Number(minute)
  }

  if (data1 >= 60) {
    data += Math.floor(data1 / 60);
    data1 = data1 % 60;
  }

  data = ('0' + data).slice(-2)
  data1 = ('0' + data1).slice(-2)

  let x, y;
  if (h.empolyeeTasks != '') {
    x = h.empolyeeTasks.map(a => a.assignedhours).reduce(function (a, b) {
      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
    })
    y = h.empolyeeTasks.map(a => a.assignedminutes).reduce(function (a, b) {
      return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
    })

    if (y >= 60) {
      x += Math.floor(y / 60);
      y = y % 60;
    }
    this.consumedHours = x
    this.consumedMinutes = y
    this.taskassignForm.patchValue({
      consumedhours: x,
      consumedminutes: y
    })
    let _totalHM = `${data}:${data1}`
    let _consumedHM = `${x}:${y}`
    let _availableHM = sub([_totalHM, _consumedHM])
    this.taskassignForm.patchValue({
      avaliablehours: _availableHM[0],
      avaliableminutes: _availableHM[1],
    })
  }
  else {
    this.taskassignForm.patchValue({
      consumedhours: 0,
      consumedminutes: 0,
    })
    this.taskassignForm.patchValue({
      avaliablehours: data,
      avaliableminutes: data1,
    })
  }

  this.hourDisable = false
  this.taskassignForm.patchValue({
    totalhours: data,
    totalminutes: data1,
    assignedhours:'',
    assignedminutes:''
  })
}

set(e) {
  let x = this.taskassignForm.value;
  if(this.empTaskId){
    let c = 0
    let d = 0
    let arr = new Array()
    this.myTask.map(
      e=>{
        if(e.emptaskId != this.empTaskId){
          arr.push(e)
          c = arr.map(a => a.assignedhours).reduce(function (a, b) {
            return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
          })
          d = arr.map(a => a.assignedminutes).reduce(function (a, b) {
            return Number(('0' + a).slice(-2)) + Number(('0' + b).slice(-2))
          })
        }
      }
    )
    if (d >= 60) {
      c += Math.floor(d / 60);
      d = d % 60;
    }
    if (x.assignedhours != '' && x.assignedminutes != '') {
      let newH:any = `${('0' + x.assignedhours).slice(-2)}:${('0' + x.assignedminutes).slice(-2)}`
      let oldH:any = `${('0' + c).slice(-2)}:${('0' + d).slice(-2)}`
      let total:any = add([newH,oldH])
      var startTimeObject =`${total[0]}:${total[1]}`
      let x1 =('0' + total[0]).slice(-2)
      let x2 =('0' + total[1]).slice(-2)
      startTimeObject = `${x1}:${x2}`

      this.taskassignForm.patchValue({
        consumedhours:total[0],
        consumedminutes:total[1]
      })

      //Create date object and set the time to that
      var endTimeObject =`${x.totalhours}:${x.totalminutes}`
      let y1 =('0' + x.totalhours).slice(-2)
      let y2 =('0' + x.totalminutes).slice(-2)
      endTimeObject = `${y1}:${y2}`

      if(startTimeObject > endTimeObject){
        alert(`Consumed Time ${x.consumedhours}:${x.consumedminutes} Should be less than Total Time ${x.totalhours}:${x.totalminutes}`)
        return
      }
      else{
        let d: any = `${('0' + total[0]).slice(-2)}:${('0' + total[1]).slice(-2)}`
        let e: any = `${('0' + x.totalhours).slice(-2)}:${('0' + x.totalminutes).slice(-2)}`
        let data:any = 0;
        data = sub([e,d])
        this.taskassignForm.patchValue({
          avaliablehours: data[0],
          avaliableminutes: data[1]
        })
      }
    }
  }
  else{
  let CM: any = 0;
  let CH: any = 0;
  let a: any
  let b: any
  if (x.assignedhours != '' && x.assignedminutes != '') {
    a = `${('0' + x.assignedhours).slice(-2)}:${('0' + x.assignedminutes).slice(-2)}`
    b = `${('0' + x.avaliablehours).slice(-2)}:${('0' + x.avaliableminutes).slice(-2)}`
    CM = sub([b, a])
  }


  if (a > b) {
    alert(`Assigned Time ${x.assignedhours}:${x.assignedminutes} should be less than Available Time ${x.avaliablehours}:${x.avaliableminutes}`)
    return
  }
  else{
    console.log(x)
    if (x.assignedhours != '' && x.assignedminutes != '') {
      let c: any = `${('0' + this.consumedHours).slice(-2)}:${('0' + this.consumedMinutes).slice(-2)}`
      CH = add([a, c])
      console.log(a,c)
      this.taskassignForm.patchValue({
        consumedhours: CH[0],
        consumedminutes: CH[1]
      })
      let d: any = `${('0' + CH[0]).slice(-2)}:${('0' + CH[1]).slice(-2)}`
      let e: any = `${('0' + x.totalhours).slice(-2)}:${('0' + x.totalminutes).slice(-2)}`
      let data:any = 0;
      data = sub([e,d])
      this.taskassignForm.patchValue({
        avaliablehours: data[0],
        avaliableminutes: data[1]
      })
    }

  }
  }
}

// keys

omit_special_char(event) {
  let k;
  let n;
  n = event.keyCode;
  k = event.charCode;
  return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
}
keyPress(event: any) {
  const pattern = /^[a-zA-Z ]*$/;
  const inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode !== 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}
assignnoNumber(event: any) {
  const pattern = /[0-9]|\./;
  const inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode !== 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}

// date validation
assignsomeMethodName(date: any) {
  this.minStart = new Date(date.value)
  this.endDate_disable = false
}

// cancel() {
//   this.router.navigate(['./emp-timesheet'])
// }

Assign_task() {
  if (this.taskassignForm.invalid) {
    return
  }
   if(this.taskassignForm.valid && this.employeetaskexist == true)
   {
      return
   }
    
   // console.log("form sumbitted");
    
  let x = this.taskassignForm.value
  if(x.startdate){
      //start date
      let a = x.startdate;
      let i = new Date(a);
      i.setMinutes(i.getMinutes() + 480);
      let j = i.toISOString()
      x['startdate'] = j
  }
  if(x.enddate){
    //end date
    let b = x.enddate;
    let y = new Date(b);
    y.setMinutes(y.getMinutes() + 480);
    let t = y.toISOString()
    x['enddate'] = t
  }


  if(this.empTaskId){
      let x1 =('0' + x.consumedhours).slice(-2)
      let x2 =('0' + x.consumedminutes).slice(-2)
      var startTimeObject = `${x1}:${x2}`

      let y1 =('0' + x.totalhours).slice(-2)
      let y2 =('0' + x.totalminutes).slice(-2)
      var endTimeObject = `${y1}:${y2}`
      console.log(startTimeObject,endTimeObject)

      if(startTimeObject > endTimeObject){
        alert(`Consumed Time ${x.consumedhours}:${x.consumedminutes} Should be less than Total Time ${x.totalhours}:${x.totalminutes}`)
        window.location.reload()
        return
      }
  }
  else{
  let CM: any = 0;
  let CH: any = 0;
  let a: any = `${('0' + x.assignedhours).slice(-2)}:${('0' + x.assignedminutes).slice(-2)}`
  let b: any = `${('0' + x.avaliablehours).slice(-2)}:${('0' + x.avaliableminutes).slice(-2)}`
  CM = sub([b, a])
  // if (a > b) {
  //   alert(`Assigned Time ${x.assignedhours}:${x.assignedminutes} should be less than Available Time ${x.avaliablehours}:${x.avaliableminutes}`)
  //   return
  // }
  // else{
    if (x.assignedhours != '' && x.assignedminutes != '') {
      let c: any = `${('0' + this.consumedHours).slice(-2)}:${('0' + this.consumedMinutes).slice(-2)}`
      CH = add([a, c])
      this.taskassignForm.patchValue({
        consumedhours: CH[0],
        consumedminutes: CH[1]
      })
      let d: any = `${('0' + CH[0]).slice(-2)}:${('0' + CH[1]).slice(-2)}`
      let e: any = `${('0' + x.totalhours).slice(-2)}:${('0' + x.totalminutes).slice(-2)}`
      let data:any = 0;
      data = sub([e,d])
      this.taskassignForm.patchValue({
        avaliablehours: data[0],
        avaliableminutes: data[1]
      })
      if(d > e){
        alert("You have assigned maximum number of hours")
        this.taskassignForm.patchValue({
          avaliablehours: 0,
          avaliableminutes: 0
        })
        window.location.reload()
        return
      }
    }

  // }
  }

  var arrayData = new Array()
  console.log(x)

  let data = {
    task: {
      taskId: x.task
    },
    avaliablehours: x.avaliablehours,
    avaliableminutes: x.avaliableminutes,
    startdate: x.startdate,
    enddate: x.enddate,
    employee: {
      employeeId: x.employee
    },
    assignedhours: x.assignedhours,
    assignedminutes: x.assignedminutes,
    consumedhours: x.consumedhours,
    consumedminutes: x.consumedminutes
  }
  arrayData.push(data)

  // console.log(JSON.stringify(arrayData))
  // return

  if(x.assignedhours == 0 && x.assignedminutes == 0){
    alert("Assigned Time Should be greater than 0")
    return
  }

  if (this.empTaskId) {
    data['emptaskId'] = this.empTaskId
    // console.log(JSON.stringify(data))
    // return
    this.gs.updateEmployeeTask(this.empTaskId, data).subscribe(
      (x: any) => {
        Swal.fire({
          text: x.message,
          icon: 'success'
        })
        this.router.navigate(['./emp-timesheet'])
      }
    )
  }
  else {
    this.gs.assignEmployee(arrayData).subscribe((a: any) => {
      Swal.fire({
        icon: 'success',
        text: a.message
      })
      this.router.navigate(['./emp-timesheet'])
    })
  }

}

// empolyeeTaskvalidate(){
//   console.log("employee : " +  this.taskassignForm.value.employee);
//   if(!this.empTaskId)
//   {
//       if(this.taskassignForm.value.employee != 0 && this.taskassignForm.value.task != 0)
//       {
         
//         this.gs.getEmployeetaskvalidate(this.taskassignForm.value.employee , this.taskassignForm.value.task).subscribe(a => {
//             console.log(a);
//             this.employeetaskobject = a;
//               if(this.employeetaskobject.isexist)
//               {
//                   console.log("role name isexist");
//                   this.employeetaskexist = true;
//               }else{
//                 this.employeetaskexist = false;
//               }
//         })
//       }else{
//         this.employeetaskexist = false;
//       }
//   }
//  }


// get project()
// {
//   return this.taskassignForm.get('project');  
// }
// get employee()
// {
//   return this.taskassignForm.get('employee');  
// }
get task1()
{
  return this.taskassignForm.get('task1');  
}
get avaliablehours()
{
  return this.taskassignForm.get('avaliablehours');  
}
get avaliableminutes()
{
  return this.taskassignForm.get('avaliableminutes');  
}
get consumedhours()
{
  return this.taskassignForm.get('consumedhours');  
}
get consumedminutes()
{
  return this.taskassignForm.get('consumedminutes');  
}
get assignedhours()
{
  return this.taskassignForm.get('assignedhours');  
}
get assignedminutes()
{
  return this.taskassignForm.get('assignedminutes');  
}
get startdate()
{
  return this.taskassignForm.get('startdate');  
}
get enddate()
{
  return this.taskassignForm.get('enddate');  
}
    
}
   
