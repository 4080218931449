<div class="main-content">
    <div class="container-fluid">
      <div class="top-content row">
        <div class="col-md-5 col-sm-5" *ngIf=" user_type != 'HumanResource' &&  user_type != 'Manager'">
          <h4>Assign Approvers</h4>
          <span class="sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">Assign Approvers</span>
        </div>
        <div class="col-md-5 col-sm-5"*ngIf=" user_type == 'HumanResource' ||  user_type == 'Manager'">
          <h4>Assign Approvers</h4>
          <span class="sub-cont" [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Assign Approvers</span>
        </div>
        <!-- <div>
          <mat-form-field class="example-width">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="search" placeholder="Search..." (keyup)="changepage()"/>
            <mat-icon matSuffix >search</mat-icon>
          </mat-form-field> -->
          <div class="col-md-5 col-sm-5">
            <ul class="unstyled inbox-pagination">
              <div class="sidebar-search">
                <div class="input-group">
                  <input type="text" class="form-control search-menu" placeholder="Search..." [(ngModel)]="search"  (keyup)="changepage()" name="searchText">
                  <div class="material-icons-outlined search-icon">search</div>
                </div>
              </div>
            </ul>
          </div>
          <div class="col-md-2 col-sm-2">
            <button type="button" mat-raised-button class="btn"  (click)="viewProject('e')"
            *ngIf="userType == 'HumanResource' || userType == 'Manager'">
              <!-- <span class="material-icons">add</span> -->
              <span>Assign Approver</span>
            </button>
          </div>
        <!-- </div> -->
      </div>
  
      <div class="search-content">
        <mat-card class="card-content"  *ngIf="approverList.length > 0 ">
          <div class="card-body table-responsive">
             <!-- <ng-container *ngIf="approverList.length > 0;else elseProject"> -->
              <div class="row p-0 m-0" *ngIf="approverList.length > 5 ">
                <div class="col-xl-12 col-lg-12">
                    <div class="select-pagination">
                        <span>Show : </span>
                        <select class="select_list new-select_list" [(ngModel)]="mySelect">
                            <option class="form-control" value="5"> 5</option>
                            <option class="form-control" value="10">10</option>
                            <option class="form-control" value="15">15</option>
                            <option class="form-control" value="20">20</option>
                            <option class="form-control" value="25">25</option>
                        </select>
                    </div>
                </div>  
              </div>
              <table class="table" matSort (matSortChange)="sortData($event)" >
                <thead>
                  <tr>
                    <th style="text-align: center;">Sl.No</th>
                    <!-- <th>Project&nbsp;&nbsp;Code</th>
                    <th>Project&nbsp;&nbsp;Name</th> -->
                    <th  mat-sort-header="rulename"  >Approver&nbsp;&nbsp;Rule&nbsp;&nbsp;Name</th>
                    <th  mat-sort-header="projectName" >Project&nbsp;&nbsp;Name</th>
                    <!-- <th>Start&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th>End&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th> -->                   
                    <th style="text-align: center;" *ngIf="userType == 'HumanResource' || userType == 'Manager'">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let approver of approverList | projectapprover:search |  paginate:{itemsPerPage:mySelect,currentPage:p};let i=index">
                    <!-- <tr> -->
                    <td style="text-align: center;"  *ngIf="p">{{((p-1)*mySelect)+(i+1)}}</td>
                    <td style="text-align: center;" *ngIf="!p">{{i+1}}</td>
                    <!-- <td>{{approver.project.projectCode}}</td>
                    <td>{{approver.project.projectName}}</td> -->
                    <td >{{approver.approvers.rulename}}</td>
                    <td  >{{approver.project.projectName}}</td>
                    <!-- <td>{{approver.project.projectStartDate | date}}</td>
                    <td>{{approver.project.projectEndDate | date}}</td> -->                       
                    <td style="text-align: center;" class="more"  *ngIf="userType == 'HumanResource' || userType == 'Manager'">
                       <span class="material-icons icons" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        more_vert
                      </span> 
  
                      <mat-menu #menu="matMenu">
                         <!-- <button mat-menu-item *ngIf="user_type != 'Employee'" (click)="viewEmployee(approver)">
                          <mat-icon>streetview</mat-icon>
                          <span>Assign Employees</span>
                        </button> -->
                        <button mat-menu-item *ngIf="user_type != 'Employee'"
                              (click) = "editprojectapprover(approver)">
                          <mat-icon class="edit-icon">create</mat-icon>
                          <span class="edit-form">Edit</span>
                        </button>
                         <!-- <button mat-menu-item *ngIf="user_type != 'Employee'">
                          <mat-icon>delete_forever</mat-icon>
                          <span>Delete</span>
                        </button>  -->
                      </mat-menu> 
  
                    </td>
                  </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event ; beforesearchpage = $event"
            *ngIf="approverList.length > 10" align="end"></pagination-controls>
             <!-- </ng-container> -->
            </div>
          </mat-card>
          <mat-card class="card-content" *ngIf="nodata">
            <div class="card-body">
              <!-- <ng-template #elseProject> -->
                <div class="alert alert-primary">
                  <h4 align="center">Approvers Not Found</h4>
                </div>
              <!-- </ng-template> -->
            </div>
          </mat-card>
      </div>
    </div>
  </div>
  <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>