import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { url } from './.././../../providers/properties'
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private Router: Router) { }

  login(data) {
    return this.http.post<any>(`${url}user_validation`,
      data).pipe(map(u => {
        if (u !== undefined && u != null) {
          var testData: any = u;
          localStorage.setItem("UserData", JSON.stringify(testData))
          var role:any
          Swal.fire({
            icon: 'success',
            text: testData.description
          })
        };
      }));
  }
  isLoggedIn() {
    if (localStorage.getItem('UserData')) {
      this.loggedIn.next(true)
    }
  }

  logout() {
      this.loggedIn.next(false)
      localStorage.clear();
  }

}
