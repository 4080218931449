import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { UpdateActionComponent } from '../action-item/update-action/update-action.component';
import { CreateMomComponent } from '../create-mom/create-mom.component';
import { GlobalserviceService } from '../providers/globalservice.service';
import { UpdateMomComponent } from './update-mom/update-mom.component';
import { PreviewFileComponent } from './preview-file/preview-file.component';

@Component({
  selector: 'app-mom-report',
  templateUrl: './mom-report.component.html',
  styleUrls: ['./mom-report.component.css']
})
export class MomReportComponent implements OnInit {

  momList:any = []
  mombyproject:any=[];
  projectId:number;
  projectList:any = []
  projectStatus:any = "0" ;
  startDate:any;
  mySelect:any =5;
  endDate:any;
  // p:any;
  p:number =1;
  User_Type: any;
  noData=false;
  // nodata: boolean = false;
  beforesearchpage: any;
   search: any;
   errorMsg=false;
   today:any;

  constructor(private gs:GlobalserviceService,
              public dialog:MatDialog,
              private router:Router,
              private route:ActivatedRoute,
              private datepipe : DatePipe,) { }

  ngOnInit(): void {
    this.noData=false;
    this.today =new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
   // const myData = JSON.parse(localStorage.getItem('MOMdata'))
    const myData = JSON.parse(localStorage.getItem('UserData'))
    this.gs.getResourceById(myData.employeeId).subscribe(
      (x:any)=>{
        this.User_Type = x.userType.userType;
        console.log(this.User_Type)
      }
    )
    // this.gs.get_projectsByUserId(myData.employeeId).subscribe(
    //   (x:any)=>{
    //     this.projectList = x
    //   }
    // )
    this.gs.getEmpProjectList(myData.employeeId)
    .subscribe((pa:any)=>{
      this.projectList = pa;
    });
  }

  searchTask(){
    this.startDate = this.datepipe.transform( this.startDate , 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform( this.endDate , 'YYYY-MM-dd');

    if(this.projectStatus == "0" || this.startDate == undefined || this.endDate == undefined){
      alert("Please select the required Items")
      return
    }

    // if(this.startDate > this.endDate){
    //   alert('Start date should not be greater than End date')
    //   return
    // }
    this.momList = [];
    if(this.startDate <= this.endDate) {
    this.gs.get_momByProject(this.startDate,this.endDate,this.projectStatus).subscribe(
      (data:any)=>{
        this.noData=false;
        this.momList = data;
      }
      ,(error)=>
        {
          this.noData=true;
          console.log('No Data');
        }
    );
      }
      else
      {
        console.log("StartDate is Less Then End date");
        this.errorMsg=true;
      }
  }

  dateChange(event) {
    console.log(event.value);
    // this.enddate_required = false;
    // this.startdate_required = false;
    this.errorMsg=false;
  }

  createMOM(){
    this.router.navigate(['./create-MOM'])
    // const dialogRef = this.dialog.open(CreateMOMComponent,{
    //   data:'',
    //   height:'600px',
    //   width:'800px',
    //   disableClose:true
    // })
  }
  create(){
    const dialogRef = this.dialog.open(CreateMomComponent,{
      data:'',
      height:'600px',
      width:'800px',
      disableClose:true
    })
  }

  edit(e){
    // this.router.navigate(['./update-mom',e]);
    const dialogRef = this.dialog.open(UpdateMomComponent,{
      data:e,
      height:'600px',
      width:'850px',
      disableClose:true
    })
  }


  pro(){
    this.router.navigate(['../project'])
  }

  preview(e){
    this.router.navigate(['./view-mom',e])
    // const dialogRef = this.dialog.open(PreviewFileComponent,{
    //   data:e,
    //   width:'900px',
    //   height:'600px'
    // })
  }

  sortData(sort: Sort) {
    const data = this.momList.slice();
    if (!sort.active || sort.direction === '') {
      this.momList  = data;
      return;
    }

    this.momList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'momName':
          return this.compare(a.momName, b.momName, isAsc);
        case 'momDate':
          return this.compare(a.momDate, b.momDate, isAsc);
        case 'client':
          return this.compare(a.client, b.client, isAsc);
        // case 'projectName':
        //   return this.compare(a.projectName, b.projectName, isAsc);
        // case 'projectName':
        //   return this.compare(a.mom.project.projectName, b.mom.project.projectName, isAsc);
        //   case 'actionItemStatus':
        //     return this.compare(a.actionItemStatus, b.actionItemStatus, isAsc);
        default:
          return 0;
      }
    });
  }
  
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  changepage(){
    console.log("Search");
    console.log(this.momList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.momList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
       console.log(this.momList.length);
       console.log("nodata");
    }
  }

  showChanges()
    {
      console.log("show Change");
      this.p=1;
    }
    

  // attendees(d){
  //   console.log(d)
  //   const dialogRef = this.dialog.open(Describe,{
  //     data:d,
  //     width:'500px',
  //     disableClose:true
  //   })
  // }
}

@Component({
  selector: 'Describe',
  templateUrl: 'Describe.html',
})
export class Describe implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<Describe>,
    @Inject(MAT_DIALOG_DATA) public data: any){}

  ngOnInit(){
    console.log(this.data)
  }
}
