import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Sort } from '@angular/material/sort';
import { GlobalserviceService } from '../providers/globalservice.service';
import { UpdateActionComponent } from './update-action/update-action.component';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.css']
})
export class ActionItemComponent implements OnInit {


  projectList:any = []
  actionItemList:any = []
  projectStatus:any = "0";
  actionStatus:any = "0";
  mySelect:any =5;
  startDate:any;
  endDate:any;
  // p:any
  p:number =1;
  User_Type: any;
  noData=false;
  beforesearchpage: any;
  search: any;
  errorMsg=false;
  today:any;
  
  constructor(private gs:GlobalserviceService,
              private dialog:MatDialog,
              private datepipe : DatePipe,) { }

  ngOnInit(): void {
      this.noData=false;
      this.today =new Date();
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    //const myData = JSON.parse(localStorage.getItem('MOMdata'))
    const myData = JSON.parse(localStorage.getItem('UserData'));
    this.gs.getResourceById(myData.employeeId).subscribe(
      (x:any)=>{
        this.User_Type = x.userType.userType;
        console.log(this.User_Type)
      }
    )
    // this.gs.get_projectsByUserId(myData.employeeId).subscribe(
    //   e=>{
    //     this.projectList = e;
    //   }
    // )
    this.gs.getEmpProjectList(myData.employeeId)
    .subscribe((pa:any)=>{
      this.projectList = pa;
    });
  }

  dateChange(event) {
    console.log(event.value);
    // this.enddate_required = false;
    // this.startdate_required = false;
    this.errorMsg=false;
  }

  searchTask(){
    this.startDate = this.datepipe.transform( this.startDate , 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform( this.endDate , 'YYYY-MM-dd');

    if(this.projectStatus == "0" || this.startDate == undefined || this.endDate == undefined || this.actionStatus == "0"){
      alert("Please select required Items")
      return
    }
    // if(this.startDate > this.endDate){
    //   alert('Start date should not be greater than End date')
    //   return
    // }
    this.actionItemList = [];
    console.log(this.projectStatus);
    if(this.projectStatus == 'non-project'){
      console.log("non-project");
      this.gs.get_actionItemByNonProject(this.startDate,this.endDate,this.actionStatus).subscribe(
        d=>{
          this.noData=false;
          this.actionItemList = d;
          console.log(this.actionItemList);
        },
        (error)=>{
          this.noData=true;
          console.log('No Data');
        }
      )
    }
    else if(this.startDate <= this.endDate){
      console.log("project");
      this.gs.get_actionItemByProject(this.startDate,this.endDate,this.actionStatus,this.projectStatus).subscribe(
        d=>{
          this.noData=false;
          this.actionItemList = d
          console.log(this.actionItemList);
          
        },
        (error)=>
        {
          this.noData=true;
          console.log('No Data');
        }
      );
    }
    else
      {
        console.log("StartDate is Less Then End date");
        this.errorMsg=true;
      }

  }

  action(e){
    this.dialog.open(UpdateActionComponent,{
      data:e,
      width:'500px'
    })
  }

  // mat Sort Function
  sortData(sort: Sort) {
    const data = this.actionItemList.slice();
    if (!sort.active || sort.direction === '') {
      this.actionItemList  = data;
      return;
    }

    this.actionItemList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'actionItemName':
          return this.compare(a.actionItemName, b.actionItemName, isAsc);
        case 'actionItemTargetDate':
          return this.compare(a.actionItemTargetDate, b.actionItemTargetDate, isAsc);
        case 'actionItemOwner':
          return this.compare(a.actionItemOwner, b.actionItemOwner, isAsc);
        // case 'projectName':
        //   return this.compare(a.mom.project.projectName, b.mom.project.projectName, isAsc);
        //   case 'actionItemStatus':
        //     return this.compare(a.actionItemStatus, b.actionItemStatus, isAsc);
        default:
          return 0;
      }
    });
  }
  
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  changepage(){
    console.log("Search");
    console.log(this.actionItemList.length);
    if(this.search.length!= 0)
    {
       this.p = 1;
       console.log(this.actionItemList.length);
      // this.taskEmpList.length=0;
      // console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        console.log(this.p);
        console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      console.log(this.actionItemList.length);
      console.log("nodata");
    }
  }

  showChanges()
  {
    console.log("show Change");
    this.p=1;
  }
  
}
