<div class="container-fluid">
    <div class="top-content row">
      <div class="col-md-5 col-sm-5">
        <h4>Maximum Work Hours</h4>
        <span class="sub-cont" [routerLink]="['/admin-dashboard']">Dashboard</span> 
        / <span class="sub-cont1">Maximum Work Hours</span>
      </div>
  </div>
  <div class="row m-0">
    <div class="card main-card col-md-6">
        <div class="row">
            <div class="col-md-12" align="center">
            <h5 class="text-center title">Maximum Work Hours</h5>
            </div>
        </div>
        <form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
            <div class="col-md-12" id="loginform">
                <!-- <label class="form-control-label">Maximum work hours<span class="text-danger">*</span></label> -->
                <input type="text" maxlength="2" class="form-control email-input select-placeholder" 
                formControlName="maxWorkHours" placeholder="Enter maximum work hours" (keyup)="WorkHours($event)">
                <div *ngIf="(f.maxWorkHours.invalid && f.maxWorkHours.touched) || f.maxWorkHours.dirty">
                    <small *ngIf="f.maxWorkHours.errors?.required" class="text-danger">Please enter max work hours</small>
                    <small *ngIf="f.maxWorkHours.errors?.pattern" class="text-danger">Please enter valid max work hours</small>   
                </div> 
            </div>  
            <div align="end">
                <button class="btn" type="submit">Update</button>
            </div>
        </form>
    </div>
</div>
  
