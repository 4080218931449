import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-update-action',
  templateUrl: './update-action.component.html',
  styleUrls: ['./update-action.component.css']
})
export class UpdateActionComponent implements OnInit {

  momId:any
  actionForm:FormGroup;
  modifiedTargetDate: any;

  constructor(private gs:GlobalserviceService,
    public dialogRef: MatDialogRef<UpdateActionComponent>,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder,
    private datepipe : DatePipe) { }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.mom.momId);

    this.actionForm = this.fb.group({
      actionItemName:['',Validators.required],
      actionItemTargetDate:['',Validators.required],
      actionItemOwner:['',Validators.required],
      actionItemStatus:['',Validators.required],
      status:['']
    })
 console.log(this.actionForm);
    this.actionForm.patchValue({
      actionItemName:this.data.actionItemName,
      actionItemTargetDate:this.data.actionItemTargetDate,
      actionItemOwner:this.data.actionItemOwner,
      actionItemStatus:this.data.actionItemStatus,
      status:this.data.status
    })
    console.log(this.actionForm);
  }

  get f(){
    return this.actionForm.controls
  }

  onSubmit(){
    if(this.actionForm.invalid){
      return
    }
    console.log(this.actionForm.value);
    let x = this.actionForm.value;
    console.log(x);
    this.modifiedTargetDate= this.datepipe.transform(this.actionForm.value.actionItemTargetDate, 'YYYY-MM-dd');
    x['actionItemTargetDate'] =this.modifiedTargetDate;
    x['mom'] = {
      momId:this.data.mom.momId
    }
    x['actionItemId'] = this.data.actionItemId;
    console.log(x);
    console.log(JSON.stringify(x));

    this.gs.UpdateActionItem(this.data.actionItemId,x).subscribe(
      (data:any)=>{
        Swal.fire({
          text:data.description,
          icon:'success'
        })
        window.location.reload();
      console.log(data);
      },
      err=>{
        Swal.fire({
          text:err.error.description,
          icon:'error'
        })
       window.location.reload();
      }
    )
  }

}