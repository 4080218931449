<div class="container-fluid">
    <!-- <div class="row m-0">
      <div  class="col-md-4 mb-1" *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'">
        <h4 class="m-0">Minutes Of Meeting</h4>
        <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">MOM Reports</span>
      </div>
      <div class="col-md-4 mb-1" *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'">
        <h4 class="m-0">Minutes Of Meeting</h4>
        <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">MOM Reports</span>
      </div> 
      <div class="col-md-5">
        <div class="example-width">
          <ul class="unstyled inbox-pagination">
            <div class="sidebar-search">
              <div class="input-group">
                <input type="text" 
                  class="form-control search-menu" 
                  placeholder="Search..." 
                  [(ngModel)]="search"
                  (keyup) = "changepage()"  
                  name="searchText">
                <div class="material-icons-outlined search-icon">search</div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="float-right col-md-3">
        <button class="btn float-right" type="button" (click)="createMOM()">
          Create MOM
        </button>
      </div>
    </div> -->
   
    <!--  -->
    <div class="top-content row">
      <div class="col-md-5" *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'">
        <h4>Minutes Of Meeting</h4>
        <!-- <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">Action Item Report</span> -->
        <!-- <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">MOM Reports</span> -->
        <span style="font-weight:550;font-size: 13px;" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span style="color: gray;font-size: 13px;">MOM Reports</span>
      </div>
      <div class="col-md-5" *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'">
        <h4>Minutes Of Meeting</h4>
        <!-- <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Action Item Report</span> -->
        <!-- <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">MOM Reports</span> -->
        <span style="font-weight:550;font-size: 13px;" [routerLink]="['/admin-dashboard']">Dashboard</span> / <span style="color: gray;font-size: 13px;">MOM Reports</span>
      </div>
      <div class="col-md-5 col-5-responsive">
        <div class="example-width">
          <ul class="unstyled inbox-pagination">
            <div class="sidebar-search">
              <div class="input-group">
                <input type="text" 
                  class="form-control search-menu" 
                  placeholder="Search..." 
                  [(ngModel)]="search"
                  (keyup) = "changepage()"  
                  name="searchText">
                <div class="material-icons-outlined search-icon">search</div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-2-responsive" style="margin-top: -6px;padding-right: 30px;">
        <button class="btn" type="button" (click)="createMOM()" id="create">
          Create&nbsp;MOM
        </button>
      </div>
    </div>

    <!--  -->
    <div class="row m-0">
      <div class="col-md-3" id="space1">
        <label class="form-control-label">Project<span class="text-danger"> *</span></label>
        <mat-select id="framework" class="form-control email-input select-placeholder" [(ngModel)]="projectStatus" placeholder="Select project">
          <mat-option>-Select Project-</mat-option>
          <mat-option value="non-project">Non Project</mat-option>
          <mat-option *ngFor="let pro of projectList" [value]="pro.projectId">
            {{pro.projectName}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-3" id="space2">
        <label class="form-control-label">Start Date<span class="text-danger"> *</span></label>
        <!-- <input class="form-control" type="Date" [(ngModel)]="startDate" placeholder="Start Date"> -->
        <input matInput [matDatepicker]="picker1" [max]="today" (dateChange)="dateChange($event)" placeholder="Select start date" class="form-control email-input select-placeholder" 
          [(ngModel)]="startDate" type="text" id="date">
          <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>  
      </div>
      <div class="col-md-3" id="space3">
        <label class="form-control-label">End Date<span class="text-danger"> *</span></label>
        <input matInput [matDatepicker]="picker2" class="form-control email-input select-placeholder" [min]="startDate" [max]="today"  (dateChange)="dateChange($event)" placeholder="Select end date" 
        type="text" [(ngModel)]="endDate" id="date">
        <mat-datepicker-toggle matSuffix [for]="picker2" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker> 
        <span class="error_msg" *ngIf="errorMsg" style="color:red;font-size:12px;">Invalid Enddate</span> 
      </div>
      <div class="col-md-3" id="space">
        <button type="button" mat-raised-button  class="btn searchBtn" (click)="searchTask()">Search</button>
      </div>
    </div>
  
  
      <!-- <div *ngIf="momList.length>0"> -->
        <mat-card class="card-content" *ngIf="momList.length>0">
          <div class="row p-0 m-0" *ngIf="momList.length>5">
            <div class="col-xl-12 col-lg-12">
                <div class="select-pagination">
                    <span>Show : </span>
                    <select class="select_list new-select_list" [(ngModel)]="mySelect" (change)="showChanges()">
                        <option class="form-control" value="5"> 5</option>
                        <option class="form-control" value="10">10</option>
                        <option class="form-control" value="15">15</option>
                        <option class="form-control" value="20">20</option>
                        <option class="form-control" value="25">25</option>
                    </select>
                </div>
            </div>  
          </div>
          <div class="card-body table-responsive" style="margin-top:0px;">
            <table class="table" matSort (matSortChange)="sortData($event)">
              <thead>
                <tr>
                  <th style="text-align: center;" scope="col">S.No</th>
                  <th mat-sort-header="momName">Meeting Name</th>
                  <th mat-sort-header="momDate">Meeting Date</th>
                  <th mat-sort-header="client">Client & Place</th>
                  <th>Project Name</th>
                  <th style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let dato of momList | custom:search | paginate:{itemsPerPage:mySelect,currentPage:p}; let i = index">
                  <td *ngIf="p" style="text-align: center;" >{{((p-1)*mySelect)+(i+1)}}</td>
                  <td style="text-align: center;" *ngIf="!p">{{i+1}}</td>
                  <td>{{dato.momName}}</td>
                  <td>{{dato.momDate}}</td>
                  <td>{{dato.client}} - {{dato.meetingPlace}}</td>
                  <td *ngIf="dato.project">{{dato.project.projectName}}</td>
                  <td *ngIf="!dato.project">--</td>
                  <td style="text-align: center;">
                    <span class="material-icons edit-icon" (click)="edit(dato.momId)">edit</span>
                    <span class="material-icons view-icon" (click)="preview(dato.momId)">remove_red_eye</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination-content" *ngIf="momList.length>5" id="page">
            <pagination-controls (pageChange)="p = $event" align="end"></pagination-controls>
          </div>
        </mat-card>
      <!-- </div> -->
      <!-- <div> -->
        <!-- <mat-card class="card-content" *ngIf="noData">
          <div class="card-body">
            <div class="alert alert-primary">
              <h5 class="text-center"> MOM List Not Found</h5>
            </div>
          </div>
        </mat-card> -->

        <mat-card class="card-content" *ngIf="noData">
          <div class="card-body">
            <div class="alert alert-primary">
              <h4 align="center"> MOM List Not Found</h4>
            </div>
          </div>
        </mat-card>
      <!-- </div> -->
  </div>
  
  
