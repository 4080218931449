<div class="container">
  <div style="background: #fafbfb;">
    <div *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'" class="mb-1">
      <h4>Minutes Of Meeting</h4>
      <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">{{momId ? 'Update MOM' : 'Create MOM'}}</span>
    </div>
    <div *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'">
      <h4>Minutes Of Meeting</h4>
      <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">{{momId ? 'Update MOM' : 'Create MOM'}}</span>
    </div>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="form-group col-md-4 full-width">
          <label class="form-control-label">MOM Type<span class="text-danger"> *</span></label>
          <mat-select formControlName="projectValue" placeholder="Project" class="form-control email-input select-placeholder">
            <mat-option *ngFor="let pro of myProject" (click)="onChange(pro.id)" [value]="pro.id">
              {{pro.value}}
            </mat-option>
          </mat-select>
          <div *ngIf="(f.projectValue.invalid && submitted) || f.projectValue.dirty">
            <small *ngIf="f.projectValue.errors?.required" class="text-danger">MOM type is required</small>
          </div>
            <!-- <mat-error *ngIf="f.projectValue.hasError('required')">
              MOM Type is required
            </mat-error> -->
        </div>
        <div class="form-group col-md-4 full-width" *ngIf="isShow">
          <label class="form-control-label">Project<span class="text-danger"> *</span></label>
          <mat-select formControlName="project" class="form-control email-input select-placeholder" placeholder="Select project">
            <mat-option *ngFor="let pro of projectList" [value]="pro.projectId">
              {{pro.projectName}}
            </mat-option>
          </mat-select>
          <div *ngIf="(f.project.invalid && submitted) || f.project.dirty">
            <small *ngIf="f.project.errors?.required" class="text-danger">Project name is required</small>
          </div>
            <!-- <mat-error *ngIf="f.project.hasError('required')">
              Project Name is required
            </mat-error> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 full-width">
          <label class="form-control-label">MOM Name<span class="text-danger"> *</span></label>
          <input formControlName="momName" placeholder="Enter MOM name" 
          (keypress)="keyPress($event)"
          class="form-control email-input select-placeholder">
          <div *ngIf="(f.momName.invalid && submitted) || f.momName.dirty">
            <small *ngIf="f.momName.errors?.required" class="text-danger">MOM name is required</small>
          </div>
          <!-- <mat-error *ngIf="f.momName.hasError('required')">
            MOM Name is required
          </mat-error> -->
        </div>
        <div class="col-md-4 full-width">
          <label class="form-control-label">MOM Date<span class="text-danger"> *</span></label>
          <input matInput [matDatepicker]="picker1" formControlName="momDate" placeholder="Select MOM date" class="form-control email-input select-placeholder"
          [max]="tomorrow" style="height: 43px;" (dateChange)="dateChange($event)">
          <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <div *ngIf="(f.momDate.invalid && submitted) || f.momDate.dirty">
            <small *ngIf="f.momDate.errors?.required" class="text-danger">MOM date is required</small>
          </div>
            <!-- <mat-error *ngIf="f.momDate.hasError('required')">
              MOM Date is required
            </mat-error> -->
        </div>
      </div>
      <div style="position: relative;bottom:15px">
        <div class="row">
          <div class="form-group col-md-4 full-width">
            <label class="form-control-label">Client Name<span class="text-danger"> *</span></label>
            <!-- <input matInput formControlName="client" (keypress)="keyPress($event)"
            placeholder="Enter Client Name" 
            class="form-control email-input select-placeholder"> -->
            <mat-select formControlName="client" (keypress)="keyPress($event)"
            placeholder="Select client name" 
            class="form-control email-input select-placeholder">
              <mat-option *ngFor="let e of customerList" [value]="e.customername">
                {{e.customername}}
              </mat-option>
            </mat-select>
            <div *ngIf="(f.client.invalid && submitted) || f.client.dirty">
              <small *ngIf="f.client.errors?.required" class="text-danger">Client name is required</small>
            </div>
              <!-- <mat-error *ngIf="f.client.hasError('required')">
                Client Name is required
              </mat-error> -->
          </div>
          <div class="form-group col-md-4 full-width">
            <label class="form-control-label">Meeting Place<span class="text-danger"> *</span></label>
            <input matInput formControlName="meetingPlace"
            placeholder="Enter meeting place" 
            class="form-control email-input select-placeholder">
            <div *ngIf="(f.meetingPlace.invalid && submitted) || f.meetingPlace.dirty">
              <small *ngIf="f.meetingPlace.errors?.required" class="text-danger">Meeting place is required</small>
            </div>
              <!-- <mat-error *ngIf="f.meetingPlace.hasError('required')">
                Meeting Place is required
              </mat-error> -->
          </div>
          <div class="form-group col-md-4 full-width">
            <label class="form-control-label">Attendees<span class="text-danger"> *</span></label>
            <textarea matInput formControlName="momAttendees" rows="1" style="font-size: 15px;"
            placeholder="Enter Attendees"
            class="form-control email-input text-placeholder"></textarea>
            <div *ngIf="(f.momAttendees.invalid && submitted) || f.momAttendees.dirty">
              <small *ngIf="f.momAttendees.errors?.required" class="text-danger">Attendees are required</small>
            </div>
              <!-- <mat-error *ngIf="f.momAttendees.hasError('required')">
                Attendees are required
              </mat-error> -->
          </div>
        </div>
        <div formArrayName="pointsDiscussed">
            <!-- <mat-card> -->
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="col-md-6 float-left">
                    <b>The following points were discussed in the meeting.</b>
                  </div>
                  <div class="col-md-6 float-left">
                    <button type="button" class="btn add-buttons" (click)="add()">
                     <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
                     </button>
                   </div>
                </div>
                <!-- <div align="end" class="col-md-4">
                  <button type="button" class="btn add-buttons" (click)="add()">Add</button>
                </div> -->
              </div>
              <div *ngFor="let con of content().controls; let i=index">
              <div [formGroupName]="i" class="d-flex"> 
                  <div class="form-group full-width">
                      <label class="form-control-label">Discussed Points<span class="text-danger"> *</span></label>
                      <textarea formControlName="pointsDiscussedDetails" matInput rows="1" style="font-size: 15px;"
                      placeholder="Enter discussed points"
                      class="form-control email-input text-placeholder"></textarea>
                      <div *ngIf="(submitted && con['controls'].pointsDiscussedDetails.invalid) || con['controls'].pointsDiscussedDetails.dirty">
                        <small *ngIf="con['controls'].pointsDiscussedDetails.errors?.required" class="text-danger">Discussed points are required</small>
                      </div> 
                      <!-- <mat-error *ngIf="content().controls[i].get('pointsDiscussedDetails').errors?.required">
                        Discussed Points is required
                      </mat-error> -->
                  </div>
                  <div class="aligncenter">
                    <mat-icon matSuffix (click)="remove(i)" class="material-icons-outlined delete-icon"
                    *ngIf="i >= 1">
                      delete
                    </mat-icon>
                  </div>
              </div>
            </div>
            <!-- </mat-card> -->

        </div>
        <div formArrayName="pointsAgreed">
            <!-- <mat-card> -->
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="col-md-6 float-left">
                    <b>The following points were agreed in the meeting.</b>
                  </div>
                  <div class="col-md-6 float-left">
                   <button type="button"  class="btn add-buttons" (click)="addAgree()">
                     <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
                   </button>
                  </div>
                </div>
              </div>
              <!-- <div align="end">
                 <button type="button" mat-stroked-button class="subBtn" (click)="addsubContent(i)">
                  Add Sub Content
                </button> -->
                <!--<button type="button"  class="btn add-buttons" (click)="addAgree()">Add</button>
              </div> -->
              <div *ngFor="let con of agree().controls; let i=index">

              <div [formGroupName]="i" class="d-flex">
                  <div class="form-group full-width">
                      <label class="form-control-label">Agreed points<span class="text-danger"> *</span></label>
                      <textarea formControlName="pointsAgreedDetails" rows="1" style="font-size: 15px;"
                      placeholder="Enter agreed points" matInput
                      class="form-control email-input text-placeholder"></textarea>
                      <div *ngIf="(submitted && con['controls'].pointsAgreedDetails.invalid) || con['controls'].pointsAgreedDetails.dirty">
                        <small *ngIf="con['controls'].pointsAgreedDetails.errors?.required" class="text-danger">Agreed points are required</small>
                      </div> 
                      <!-- <mat-error *ngIf="agree().controls[i].get('pointsAgreedDetails').errors?.required">
                        Agreed points is required
                      </mat-error> -->
                  </div>
                  <div class="aligncenter">
                    <mat-icon matSuffix (click)="removeAgree(i)" class="material-icons-outlined delete-icon"
                    *ngIf="i >= 1">delete</mat-icon>
                  </div>
              </div>
            </div>
            <!-- </mat-card> -->
        </div>
        <div formArrayName="actionItem">
            <!-- <mat-card> -->
              <div class="row">
                <div class="col-md-12 p-0">
                  <div class="col-md-6 float-left">
                    <b>The following points were action items in the meeting.</b>
                  </div>
                   <div class="col-md-6 float-left">
                     <button type="button" class="btn add-buttons" (click)="addItem()">
                       <mat-icon class="material-symbols-outlined" style="font-size: 22px;">add</mat-icon>
                     </button>
                   </div>
                </div>
              </div>
              <!-- <div align="end"  *ngIf="myForm.value.actionItem">
                  <button type="button" class="btn add-buttons" (click)="addItem()">Add</button>
              </div> -->
              <div *ngIf="myForm.value.actionItem">
                <div *ngFor="let action of actionItem().controls; let i=index">
                  <div [formGroupName]="i" >
                    <div class="row" *ngIf="momId">
                      <div class="col-md-4 full-width">
                          <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                          <input matInput formControlName="actionItemName"
                          placeholder="Enter action item name" (keypress)="keyPress($event)"
                          class="form-control email-input select-placeholder"/>
                          <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                            <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action item name is required</small>
                          </div> 
                          <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemName').errors?.required">
                            Action Item Name is required
                          </mat-error> -->
                      </div>
                      <div class="col-md-2 full-width">
                          <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                          <input matInput [matDatepicker]="picker" class="form-control email-input" formControlName="actionItemTargetDate"
                          placeholder="Select target date" style="height: 43px;" [min]="momdate">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                            <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                          </div> 
                          <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemTargetDate').errors?.required">
                            Target Date is required
                          </mat-error> -->
                      </div>
                      <!-- <div class="col-md-2 full-width">  
                          <label class="form-control-label">Action Status<span class="text-danger"> *</span></label>
                          <mat-select formControlName="actionItemStatus" readonly placeholder="Action item status">
                            <mat-option value="Pending">Pending</mat-option>
                            <mat-option value="Completed">Completed</mat-option>
                          </mat-select>
                          <div *ngIf="(submitted && action['controls'].actionItemStatus.invalid) || action['controls'].actionItemStatus.dirty">
                            <small *ngIf="action['controls'].actionItemStatus.errors?.required" class="text-danger">Action status is required</small>
                          </div>  -->
                          <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemStatus').errors?.required">
                            Action Status is required
                          </mat-error> -->
                      <!-- </div> -->
                      <div class="col-md-3 full-width">
                          <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                          <input matInput formControlName="actionItemOwner"
                          placeholder="Enter action item owner" (keypress)="keyPress($event)"
                          class="form-control email-input select-placeholder"/>
                          <div *ngIf="(submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty">
                            <small *ngIf="action['controls'].actionItemOwner.errors?.required" class="text-danger">Action item owner is required</small>
                          </div> 
                          <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemOwner').errors?.required">
                            Action Item Owner is required
                          </mat-error> -->
                      </div>
                      <div class="col-md-1 aligncenter">
                        <mat-icon matSuffix (click)="removeItem(i)" class="material-icons-outlined deleteitem-icon"
                        *ngIf="i >= 1">delete</mat-icon>
                      </div>
                    </div>
                    <div class="row" *ngIf="!momId">
                      <div class="col-md-4 full-width">
                          <label class="form-control-label">Action Item Name<span class="text-danger"> *</span></label>
                          <input matInput formControlName="actionItemName"
                          placeholder="Enter action item name" (keypress)="keyPress($event)"
                          class="form-control email-input select-placeholder"/>
                          <div *ngIf="(submitted && action['controls'].actionItemName.invalid) || action['controls'].actionItemName.dirty">
                            <small *ngIf="action['controls'].actionItemName.errors?.required" class="text-danger">Action item name is required</small>
                          </div> 
                          <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemName').errors?.required">
                            Action Item Name is required
                          </mat-error> -->
                      </div>
                      <div class="col-md-3 full-width">
                          <label class="form-control-label">Target Date<span class="text-danger"> *</span></label>
                          <input matInput [matDatepicker]="picker" formControlName="actionItemTargetDate"
                          placeholder="Select target date" class="form-control email-input select-placeholder"  
                          style="height: 43px;" [min]="momdate">
                          <mat-datepicker-toggle matSuffix [for]="picker" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <div *ngIf="(submitted && action['controls'].actionItemTargetDate.invalid) || action['controls'].actionItemTargetDate.dirty">
                            <small *ngIf="action['controls'].actionItemTargetDate.errors?.required" class="text-danger">Target date is required</small>
                          </div> 
                          <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemTargetDate').errors?.required">
                            Target Date is required
                          </mat-error> -->
                      </div>
                      <div class="col-md-5 full-width M-responsive">
                        <label class="form-control-label">Action Item Owner<span class="text-danger"> *</span></label>
                        <input matInput formControlName="actionItemOwner"
                        placeholder="Enter action item owner"(keypress)="keyPress($event)"
                        class="form-control email-input select-placeholder"/>
                        <div *ngIf="(submitted && action['controls'].actionItemOwner.invalid) || action['controls'].actionItemOwner.dirty">
                          <small *ngIf="action['controls'].actionItemOwner.errors?.required" class="text-danger">Action item owner is required</small>
                        </div> 
                        <!-- <mat-error *ngIf="actionItem().controls[i].get('actionItemOwner').errors?.required">
                          Action Item Owner is required
                        </mat-error> -->
                        <div class="aligncenter1">
                          <mat-icon matSuffix (click)="removeItem(i)" class="material-icons-outlined deleteitem-icon"
                          *ngIf="i >= 0">delete</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <!-- </mat-card> -->
        </div>
        <div align="end">
          <button mat-raised-button type="button" class="btn cancel-button m-1"
          (click)="cancel()" style="color: black;">Cancel</button>
          <button mat-raised-button type="submit" class="btn create-button m-1">
            {{momId ? 'Update' : 'Submit'}}</button>
        </div>
      </div>
    </form>
  </div>
  </div>
  
  
