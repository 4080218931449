<div class="container-fluid">
  <div class="top-content">
    <div *ngIf="user_type != 'HumanResource' && user_type != 'Manager'">
      <h4>Employee Task Report</h4>
      <span class="sub-cont" [routerLink]="['/employee-dashboard']"
        >Dashboard</span
      >
      / <span class="sub-cont1">Employee Task Report</span>
    </div>
    <div *ngIf="user_type == 'HumanResource' || user_type == 'Manager'">
      <h4>Employee Task Report</h4>
      <span class="sub-cont" [routerLink]="['/admin-dashboard']"
        >Dashboard</span
      >
      <span class="sub-cont1">Employee Task Report</span>
    </div>
    <div></div>
    <br />
    <br />
    <br />
    <div class="col-md-5 col-sm-5 pr-0">
      <ul class="unstyled inbox-pagination">
        <div class="sidebar-search">
          <div class="input-group">
            <input
              type="text"
              class="form-control search-menu"
              placeholder="Search..."
              [(ngModel)]="search"
              (keyup)="changepage()"
              name="searchText"
            />
            <div class="material-icons-outlined search-icon">search</div>
          </div>
  
        </div>
      </ul>
    </div>
  </div>
 
  <div class="search-content">
    <form #yourForm="ngForm">
      <!-- Your form fields and controls go here -->

    <div class="row" >
  


      <div class="col-md-3" #projectContainer>
        <label class="form-control-label">Project</label>
        <mat-select
          id="framework"

          name="projectid"
          class="form-control email-input select-placeholder"
          (selectionChange)="onSelectionChange()"
          [(ngModel)]="projectId"
          placeholder="Select Project"
          #projectSelect
        >
          <mat-option>-Select Project-</mat-option>
          <mat-option *ngFor="let pro of projectlist" [value]="pro.projectId">
            {{ pro.projectName }}
          </mat-option>
        </mat-select>
        <span class="error_msg" *ngIf="project_required ">Please select project</span>
      </div>
      
      
      <div class="col-sm-3">
     
        <label class="form-control-label">Employee</label>
        <mat-select
          id="framework"
          name="employeeid"
          class="form-control email-input select-placeholder"
          [(ngModel)]="selectedemployeeId"
          placeholder="Select Employee"
        
        >
         <!--   [disabled]="!projectId" -->
          <mat-option>-Select Employee-</mat-option>
          <mat-option
            *ngFor="let name of employeelist"
            [value]="name?.employee?.employeeId"
          >
            {{ name?.employee?.employeeName }}
          </mat-option>
        </mat-select>
       
      </div>

      <div class="col-md-2">
        <label class="form-control-label">Start Date</label>
        <input
          placeholder="Select Date"
          name="startdate"
          readonly
          matInput
          [matDatepicker]="picker1"
          class="form-control email-input"
          style="height: 43px"
          [(ngModel)]="startDate"
          type="text"
      
          (dateChange)="dateChange($event)"
          id="startDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
          style="float: right; position: relative; bottom: 42px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <span class="error_msg" *ngIf="startdate_required"
          >Please select startdate</span
        >
      </div>
      <div class="col-md-2">
        <label class="form-control-label">End Date</label>
        <input
          readonly
          name="enddate"
          placeholder="Select Date"
          matInput
          [matDatepicker]="picker2"
          class="form-control email-input"
          style="height: 43px"
          [(ngModel)]="endDate"
          type="text"
          [min]="startDate"
         
          (dateChange)="dateChange($event)"
          id="endDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
          style="float: right; position: relative; bottom: 42px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <span class="error_msg" *ngIf="enddate_required"
          >Please select enddate</span
        >
      </div>
    
      <!-- <div class="col-md-2">
        <button type="button" class="btn searchBtn" (click)="searchTimesheet()">
          Clear
        </button>
        
      </div> -->
    </div>
    <div class="row">
      <div class="col-sm-3">
     
        <label class="form-control-label">Task Status</label>
        <mat-select
          id="framework"
          name="employeeid"
          class="form-control email-input select-placeholder"
          [(ngModel)]="taskstatus"
          placeholder="Select Task Status"
        
        >
         <!--   [disabled]="!projectId" -->
         <mat-option>-Select Task Status-</mat-option>
            <mat-option [value]="31">To Do</mat-option>
            <mat-option [value]="29">In Progress</mat-option>
            <mat-option [value]="30">Completed</mat-option>
        </mat-select>
       
      </div>
      <div class=" small-view ">
        <div class=" ">
        <button type="button" class="btn searchBtn mr-2 " (click)="searchTimesheet()">
          Search
        </button>
        <button type="button" class="btn searchBtn" (click)="resetForms()">
          Clear
        </button>
      </div>
      </div>
    </div>
  </form>
  </div>
  <mat-card class="card-content mt-5" *ngIf="!isproject">
    <div class="card-body" *ngIf="timesheetreport.length > 0">
      <div class="row m-0">
        <ng-container *ngIf="timesheetreport.length > 5; else alignend">
          <div class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9">
            <span class="badge badge-light totaltime">
            
            </span>
          </div>
        </ng-container>
        <ng-template #alignend>
          <div class="col-xl-12 col-lg-12">
            <span class="badge badge-light totaltime">
         
            </span>
          </div>
        </ng-template>
        <div
          class="col-xl-2 col-lg-3 col-md-3 col-sm-4"
          *ngIf="timesheetreport.length > 5"
        >
          <div class="select-pagination">
            <span>Show : </span>
            <select
              class="select_list new-select_list"
              [(ngModel)]="mySelect"
              (change)="changefirstpage()"
            >
              <option class="form-control" value="5">5</option>
              <option class="form-control" value="10">10</option>
              <option class="form-control" value="15">15</option>
              <option class="form-control" value="20">20</option>
              <option class="form-control" value="25">25</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-responsive" >
        <table class="table" matSort (matSortChange)="sortData($event)">
          <thead>
            <tr class="tr" *ngIf="timesheetreport.length > 0 ">
              <th style="text-align: center">Sl.No</th>
              <th mat-sort-header="employeeName" scope="col">
                Employee&nbsp;Name
              </th>
              <th mat-sort-header="projectName" scope="col">
                Project&nbsp;Name
              </th>
              <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
              <th mat-sort-header="taskCode" scope="col">Task&nbsp;Code</th>
              <th
                mat-sort-header="taskStartDate"
                scope="col"
                style="text-align: center"
              >
                Start&nbsp;Date
              </th>
              <th
                mat-sort-header="taskEndDate"
                scope="col"
                style="text-align: center"
              >
                End&nbsp;Date
              </th>

              <th
                mat-sort-header="workedHours"
                scope="col"
                style="text-align: center"
              >
                Worked&nbsp;Hours
              </th>
              <th
                mat-sort-header="estimatedHours"
                scope="col"
                style="text-align: center"
              >
                Assigned&nbsp;Hours
              </th>
              <th
                mat-sort-header="taskStatusName"
                scope="col"
                style="text-align: center"
              >
                Task&nbsp;Status
              </th>

              <th style="text-align: center" scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
           
            <tr
              *ngFor="
                let e of timesheetreport  | custom : search
                  | paginate : { itemsPerPage: mySelect, currentPage: p };
                let i = index
              "
            >
              <td style="text-align: center">
                {{ (p ? (p - 1) * mySelect : 0) + i + 1 }}
              </td>
              <td>
                {{ e.empolyeeTasks[0]?.employee.employeeName | titlecase }}
              </td>
              <td>{{ e.project?.projectName | titlecase }}</td>
              <td>{{ e.taskName | titlecase }}</td>
              <td class="text-center">
                <ng-container *ngIf="!e.taskCode; else showTaskCode">{{
                  "-"
                }}</ng-container>
                <ng-template #showTaskCode>{{ e.taskCode }}</ng-template>
              </td>
              <td style="text-align: center; ">
                {{ e.taskStartDate }}
              </td>
              <td style="text-align: center; ">
                {{ e.taskEndDate }}
              </td>
              <td style="text-align: center;">
                {{ e.totalWorkingHours }}
              </td>

           
              
              <td class="text-center" *ngIf="!e.empolyeeTasks[0]?.assignedhours || !e.empolyeeTasks[0]?.assignedminutes">{{ '-' }}</td>

              <td style="text-align: center; padding-right: 30px" *ngIf="e.empolyeeTasks[0]?.assignedhours && e.empolyeeTasks[0]?.assignedminutes">
                {{ e.empolyeeTasks[0]?.assignedhours | number:'2.0' }}:{{ e.empolyeeTasks[0]?.assignedminutes | number:'2.0' }}
              </td>
              
             
              <td
                style="text-align: center; padding-right: 30px"
                [style.color]="withDrawStatus(e)"
              >
                {{ e.taskStatusName }}
              </td>
              <td
                style="text-align: center; padding-right: 30px"
                [style.color]="approvalStatus(e)"
              >
                {{ e.approvalStatusName }}
              </td>

              <td class="more" align="center">
                <span
                  class="material-icons"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  more_vert
                </span>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="viewTimeSheet(e)">
                    <mat-icon
                      style="margin-left: 10px; margin-top: -7px"
                      class="edit-icon"
                      >date_range</mat-icon
                    >

                    <span style="margin-left: 4px" class="edit-form"
                      >View TimeSheet</span
                    >
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination-controls
      (pageChange)="p = $event"
      *ngIf="timesheetreport.length > 5"
      align="end"
    ></pagination-controls>
  </mat-card>
  <mat-card class="card-content" *ngIf="timesheetreport.length === 0 || noDataMessage === 'No Data' ">
    <div class="card-body">
      <div class="alert alert-primary">
        <h4 align="center">Employee Task Not Found</h4>
      </div>
    </div>
  </mat-card>
  <!-- <mat-card class="card-content" *ngIf="nodata">
    <div class="card-body">
      <div class="alert alert-primary">
        <h4 align="center">Employee Task Not Found</h4>
      </div>
    </div>
  </mat-card> -->

</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
