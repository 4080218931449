import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css',]
})
export class NavbarComponent implements OnInit {
 navbar: any = ['Home','Pricing','Customers', 'Partners', 'Support','Resources']; 
  constructor() { }
 
  ngOnInit() {
  }

}
