<div class="container">
    <!-- <br> -->
    <div class="top-content">        
      <h4>Approvers</h4>
      <div>
        <span class="material-icons float-right edit-icon"
        [routerLink]="['/approver-list/add-approver',data.approverId]" mat-dialog-close>edit</span>
      </div>
        <span class="material-icons c" style="color:red;"  mat-dialog-close>cancel</span>

    </div>
        <div>
            <div>
              <div class="row" >
                <div class="col-md-8">
                 <h6>{{ data.approverOneObject.employeeName}}</h6>
                 <h6 class="email-id-new">{{ data.approverOneObject.organizationEmail }}</h6>
                </div>
                <!-- <div class="col-md-4">               
                 <h6  class="approver-status" [style.color]="approvalStatus(data.projectApprovers.projectApproverStatus)" 
                 [style.backgroundColor]="approvalBackground(data.approval.approverOneStatusName)" align="center"> {{ data.approval.approverOneStatusName }} </h6>
                </div>                 -->
             </div>
            </div>
            <hr/>
            <div  *ngIf="data.approverTwoObject">
              <div class="row" >
                <div class="col-md-8">
                 <h6>{{ data.approverTwoObject.employeeName}}</h6>
                 <h6 class="email-id-new">{{ data.approverTwoObject.organizationEmail }}</h6>
                </div>
                <!-- <div class="col-md-4">               
                 <h6 class="approver-status"  [style.color]="approvalStatus(data.approval.approverTwoStatusName)" 
                 [style.backgroundColor]="approvalBackground(data.approval.approverTwoStatusName)" align="center"> {{ data.approval.approverTwoStatusName }}</h6>
                </div>                 -->
             </div>
             <hr/>
            </div>
            <div  *ngIf="data.approverThreeObject">
              <div class="row" >
                <div class="col-md-8">
                 <h6>{{ data.approverThreeObject.employeeName}}</h6>
                 <h6 class="email-id-new">{{ data.approverThreeObject.organizationEmail }}</h6>
                </div>
                <!-- <div class="col-md-4">               
                 <h6 class="approver-status" [style.color]="approvalStatus(data.approval.approverThreeStatusName)" 
                 [style.backgroundColor]="approvalBackground(data.approval.approverThreeStatusName)" align="center"> {{ data.approval.approverThreeStatusName }}</h6>
                </div>                 -->
             </div>
             <hr/>
            </div>
         
            <div  *ngIf="data.approverFourObject">
              <div class="row" >
                <div class="col-md-8">
                 <h6>{{ data.approverFourObject.employeeName}}</h6>
                 <h6 class="email-id-new">{{ data.approverFourObject.organizationEmail }}</h6>
                </div>
                <!-- <div class="col-md-4">               
                 <h6  class="approver-status" [style.color]="approvalStatus(data.approval.approverFourStatusName)" 
                 [style.backgroundColor]="approvalBackground(data.approval.approverFourStatusName)" align="center" > {{ data.approval.approverFourStatusName }}</h6>
                </div>                 -->
             </div>
             <hr/>
            </div>
           
            <div  *ngIf="data.approverFiveObject">
              <div class="row" >
                <div class="col-md-8">
                 <h6>{{ data.approverFiveObject.employeeName}}</h6>
                 <h6 class="email-id-new">{{ data.approverFiveObject.organizationEmail }}</h6>
                </div>
                <!-- <div class="col-md-4">               
                 <h6 class="approver-status" [style.color]="approvalStatus(data.approval.approverFiveStatusName)" 
                 [style.backgroundColor]="approvalBackground(data.approval.approverFiveStatusName)" align="center"> {{ data.approval.approverFiveStatusName }}</h6>
                </div>                 -->
             </div>
            <hr/>
            </div>
            <div class="top-content">
               
              <h4>FYI</h4>
          
          </div>
            <div>
              <div class="row" >
                <div class="col-md-6">
                 <h6>{{ data.fyiObject.employeeName}}</h6>
                 <h6 class="email-id-new">{{ data.fyiObject.organizationEmail }}</h6>
                </div>                 
             </div>
            </div>
          </div>
        </div>
  
  
