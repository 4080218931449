<div class="container-fluid">
  <div class="top-content">
    <div *ngIf="user_type != 'HumanResource' && user_type != 'Manager'">
      <h4>Consolidate Timesheet Report</h4>
      <span class="sub-cont" [routerLink]="['/employee-dashboard']"
        >Dashboard</span
      >
      / <span class="sub-cont1">Consolidate Timesheet Report</span>
    </div>
    <div *ngIf="user_type == 'HumanResource' || user_type == 'Manager'">
      <h4>Consolidate Timesheet Report</h4>
      <span class="sub-cont" [routerLink]="['/admin-dashboard']"
        >Dashboard</span
      >
      / <span class="sub-cont1">Consolidate Timesheet Report</span>
    </div>
    <div></div>
    <br />
    <br />
    <br />
  </div>

  <div class="search-content">
    <div class="row">
      <!-- <div class="col-md-3">
           
            <label class="form-control-label">Project<span class="text-danger"> *</span></label>
            <mat-select id="framework" class="form-control email-input select-placeholder" [(ngModel)]="projectId" 
            (selectionChange) = "getTask()"
            placeholder="Select Project"
          >
              <mat-option>-Select Project-</mat-option>             
              <mat-option *ngFor="let pro of projectList" 
                [value]="pro.projectId">
                {{pro.projectName}}
              </mat-option>
            </mat-select>
            <span class="error_msg" *ngIf="employee_required">Please select project</span>
          </div> -->

      <div class="col-md-3" #projectContainer>
        <label class="form-control-label"
          >Project</label
        >
        <mat-select
          id="framework"
          class="form-control email-input select-placeholder"
          (selectionChange)="onSelectionChange()"
          [(ngModel)]="projectId"
          placeholder="Select Project"
          #projectSelect
        >
          <mat-option>-Select Project-</mat-option>
          <mat-option *ngFor="let pro of projectlist" [value]="pro.projectId">
            {{ pro.projectName }}
          </mat-option>
        </mat-select>
        <span class="error_msg" *ngIf="project_required"
          >Please select project</span
        >
      </div>

      <div class="col-md-3">
        <label class="form-control-label">Employee</label>
        <mat-select
          id="framework"
          class="form-control email-input select-placeholder"
          [(ngModel)]="selectedemployeeId"
          
          placeholder="Select Employee"
        >
          <mat-option>-Select Employee-</mat-option>
          <mat-option
            *ngFor="let name of employeelist"
            [value]="name?.employee?.employeeId"
          >
            {{ name?.employee?.employeeName }}
          </mat-option>
        </mat-select>
        <!-- <span class="error_msg" *ngIf="employee_required">Please select employee</span>  -->
      </div>
      <div class="col-md-3">
        <label class="form-control-label">Start Date</label>
        <input
          placeholder="Select Date"
          matInput
          readonly
          [matDatepicker]="picker1"
          class="form-control email-input"
          style="height: 43px"
          [(ngModel)]="startDate"
          type="text"
          [max]="today"
          (dateChange)="dateChange($event)"
          id="startDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
          style="float: right; position: relative; bottom: 42px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <span class="error_msg" *ngIf="startdate_required"
          >Please select startdate</span
        >
      </div>
      <div class="col-md-3">
        <label class="form-control-label">End Date</label>
        <input
          placeholder="Select Date"
          matInput
          readonly
          [matDatepicker]="picker2"
          class="form-control email-input"
          style="height: 43px"
          [(ngModel)]="endDate"
          type="text"
          [min]="startDate"
          [max]="today"
          (dateChange)="dateChange($event)"
          id="endDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
          style="float: right; position: relative; bottom: 42px"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <span class="error_msg" *ngIf="enddate_required"
          >Please select enddate</span
        >
      </div>
    
    </div>
    <div class="row">
      <div class="col-md-12" style="top:-40px">
        <button
          type="button"
          class="btn searchBtn mr-2"
          (click)="searchTimesheet()"
        >
          Search
        </button>
        <button type="button" class="btn searchBtn" (click)="resetForms()">
          Clear
        </button>
      </div>
    </div>
  </div>
  <mat-card class="card-content" *ngIf="!isproject">
    <div class="card-body" *ngIf="timesheetreport.length > 0">
      <div class="row m-0">
        <ng-container *ngIf="timesheetreport.length > 5; else alignend">
          <div class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9">
            <span class="badge badge-light totaltime"
              >Total Time : {{ tasktotalTime }}</span
            >
          </div>
        </ng-container>
        <ng-template #alignend>
          <div class="col-xl-12 col-lg-12">
            <span class="badge badge-light totaltime"
              >Total Time : {{ tasktotalTime }}</span
            >
          </div>
        </ng-template>
        <div
          class="col-xl-2 col-lg-3 col-md-3 col-sm-4"
          *ngIf="timesheetreport.length > 5"
        >
          <div class="select-pagination">
            <span>Show : </span>
            <select
              class="select_list new-select_list"
              [(ngModel)]="mySelect"
              (change)="changefirstpage()"
            >
              <option class="form-control" value="5">5</option>
              <option class="form-control" value="10">10</option>
              <option class="form-control" value="15">15</option>
              <option class="form-control" value="20">20</option>
              <option class="form-control" value="25">25</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table" matSort (matSortChange)="sortData($event)">
          <thead>
            <tr class="tr" *ngIf="timesheetreport.length > 0">
              <th style="text-align: center">Sl.No</th>
              <th mat-sort-header="employeeName" scope="col">
                Employee&nbsp;Name
              </th>
              <th mat-sort-header="projectName" scope="col">
                Project&nbsp;Name
              </th>
              <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
              <th mat-sort-header="taskName" scope="col">Task&nbsp;Code</th>
              <th mat-sort-header="date" scope="col" style="text-align: center">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </th>
              <th
                mat-sort-header="workedHours"
                scope="col"
                style="text-align: center"
              >
                Worked&nbsp;Hours
              </th>

              <th
                mat-sort-header="estimatedHours"
                scope="col"
                style="text-align: center"
              >
                Estimated&nbsp;Hours
              </th>
              <th
                mat-sort-header="taskStatusName"
                scope="col"
                style="text-align: center"
              >
                Task&nbsp;Status
              </th>
              <th
                mat-sort-header="approvalStatusName"
                scope="col"
                style="text-align: center"
              >
                Approval&nbsp;Status
              </th>
              <th style="text-align: center" scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let e of timesheetreport
                  | paginate : { itemsPerPage: mySelect, currentPage: p };
                let i = index
              "
            >
              <td style="text-align: center" *ngIf="p">
                {{ (p - 1) * mySelect + (i + 1) }}
              </td>
              <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
              <td>{{ e.employee.employeeName | titlecase }}</td>
              <td>{{ e.project.projectName | titlecase }}</td>
              <td>{{ e.task.taskName | titlecase }}</td>
              <td class="text-center">
                <ng-container *ngIf="!e.task.taskCode; else showTaskCode">{{
                  "-"
                }}</ng-container>
                <ng-template #showTaskCode>{{ e.task.taskCode }}</ng-template>
              </td>
              <td style="text-align: center; padding-right: 20px">
                {{ e.date }}
              </td>
              <td style="text-align: center; padding-right: 30px">
                {{ e.workedHours }}
              </td>

              <td class="text-center">
                <ng-container
                  *ngIf="!e.task.estimatedHours; else showTaskCode"
                  >{{ "-" }}</ng-container
                >
                <ng-template #showTaskCode>{{
                  e.task.estimatedHours < 10 ? "0" : ""
                }}</ng-template>
              </td>

              <td
                style="text-align: center; padding-right: 30px"
                [style.color]="withDrawStatus(e)"
                class="status"
              >
                {{ e.task.taskStatusName }}
              </td>
              <td
                style="text-align: center; padding-right: 30px"
                [style.color]="approvalStatus(e)"
                class="status"
              >
                {{ e.approval?.approvalStatusName }}
              </td>
              <td class="more" align="center">
                <span
                  class="material-icons"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  more_vert
                </span>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="description(e)">
                    <mat-icon class="edit-icon">date_range</mat-icon>
                    <span class="edit-form">View Description</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination-controls
      (pageChange)="p = $event"
      *ngIf="timesheetreport.length > 5"
      align="end"
    ></pagination-controls>
  </mat-card>

  <mat-card class="card-content" *ngIf="nodata && timesheetreport.length === 0">
    <div class="card-body">
      <div class="alert alert-primary">
        <h4 align="center">Timesheet Not Found</h4>
      </div>
    </div>
  </mat-card>

  <!-- <div class="table-responsive"  *ngIf="showEmptyData && timesheetreport.length === 0">
    <table class="table" matSort (matSortChange)="sortData($event)">
      <thead>
        <tr class="tr" >
          <th style="text-align: center">Sl.No</th>
          <th mat-sort-header="employeeName" scope="col">
            Employee&nbsp;Name
          </th>
          <th mat-sort-header="projectName" scope="col">
            Project&nbsp;Name
          </th>
          <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
          <th mat-sort-header="taskName" scope="col">Task&nbsp;Code</th>
          <th mat-sort-header="date" scope="col" style="text-align: center">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </th>
          <th
            mat-sort-header="workedHours"
            scope="col"
            style="text-align: center"
          >
            Worked&nbsp;Hours
          </th>

          <th
            mat-sort-header="estimatedHours"
            scope="col"
            style="text-align: center"
          >
            Estimated&nbsp;Hours
          </th>
          <th
            mat-sort-header="taskStatusName"
            scope="col"
            style="text-align: center"
          >
            Task&nbsp;Status
          </th>
          <th
            mat-sort-header="approvalStatusName"
            scope="col"
            style="text-align: center"
          >
            Approval&nbsp;Status
          </th>
          <th style="text-align: center" scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let e of timesheetreport
              | paginate : { itemsPerPage: mySelect, currentPage: p };
            let i = index
          "
        >
          <td style="text-align: center" *ngIf="p">
            {{ (p - 1) * mySelect + (i + 1) }}
          </td>
          <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
          <td>{{ e.employee.employeeName | titlecase }}</td>
          <td>{{ e.project.projectName | titlecase }}</td>
          <td>{{ e.task.taskName | titlecase }}</td>
          <td class="text-center">
            <ng-container *ngIf="!e.task.taskCode; else showTaskCode">{{
              "-"
            }}</ng-container>
            <ng-template #showTaskCode>{{ e.task.taskCode }}</ng-template>
          </td>
          <td style="text-align: center; padding-right: 20px">
            {{ e.date }}
          </td>
          <td style="text-align: center; padding-right: 30px">
            {{ e.workedHours }}
          </td>

          <td class="text-center">
            <ng-container
              *ngIf="!e.task.estimatedHours; else showTaskCode"
              >{{ "-" }}</ng-container
            >
            <ng-template #showTaskCode>{{
              e.task.estimatedHours < 10 ? "0" : ""
            }}</ng-template>
          </td>

          <td
            style="text-align: center; padding-right: 30px"
            [style.color]="withDrawStatus(e)"
            class="status"
          >
            {{ e.task.taskStatusName }}
          </td>
          <td
            style="text-align: center; padding-right: 30px"
            [style.color]="approvalStatus(e)"
            class="status"
          >
            {{ e.approval?.approvalStatusName }}
          </td>
          <td class="more" align="center">
            <span
              class="material-icons"
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              more_vert
            </span>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="description(e)">
                <mat-icon class="edit-icon">date_range</mat-icon>
                <span class="edit-form">View Description</span>
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
