import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    console.log("MustMatch");
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
         //   console.log("matching if")
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
           // console.log("control value if")
            matchingControl.setErrors({ mustMatch: true });
        } else {
           // console.log("control value else")
            matchingControl.setErrors(null);
        }
    }
}
