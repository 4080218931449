<div class="container">
    <h3 class="material-icons float-right" style="color:red;"  align="end" mat-dialog-close>
        cancel
      </h3>
      <h3 align="center">Approver Policy</h3>
    <form [formGroup]="ApproverPolicyForm" (ngSubmit)="addapproverpolicy()">
          <!-- Task Type -->
          <mat-form-field class="input-width" appearance="legacy">
            <mat-label>
               <!-- <mat-icon matPrefix>layers</mat-icon>  -->
               Approver policy Name* </mat-label>
            <input
              type="text"
              matInput
              placeholder="Enter Task Name"
              formControlName="approverpolicyname"
              (keyup) = "approverpolicyvalidate()"   (keypress)="omit_special_char($event) && keyPress($event) "
            />           
            <mat-error *ngIf="f.approverpolicyname">Please Enter Approver Policy Name</mat-error>
          </mat-form-field>       
             <span *ngIf="approverpolicyexist"  class="span-error"> Approver Already Exist</span>   
          <mat-form-field  class="input-width">
            <mat-label>
              First Approver Hierarchy*
            </mat-label>
            <mat-select formControlName="firstapprovertype" placeholder="Select Approver Type"   (selectionChange) = "approvertypevalidate()">
              <mat-option *ngFor="let aprover of approvertype" [value]="aprover.listTypeValueId" >
                {{aprover.listTypeValueName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.firstapprovertype">Approver Type is Requried</mat-error>
          </mat-form-field>
       
                <!-- Task Type -->
                <mat-form-field  class="input-width">
                    <mat-label>
                        Second Approver Hierarchy*
                    </mat-label>
                    <mat-select formControlName="secondapprovertype" placeholder="Select Approver Rule"  (selectionChange) = "approvertypevalidate()">
                      <mat-option *ngFor="let approver of approvertype" [value]="approver.listTypeValueId">
                        {{approver.listTypeValueName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.secondapprovertype">Approver Type is Requried</mat-error>
                  </mat-form-field>
                  <span *ngIf="approvertypesame"  class="span-error"> ApproverType  Cannot  be Same</span>   

                  <div>
                    <mat-checkbox formControlName="default" > Default
                    </mat-checkbox>
                  </div>
                  
                   
                

        <div mat-dialog-actions align="end">
          <button
            class="btn"
            type="submit"
            [disabled]="ApproverPolicyForm.invalid"
            cdkFocusInitial
          >
          {{ data.approversPolicyId ? 'Update': 'Create'}}
          <!-- Create -->
          </button>
        </div>
      </form>
</div>