import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { ViewdescripeComponent } from "../viewdescripe/viewdescripe.component";
import { MatDialog, Sort } from "@angular/material";
import { GlobalserviceService } from "../providers/globalservice.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { log } from "console";
import { element } from "protractor";
import { TimeSheetComponent } from "../time-sheet/time-sheet.component";
import { DataSource } from "@angular/cdk/collections";
import { BreakpointObserver } from "@angular/cdk/layout";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-employee-task-report",
  templateUrl: "./employee-task-report.component.html",
  styleUrls: ["./employee-task-report.component.css"],
})
export class EmployeeTaskReportComponent implements OnInit {
  projectId: any;
  projectList: any = [];
  taskList: any;
  projectStatus: any = "0";
  timesheetDate: any;
  p: any;
  user_type: string;
  search: any;
  userType: any;
  isData: boolean;
  taskId: number = 0;

  colon=":";
  public timesheetreport: any[] = [];
  isproject: boolean;
  nodata = false;
  loader = false;
  public searchFilter: any = "";
  query: any = "";
  beforepage: any;
  mySelect: any = 5;
  employeelist: any = []=[];
  employeeId: any;
  employeeTimeSheetData: any[] = [];
  projectlist: any;
  selectedemployeeId: any;
  startDate: any;
  endDate: any;
  disable_project: boolean = true;
  enddate_required: boolean = false;
  startdate_required: boolean = false;
  tasktotalTime: any;
  today: any;
  employee_required: boolean = false;
  organizationId:any;
  empId: any;
  project_required: boolean = false;
  orgemployeedata: any;
  taskstatus: any;
  noDataMessage:any;
  beforesearchpage: any;
  constructor(
    private gs: GlobalserviceService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {

    
    const data = JSON.parse(localStorage.getItem("UserData"));
    this.empId = data.employeeId;
    this.organizationId = data.organization.organizationId;
    console.log("organzationid" + this.organizationId);
    this.today = new Date();
    this.today = this.datepipe.transform(this.today, "YYYY-MM-dd");

    // this.gs.getResource().subscribe(
    //   (d: any) => {
    //     //this.loader = false;
    //     this.employeelist = d;
    //   },
    //   (error) => {
    //     //  this.loader = false;
    //     //  this.nodata = true;
    //     console.log("error");
    //     // if(error.status == 404){
    //     //   this.nodata = true;
    //     //   console.log("404")
    //     // }
    //   }
    // );


    this.gs
      .getProjectByOrganization(this.organizationId)
      .subscribe((data: any) => {
        console.log("data", data);

        console.log("data", data[0].projectId);
        this.projectList.push(data);
        this.projectList = data;
        this.projectlist =  data;

        console.log('this.projectlist',this.projectlist[0].projectName);
        
      });
      this.gs
      .getResource()
      .subscribe((data: any) => {
        console.log("data", data);
          this.orgemployeedata = data;
        data.forEach(element => {
          let employee = {
            employee: {
              employeeId : element.employeeId,
              employeeName : element.employeeName
            }
          
          }

          this.employeelist.push(employee);
        });
    
          console.log(this.employeelist)
      });
  }

  getTask() {
   if (this.projectId) {
      this.disable_project = false;
      this.gs.getEmployeeProject(this.projectId).subscribe(
        (data: any) => {
          console.log("data", data);
          this.employeelist = data;

          // this.employeelist.push({data})
        },
        (error) => {
          console.error("Error fetching employee data:", error);
          // Handle the error (e.g., show an error message to the user)
        }
      );
    }else{

             this.employeelist.length = 0;
           this.orgemployeedata.forEach(element => {
        let employee = {
          employee: {
            employeeId : element.employeeId,
            employeeName : element.employeeName
          }
        
        }

        this.employeelist.push(employee);
         });
    }
  }

  // getTask() {
  //   this.employee_required = false;
  //   this.disable_project = false;
  //   console.log(this.disable_project);
  //   console.log("employeeId" + this.selectedemployeeId);
  //   // if(this.projectId  == undefined){
  //   //   this.employeelist.length = 0;
  //   // }
  //   // else{

  //     // if (this.projectId) {
  //       // this.disable_project = false;
  //       this.gs.getEmployeeProject(this.projectId).subscribe((data: any) => {
  //         console.log("datadddd", data);
  //         this.employeelist = data;
  //       });
  //     // }

  //   // }
  // }

  
  dateChange(event) {
    console.log(event.value);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  changefirstpage() {
    this.p = 1;
  }

  // searchTimesheet() {
  //   this.changefirstpage();
  //   this.startDate = this.datepipe.transform(this.startDate, "YYYY-MM-dd");
  //   this.endDate = this.datepipe.transform(this.endDate, "YYYY-MM-dd");

  //   if (this.selectedemployeeId == undefined) {
  //     this.employee_required = true;
  //     return;
  //   }

  //   this.employee_required = false;
  //   if (this.projectId == undefined || this.projectId == 0) {
  //     if (this.startDate == null && this.endDate == null) {
  //       this.gs.assigningTaskByEmployee(this.selectedemployeeId).subscribe(
  //         (data: any) => {
  //           console.log("timesheetreport", data);
  //           this.taskId = data[0].taskId;

  //           console.log("this.taskId", this.taskId);
  //           this.employeeTimeSheetData = data;
  //           this.nodata = true;
  //           console.log(
  //             "taskId",
  //             data[0].taskId,
  //             "employeeId:",
  //             this.selectedemployeeId
  //           );

  //           this.timesheetreport = data;

  //           this.nodata = false;
  //           this.tasktotalTime = this.timesheetreport[0]?.totalTime;
  //         },
  //         (error) => {
  //           this.nodata = true;
  //           this.timesheetreport.pop();
  //           this.timesheetreport.splice(0, this.timesheetreport.length);
  //         }
  //       );
  //     } else {
  //       if (this.startDate || this.endDate) {
  //         if (this.startDate) {
  //           this.startdate_required = true;
  //         } else if (this.endDate) {
  //           this.enddate_required = true;
  //         }
  //         return;
  //       }
  //       console.log(this.startDate);
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //     }
  //   } else {
  //     if (
  //       this.selectedemployeeId &&
  //       this.projectId &&
  //       this.startDate == null &&
  //       this.endDate == null
  //     ) {
  //       this.gs
  //         .selectingTaskByEmployee(this.selectedemployeeId, this.projectId)
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);

  //             if (data.length > 0 || data.length >= 1) {
  //               this.timesheetreport = data;
  //             }
  //             this.employeeTimeSheetData = data;
  //             console.log(
  //               "projectId:",
  //               this.projectId,
  //               "employeeId:",
  //               this.selectedemployeeId
  //             );

  //             this.nodata = false;
  //            // this.tasktotalTime = this.timesheetreport[0].totalTime;

  //             if (data.message || data.length === 0) {
  //               this.timesheetreport.pop();
  //               this.nodata = false;
  //             }
  //           },
  //           (error) => {
  //             this.timesheetreport.pop();
  //             this.nodata = true;
  //             this.timesheetreport.splice(0, this.timesheetreport.length);
  //           }
  //         );
  //     } else {
  //       if (this.startDate == undefined || this.endDate == undefined) {
  //         if (this.startDate == undefined) {
  //           this.startdate_required = true;
  //         } else if (this.endDate == undefined) {
  //           this.enddate_required = true;
  //         }
  //         return;
  //       }
  //       this.enddate_required = false;
  //       this.startdate_required = false;
  //       this.gs
  //         .getEmpIdProIdStardateEnddate(
  //           this.projectId,
  //           this.selectedemployeeId,
  //           this.startDate,
  //           this.endDate
  //         )
  //         .subscribe(
  //           (data: any) => {
  //             console.log(data);
  //             if (this.timesheetreport.length === 0) {
  //               this.nodata = true;
  //             }

  //             this.employeeTimeSheetData = data;
  //             this.timesheetreport = data;

  //             this.nodata = false;
  //            // this.tasktotalTime = this.timesheetreport[0].totalTime;
  //           },
  //           (error) => {
  //             this.nodata = true;
  //             this.timesheetreport.pop();
  //             this.timesheetreport.splice(0, this.timesheetreport.length);
  //           }
  //         );
  //     }
  //   }
  // }

   // Assuming projectId is a string, adjust as needed
   showErrorMessage = false;

   @ViewChild('projectContainer') projectContainer: ElementRef | undefined;
   @ViewChild('projectSelect') projectSelect: ElementRef | undefined;
   
   onSelectionChange() {
    // //this.showErrorMessage = true;;
     this.getTask();
   }
   
   // Detect clicks outside the select box
  //  @HostListener('document:click', ['$event'])
  //  onClickOutside(event: Event) {
  //    if (
  //      this.projectContainer &&
  //      this.projectContainer.nativeElement &&
  //      !this.projectContainer.nativeElement.contains(event.target) &&
  //      this.projectSelect &&
  //      this.projectSelect.nativeElement &&
  //      !this.projectSelect.nativeElement.contains(event.target)
  //    ) {
  //      this.showErrorMessage = false;
  //    }
  //  }


  searchTimesheet(){
    this.changefirstpage();
    this.nodata = false;
    this.startDate = this.datepipe.transform( this.startDate , 'YYYY-MM-dd');
    this.endDate = this.datepipe.transform( this.endDate , 'YYYY-MM-dd');
  
//     if((this.projectId == 0  || this.projectId == undefined) && this.selectedemployeeId == undefined){
//  //     this.project_required =true;
//        alert("Please select project or employee");
//       return
//     }


// if (this.projectId == 0 || this.projectId == undefined) {
//   // this.employee_required = true;
//   this.project_required = true;
//   return;
// }else{
//   this.project_required = false;
// }
    if((this.projectId == 0  || this.projectId == undefined)  &&   this.startDate == undefined  && this.endDate == undefined  &&  this.selectedemployeeId == undefined ){
      //     this.project_required =true;
            alert("Please select project or date range");
           return
         }
         
         if((this.startDate != undefined  && this.endDate == undefined ) || this.startDate == undefined  && this.endDate != undefined ){
                if(this.startDate == undefined){
                  this.startdate_required = true;
                }
                else if(this.endDate==undefined ){
                  this.enddate_required = true;
                }
                return
              }
    // this.timesheetreport.length=0;
   
    this.employee_required =false;

    console.log("this.selectedemployeeId" +this.selectedemployeeId);



      if(this.selectedemployeeId != undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId == undefined && this.taskstatus == undefined)
      {
        console.log("gettaskbyEmployeeAndStartDateAndEndDate");
        this.gs.gettaskbyEmployeeAndStartDateAndEndDate
        (this.selectedemployeeId,this.startDate,this.endDate)
        .subscribe((data:any)=>{
          console.log(data);
          this.timesheetreport = data;
          this.noDataMessage = data.message;
          console.log('this.timesheetreport',  this.timesheetreport);
          this.employeeTimeSheetData = data;
          if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = false;
            }
         // this.tasktotalTime = this.timesheetreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
          // //this.showErrorMessage = true;;
          this.timesheetreport.pop();
          this.timesheetreport.splice(0, this.timesheetreport.length);
        });
      }else if (this.selectedemployeeId != undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId != undefined && this.taskstatus == undefined)
            {
                  console.log("getEmpIdProIdStardateEnddate");
              this.gs.getEmpIdProIdStardateEnddate
              (this.projectId,this.selectedemployeeId,this.startDate,this.endDate)
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = false;
            }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                //this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }else if (this.selectedemployeeId != undefined  && this.startDate == undefined  && this.endDate == undefined && this.projectId != undefined && this.taskstatus == undefined)
            {
              console.log("getTaskByProjectAndEmployee");
              this.gs.getTaskByProjectAndEmployee(this.projectId , this.selectedemployeeId)
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
                {
                  this.nodata = true;
                }else{
                  this.nodata = false;
                }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                //this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }else if (this.selectedemployeeId == undefined  && this.startDate == undefined  && this.endDate == undefined && this.projectId != undefined && this.taskstatus == undefined)
            {
              console.log("taskByProject");
              this.gs.taskByProject(this.projectId)
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
                {
                  this.nodata = true;
                }else{
                  this.nodata = false;
                }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                ////this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }
            else if (this.selectedemployeeId == undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId != undefined && this.taskstatus == undefined)
            {
              console.log("getTaskByProjectAndDate");
              this.gs.getTaskByProjectAndDate(this.projectId ,this.startDate ,  this.endDate)
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = false;
            }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                ////this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }
    
    



              //with taskstatus
      if(this.selectedemployeeId != undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId == undefined && this.taskstatus != undefined)
      {
        console.log("gettaskbyEmployeeAndStartDateAndEndDatestatus");
        this.gs.gettaskbyEmployeeAndStartDateAndEndDateAndStatus
        (this.selectedemployeeId,this.startDate,this.endDate ,this.taskstatus )
        .subscribe((data:any)=>{
          console.log(data);
          this.timesheetreport =data;
          this.noDataMessage = data.message;
          console.log('this.timesheetreport',  this.timesheetreport);
          console.log("(data.length" + data.length);
          this.employeeTimeSheetData = data;
            if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = true;
            }
       
           
         // this.tasktotalTime = this.timesheetreport[0].totalTime;
        },
        (error) => {
          this.nodata = true;
          // //this.showErrorMessage = true;;
          this.timesheetreport.pop();
          this.timesheetreport.splice(0, this.timesheetreport.length);
        });
      }else if (this.selectedemployeeId != undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId != undefined  && this.taskstatus != undefined) 
            {
                  console.log("getEmpIdProIdStardateEnddatestatus");
              this.gs.getEmpIdProIdStardateEnddateAndStatus
              (this.projectId,this.selectedemployeeId,this.startDate,this.endDate,this.taskstatus)
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
                {
                  this.nodata = true;
                }else{
                  this.nodata = false;
                }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                //this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }else if (this.selectedemployeeId != undefined  && this.startDate == undefined  && this.endDate == undefined && this.projectId != undefined  && this.taskstatus != undefined)
            {
              console.log("getTaskByProjectAndEmployeestatus");
              this.gs.getTaskByProjectAndEmployeeAndStatus(this.projectId , this.selectedemployeeId,this.taskstatus )
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                this.nodata = false;
                if(data.length != undefined)
                {

                }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                //this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }else if (this.selectedemployeeId == undefined  && this.startDate == undefined  && this.endDate == undefined && this.projectId != undefined  && this.taskstatus != undefined)
            {
              console.log("taskByProjectstatus");
              this.gs.taskByProjectAndStatus(this.projectId , this.taskstatus )
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                    if(data.length != undefined)
                {
                  this.nodata = true;
                }else{
                  this.nodata = false;
                }
                // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                ////this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }
            else if (this.selectedemployeeId == undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId != undefined  && this.taskstatus != undefined)
            {
              console.log("getTaskByProjectAndDatestatus");
              this.gs.getTaskByProjectAndDateAndStatus(this.projectId ,this.startDate ,  this.endDate ,this.taskstatus )
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = false;
            }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                ////this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }
            else if (this.selectedemployeeId == undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId == undefined  && this.taskstatus == undefined)
            {
              console.log("getTaskByDate");
              this.gs.getTaskByDate(this.startDate ,  this.endDate )
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = false;
            }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                ////this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }else if (this.selectedemployeeId == undefined  && this.startDate != undefined  && this.endDate != undefined && this.projectId == undefined  && this.taskstatus != undefined)
            {
              console.log("getTaskByDate");
              this.gs.getTaskByDateAndTaskStatus(this.startDate ,  this.endDate  , this.taskstatus)
              .subscribe((data:any)=>{
                console.log(data);
                this.timesheetreport =data;
                this.noDataMessage = data.message;
                console.log('this.timesheetreport',  this.timesheetreport);
                this.employeeTimeSheetData = data;
                if(data.length != undefined)
            {
              this.nodata = false;
            }else{
              this.nodata = false;
            }
               // this.tasktotalTime = this.timesheetreport[0].totalTime;
              },
              (error) => {
                this.nodata = true;
                ////this.showErrorMessage = true;;
                this.timesheetreport.pop();
                this.timesheetreport.splice(0, this.timesheetreport.length);
              });
            }


    // if( this.selectedemployeeId == undefined){
    //   if(this.startDate == undefined  && this.endDate == undefined ){
    //     this.gs.taskByProject
    //     (this.projectId)
    //     .subscribe((data:any)=>{
    //       console.log(data);
    //       this.timesheetreport = data;
    //       this.employeeTimeSheetData = data;
    //       console.log('this.timesheetreport',  this.timesheetreport);
    //       console.log('this.timesheetreport',  this.timesheetreport[0].empolyeeTasks[0].assignedhours);
    //       this.nodata = false;
    //      // this.tasktotalTime = this.timesheetreport[0].totalTime;
    //     },
    //     (error) => {
    //       this.nodata = true;
    //       this.showErrorMessage = false;
    //       this.timesheetreport.pop();
    //       this.timesheetreport.splice(0, this.timesheetreport.length);
    //     });
    //   }
    //   else{
    //     if(this.startDate == undefined  || this.endDate == undefined ){
    //       if(this.startDate == undefined){
    //         this.startdate_required = true;
    //       }
    //       else if(this.endDate==undefined ){
    //         this.enddate_required = true;
    //       }
    //       return
    //     }
    //     console.log(this.startDate);
    //     this.enddate_required = false;
    //     this.startdate_required = false;
    //     this.gs.getTaskProjectStartEndDate
    //     (this.projectId,this.startDate,this.endDate)
    //     .subscribe((data:any)=>{
    //       console.log(data);
    //       this.timesheetreport = data;
    //       console.log('this.timesheetreport',  this.timesheetreport);
    //       this.employeeTimeSheetData = data;
    //       this.nodata = false;
    //      // this.tasktotalTime = this.timesheetreport[0].totalTime;
    //     },
    //     (error) => {
    //       this.nodata = true;
    //       //this.showErrorMessage = true;;
    //       this.timesheetreport.pop();
    //       this.timesheetreport.splice(0, this.timesheetreport.length);
    //     });
    //   }
    // }
    // else{
    //   if(this.startDate ==undefined && this.endDate==undefined ){
    //     this.gs.selectingTaskByEmployee
    //     (this.selectedemployeeId,this.projectId)
    //     .subscribe((data:any)=>{
    //       console.log(data);
    //       this.timesheetreport = data;
    //       console.log('this.timesheetreport',  this.timesheetreport);
    //       this.employeeTimeSheetData = data;
    //       this.nodata = false;
    //      // this.tasktotalTime = this.timesheetreport[0].totalTime;
    //     },
    //     (error) => {
    //       this.nodata = true;
    //       //this.showErrorMessage = true;;
    //       this.timesheetreport.pop();
    //       this.timesheetreport.splice(0, this.timesheetreport.length);
    //     });
    //   }
    //   else {
    //     if(this.startDate == undefined  || this.endDate==undefined ){
    //       if(this.startDate == undefined){
    //         this.startdate_required = true;
    //       }
    //       else if(this.endDate==undefined){
    //         this.enddate_required = true;
    //       }
    //       return
    //     }
    //     this.enddate_required = false;
    //     this.startdate_required = false;
      
  //    }
    // }
  }

  

  @ViewChild('yourForm', { static: false }) yourForm: NgForm;

  resetForms() {
    this.yourForm.resetForm();
    // this.timesheetreport.length != 0;
    this.employeelist.length = 0;
    this.timesheetreport.length === 0;
    this.timesheetreport = [];
    this.orgemployeedata.forEach(element => {
    let employee = {
      employee: {
        employeeId : element.employeeId,
        employeeName : element.employeeName
      }
    
    }

 this.employeelist.push(employee);
  });
    // Add additional logic to reset other form-related properties if needed
  }
    
  datechange(event: any) {
    console.log(event);
    this.enddate_required = false;
    this.startdate_required = false;
  }
  withDrawStatus(i) {
    if (i.taskStatusName == null) {
      return;
    }
    if (i.taskStatusName == "To Do") {
      return "#ffc107";
    }
    if (i.taskStatusName == "Completed") {
      return "#28a745";
    }
    if (i.taskStatusName == "In Progress") {
      return "#f44336";
    }
  }

  approvalStatus(i) {
    if (i.approvalStatusName == null) {
      return;
    }
    if (i.approvalStatusName == "Pending") {
      return "#ffc107";
    }
    if (i.approvalStatusName == "Approved") {
      return "#28a745";
    }
    if (i.approvalStatusName == "Denied") {
      return "#f44336";
    }
  }

  // viewTimeSheet(e: any) {
  //   const dialogRef = this.dialog.open(TimeSheetComponent, {
  //     minWidth: "900px",
  //     minHeight: "500px",
  //     data: this.employeeTimeSheetData,
  //   });
  // }


  viewTimeSheet(e: any) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)'); // Adjust the breakpoint as needed
  
    console.log('eee',e);
    const dialogRef = this.dialog.open(TimeSheetComponent, {
      minWidth: isMobile ? '97vw' : '900px',
      minHeight: isMobile ? '30vh' : '500px',
      data:e,

    });
  }
   
      
  
  sortData(sort: Sort) {
    const data = this.timesheetreport.slice();
    if (!sort.active || sort.direction === "") {
      this.timesheetreport = data;
      return;
    }

    this.timesheetreport = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "employeeName":
          return this.compare(
            a.empolyeeTasks.employee.employeeName,
            b.empolyeeTasks.employee.employeeName,
            isAsc
          );
        case "projectName":
          return this.compare(
            a.project.projectName,
            b.project.projectName,
            isAsc
          );
        case "taskName":
          return this.compare(a.task.taskName, b.task.taskName, isAsc);
        case "taskStartDate":
          return this.compare(a.taskStartDate, b.taskStartDate, isAsc);
        case "taskEndDate":
          return this.compare(a.taskEndDate, b.taskEndDate, isAsc);
        case "workedHours":
          return this.compare(a.workedHours, b.workedHours, isAsc);
        case "taskStatusName":
          return this.compare(
            a.taskStatusName,
            b.taskStatusName,
            isAsc
          );
        case "approvalStatusName":
          return this.compare(
            a.approval.approvalStatusName,
            b.approval.approvalStatusName,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  
  changepage(){
    if(this.search.length != 0)
    {
        this.p = 1;
      }
      else{
      this.p = this.beforesearchpage;
    }
  }
}
