import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import * as jspdf from 'jspdf';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
// import html from 'htmlToPdfmake';
import {ActivatedRoute,Router} from '@angular/router';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.css']
})
export class PreviewFileComponent implements OnInit {

  myData:any = []
  userName
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  mombyIddata:any= {};

  constructor(
              // public dialogRef: MatDialogRef<PreviewFileComponent>,
              // private dialog:MatDialog,
              // @Inject(MAT_DIALOG_DATA) public data: any,
              public gs:GlobalserviceService,
              public ActivatedRoute:ActivatedRoute,
              public Router:Router
              ) { }

  ngOnInit(): void {
    this.mombyIddata.actionItem=[];
    console.log(this.ActivatedRoute.snapshot.params.id);
    this.gs.get_momById(this.ActivatedRoute.snapshot.params.id).subscribe(
      (a:any)=>{

      var days = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August','September','October','November','December'];
      var dato = new Date(a.momDate);
      var dayName = days[dato.getMonth()];
      a['date'] = dato.getDate()
      a['year'] = dato.getFullYear()
      a['month'] = dayName.slice(0, 3)

      if(a.actionItem){
        a.actionItem.map(
          e=>{
            if(e.actionItemName == ''){
              delete a.actionItem;
            }
          }
        )
      }
  
      //this.myData = this.data;
      //console.log(this.myData);

      this.mombyIddata = a;
      console.log(this.mombyIddata);

    });

  }

  exportPDF(fileName){
    var data = document.getElementById('pdfTable');
    // html2canvas(data).then(canvas => {
    //   // Few necessary setting options
      // var imgWidth = 208;
      // var pageHeight = 295;
      // var imgHeight = canvas.height * imgWidth / canvas.width;
      // var heightLeft = imgHeight;

    //   const contentDataURL = canvas.toDataURL('image/png')
    //   let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdf.save(fileName); // Generated PDF
    // });

      // let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF

      // pdf.save(fileName); // Generated PDF
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download("Minutes_of_Meeting.pdf"); 

  }
  print(fileName){
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML,{tableAutoSize:true});
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).print(); 
  }
  close(){
    this.Router.navigate(['./MOM-report'])
  }
}

function html2canvas(data: HTMLElement) {
  throw new Error('Function not implemented.');
}

