<div class="container">
  <h4 class="material-icons float-right" style="color:red;"  align="end" mat-dialog-close>
      cancel
  </h4>
  <h4>Assign Approvers</h4>
  <form [formGroup]="projectApproverForm" (ngSubmit)="Addprojectapprover()">
    <!-- Task Type -->
    <div class="input-width" id="loginform">
      <label class="form-control-label">Project<span class="text-danger">*</span></label>
      <mat-select formControlName="project" 
      class="form-control email-input select-placeholder"
      placeholder="Select Project"
        [disabled]="projectreadonly"  
        (selectionChange) = "projectapprovervalidate()">
        <mat-option *ngFor="let project of projectList" [value]="project.projectId">
          {{project.projectName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.project.invalid && submitted1">Project is required</mat-error>
    </div>
    <span *ngIf="projectapproverexist"  class="span-error"> Approver Already Exist</span>
    <!-- Task Type -->
    <div  class="input-width"  id="loginform">
        <label  class="form-control-label">Approver<span class="text-danger">*</span></label>
        <mat-select formControlName="approver"
          placeholder="Select Approver Rule"
          class="form-control email-input select-placeholder">
          <mat-option *ngFor="let approver of ApproverList" [value]="approver.approverId">
            {{approver.rulename}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.approver.invalid && submitted1">Approver is required</mat-error>
    </div>
    <div mat-dialog-actions align="end">
      <button
        class="btn create-button"
        type="submit"
        [disabled]="projectApproverForm.invalid"
        cdkFocusInitial
        style="margin-bottom:15px;"
      >
      {{data.projectApproverId ? 'Update': 'Create'}}
      <!-- Create -->
      </button>
    </div>
  </form>
</div>