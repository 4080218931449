<html>
    <body class="container-fluid  d-flex h-100 login-background">     
        <div class="card-body login-card-body card-background col-md-5 align-self-center">
            <form [formGroup]="newpasswordForm" (ngSubmit)="submitData()">                  
                <div class="col-md-12 content">
                    <!-- <img src="./assets/logo.png" height="50px" width="130px"> -->
                    <h1 class="login-title">New Password</h1>
                </div>                                            
                
                <div class="row">                                                     
                    <div class="col-md-12" id="loginform">
                        <label class="form-control-label">New Password<span class="text-danger"> *</span></label>
                        <input type="password" class="form-control email-input select-placeholder" formControlName="newpassword" placeholder="Enter new password">
                        <div *ngIf="(f.newpassword.invalid && f.newpassword.touched) || f.newpassword.dirty">
                            <small *ngIf="f.newpassword.errors?.required" class="text-danger">Please enter new password</small>
                            <small *ngIf="f.newpassword.errors?.minlength" class="text-danger">Password must be at least 6 characters</small>
                        </div> 
                    </div>
                </div> 
               <div class="row">  
                <div class="col-md-12" id="loginform">
                    <label class="form-control-label" style="margin-top: 5px;">Confirm New Password<span class="text-danger"> *</span></label>
                    <input type="password" class="form-control email-input select-placeholder" formControlName="confirm_newpassword" placeholder="Confirm new password">
                    <div *ngIf="(f.confirm_newpassword.invalid && f.confirm_newpassword.touched) ||f.confirm_newpassword.dirty">
                        <small *ngIf="f.confirm_newpassword.errors?.required" class="text-danger">Please enter confirm new password</small>
                        <small *ngIf="f.confirm_newpassword.errors?.mustMatch" class="text-danger">Passwords must match</small>
                    </div> 
                </div>
               </div>
                <!-- <a routerLink="/resend" class="Resend" >Resend OTP</a> -->
                <div class="row">    
                    <div class="col-md-12 text-center login-button">
                        <Button type="submit" [disabled]="newpasswordForm.invalid" class="btn btn-block login-button-text">Submit</Button>
                    </div>
                </div>
                <!-- <div>
                    <p class="text-danger text-center mt-3 mb-0">{{error_msg}}</p>
                </div> -->
            </form>  
        </div>
    </body>
</html>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
<div *ngIf="loader" class="align-items-center text-center align-self-center"  style="position: relative;bottom: 54vh; z-index: 2;">
    <div class="spinner-border align-self-center" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<div class="text-center" *ngIf="success"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_error_msg}}
</div>


