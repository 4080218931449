import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {

 constructor(private auth: AuthService, private router: Router) {}
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
     if(localStorage.getItem('UserData')) {
       return true;
     }
     this.router.navigate(['/login'], );
     return false;
  }


}
