<div class="container-fluid">
  <!-- <div class="row m-0">
    <div  class="col-md-6 mb-1" *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'">
      <h4 class="m-0">Minutes Of Meeting</h4>
      <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">Action Item Report</span>
    </div>
    <div class="col-md-6 mb-1" *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'">
      <h4 class="m-0">Minutes Of Meeting</h4>
      <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Action Item Report</span>
    </div> 
    <div>
      <div class="example-width">
        <ul class="unstyled inbox-pagination">
          <div class="sidebar-search">
            <div class="input-group">
              <input type="text" 
                class="form-control search-menu" 
                placeholder="Search..." 
                [(ngModel)]="search"  
                
                name="searchText">
              <div class="material-icons-outlined search-icon">search</div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div> -->

  <!--  -->
  <div class="top-content row">
    <div class="col-md-8" *ngIf="User_Type != 'HumanResource' && User_Type != 'Manager'">
      <h4>Minutes Of Meeting</h4>
      <!-- <span class="span-content sub-cont" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span class="sub-cont1">Action Item Report</span> -->
      <span style="font-weight:550;font-size: 13px;" [routerLink]="['/employee-dashboard']">Dashboard</span> / <span style="color: gray;font-size: 13px;">Action Item Report</span>
    </div>
    <div class="col-md-8" *ngIf="User_Type == 'HumanResource' || User_Type == 'Manager'">
      <h4>Minutes Of Meeting</h4>
      <!-- <span class="span-content sub-cont"  [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Action Item Report</span> -->
      <span style="font-weight:550;font-size: 13px;" [routerLink]="['/admin-dashboard']">Dashboard</span> / <span style="color: gray;font-size: 13px;">Action Item Report</span>
    </div>
    <div class="col-md-4">
      <div class="example-width">
        <ul class="unstyled inbox-pagination">
          <div class="sidebar-search">
            <div class="input-group">
              <input type="text" 
                class="form-control search-menu" 
                placeholder="Search..." 
                [(ngModel)]="search"
                (keyup) = "changepage()"  
                name="searchText">
              <div class="material-icons-outlined search-icon">search</div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <!--  -->
    <div class="row m-0">
      <div class="col-md-3" id="space1">
        <label class="form-control-label">Project<span class="text-danger"> *</span></label>
        <mat-select id="framework" class="form-control email-input select-placeholder" [(ngModel)]="projectStatus" placeholder="Select Project">
          <mat-option>-Select Project-</mat-option>
          <mat-option value="non-project">Non Project</mat-option>
          <mat-option *ngFor="let pro of projectList" [value]="pro.projectId">
            {{pro.projectName}}
          </mat-option>
      </mat-select>
      </div>
      <div class="col-md-3" id="space2">
        <label class="form-control-label">Action Status<span class="text-danger"> *</span></label>
        <mat-select class="form-control email-input select-placeholder" [(ngModel)]="actionStatus" placeholder="Select Action Status">
          <mat-option>-Select Action Status-</mat-option>
          <mat-option value="Pending">Pending</mat-option>
          <mat-option value="Completed">Completed</mat-option>
        </mat-select>
      </div>
      <!-- <div class="col-md-4"></div> -->
      <div class="col-md-2" id="space3">
        <label class="form-control-label">Start Date<span class="text-danger"> *</span></label>
        <input matInput [matDatepicker]="picker1" class="form-control email-input select-placeholder" type="text" [(ngModel)]="startDate" (dateChange)="dateChange($event)" placeholder="Select Date"
        id="date">
        <mat-datepicker-toggle matSuffix [for]="picker1" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <!-- <span class="error_msg" *ngIf="startdate_required">Please select startdate</span>   -->
      </div>
      <div class="col-md-2" id="space4">
        <label class="form-control-label">End Date<span class="text-danger"> *</span></label>
        <input matInput [matDatepicker]="picker2" class="form-control email-input select-placeholder" type="text" [(ngModel)]="endDate" [min]="startDate"  (dateChange)="dateChange($event)" placeholder="Select Date"
        id="date">
        <mat-datepicker-toggle matSuffix [for]="picker2" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker> 
        <!-- <span class="error_msg" *ngIf="enddate_required">Please select enddate</span> -->
        <span class="error_msg" *ngIf="errorMsg" style="color:red;font-size:12px;">Invalid Enddate</span>
      </div>
      <div class="col-md-2" id="space5">
        <button type="button" mat-raised-button  class="searchBtn btn create-button" (click)="searchTask()">Search</button>
      </div>
      
    </div>
  
    <mat-card class="card-content" *ngIf="actionItemList.length>0">
      <div class="row p-0 m-0" *ngIf="actionItemList.length>5">
        <div class="col-xl-12 col-lg-12">
            <div class="select-pagination">
                <span>Show : </span>
                <select class="select_list new-select_list" [(ngModel)]="mySelect" (change)="showChanges()">
                    <option class="form-control" value="5"> 5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                </select>
            </div>
        </div>  
      </div>
      <!-- <ng-container *ngIf="actionItemList.length>0;else elseTemplate"> -->
        <div  class="table-responsive" style="margin-top:0px;">
          <table class="table"  matSort (matSortChange)="sortData($event)">
            <thead>
              <tr>
                <th style="text-align: center;" scope="col">S.No</th>
                <th mat-sort-header="actionItemName">Item Name</th>
                <th mat-sort-header="actionItemTargetDate">Target Date</th>
                <th mat-sort-header="actionItemOwner">Owner</th>
                <th>Project Name</th>
                <th style="text-align: center;">Action Status</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody> 
              <tr *ngFor="let dato of actionItemList| actionitem:search | paginate:{itemsPerPage:mySelect,currentPage:p};  let i = index">
                <td *ngIf="p" style="text-align: center;" >{{((p-1)*mySelect)+(i+1)}}</td>
                <td style="text-align: center;" *ngIf="!p">{{i+1}}</td>
                <td>{{dato.actionItemName}}</td>
                <td>{{dato.actionItemTargetDate }}</td>
                <td>{{dato.actionItemOwner}}</td>
                <td *ngIf="!dato.mom.project">--</td>
                <td *ngIf="dato.mom.project">{{dato.mom.project.projectName}}</td>
                <td style="color:green;text-align: center;">{{dato.actionItemStatus}}</td>
                <td style="text-align: center;">
                  <span class="material-icons" (click)="action(dato)">edit</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-content" *ngIf="actionItemList.length>5" id="page">
          <pagination-controls (pageChange)="p = $event" align="end" ></pagination-controls>
        </div>
      <!-- </ng-container> -->
      <!-- <ng-template #elseTemplate>
        <div class="alert alert-primary" *ngIf="noData">
          <h5 class="text-center"> Action Items Not Found</h5>
        </div>
      </ng-template> -->
    </mat-card>
    <mat-card class="card-content" *ngIf="noData">
      <div class="card-body">
        <!-- <ng-template #elseProject> -->
          <div class="alert alert-primary">
            <h4 align="center">Action Items Not Found</h4>
          </div>
        <!-- </ng-template> -->
    </div>
    </mat-card>
    <!-- <ng-template #elseTemplate>
      <div class="alert alert-primary" *ngIf="noData">
        <h5 class="text-center"> Action Items Not Found</h5>
      </div>
    </ng-template> -->
  
    <!-- <div class="pagination-content">
      <pagination-controls (pageChange)="p = $event" align="end" *ngIf="actionItemList.length>5"></pagination-controls>
    </div> -->

  </div>
  
