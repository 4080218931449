<div class="card card-content">
    <h4>Assign Employee Task</h4>
    <div class="row justify-content-between">
        <div class="col-md-8">
          <div id="loginform">
            <div class="col-md-6">
              <label class="form-control-label">Project Assign Type<span class="text-danger">*</span></label>
              <mat-select (selectionChange)="selected($event.value)" class="form-control email-input select-placeholder"
                          placeholder="Select project assign type">
                <mat-option value="employee">Assign by Task</mat-option>
                <mat-option value="task">Assign by Employee</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="">
          <button mat-raised-button type="submit" (click)="importEmployee()" class="btn create-button btn-res mr-3 button">Import</button>
        </div>
      </div>
      
    <div *ngIf="task_by_employee">
        <form [formGroup]="assignformbyemployee">
            <div class="row">
                <div class="col-md-4">
                    <div id="loginform">
                        <label class="form-control-label">Select Project<span class="text-danger">*</span></label>
                        <mat-select 
                            class="form-control email-input select-placeholder"
                            formControlName="projectname" 
                            placeholder="Select project">
                            <mat-option *ngFor="let project of projectList" 
                                [value]="project.projectId"  
                                (click)="projectData(project)">
                                {{project.projectName}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(submitted1 && projectname.invalid)">
                            <small *ngIf="projectname.errors?.required" class="text-danger">Please select project</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div id="loginform">
                        <label class="form-control-label">Select Task<span class="text-danger">*</span></label>
                        <mat-select 
                            class="form-control email-input select-placeholder"
                            formControlName="taskname" 
                            placeholder="Select task">
                            <mat-option *ngFor="let task of taskList"
                            [value]="task.taskId"
                            (click)="taskData(task)">{{task.taskName}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(submitted1 && taskname.invalid) || taskname.dirty">
                            <small *ngIf="taskname.errors?.required" class="text-danger">Please select task name</small>
                        </div>
                        <!--<span *ngIf="projecttaskexist" class="span-error"> Task already exist</span> --> 
                    </div>
                </div>
                <div class="col-md-4">
                    <button mat-raised-button type="submit" 
                        (click)="addRow()"
                        class="btn create-button float-right m-2 btn-res"
                        >Add Row</button>
                </div>
            </div> 
            <div class="table-responsive" style="max-height: 275px;overflow: auto;">
                <table class="table table-border table-responsive">
                    <thead>
                        <th>Employee<span class="text-danger">*</span><span class="resizer"></span></th>
                        <th>Start Date<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>End Date<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>Hours<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>Minutes<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls ; let i=index">
                            <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                                <td>
                                    <mat-select 
                                        class="form-control email-input select-placeholder"
                                        formControlName="employee"
                                        placeholder="Select employee">
                                        <mat-option *ngFor="let employee of employeeProjects" 
                                            [value]="employee.employee.employeeId">{{employee.employee.employeeName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="(submitted1 && group['controls'].employee.invalid) || group['controls'].employee.dirty">
                                        <small *ngIf="group['controls'].employee.errors?.required" class="text-danger">Please select employee</small>  
                                    </div>                            
                                </td>
                                <td>
                                    <div>
                                        <input                                          
                                            formControlName="startdate"
                                            placeholder="Select start date"      
                                            (change)="mintaskendDatenew($event , i)"                                                     
                                            [disabled]="startDate_disable" 
                                            type="date"
                                            [readonly] =  "startDate_disable"            
                                            [min]="min"
                                            [max]="max"                                    
                                            class="form-control email-input select-placeholder" >                                       
                                        <div *ngIf="(submitted1 && group['controls'].startdate.invalid) || group['controls'].startdate.dirty">
                                            <small *ngIf="group['controls'].startdate.errors?.required" class="text-danger">Please select start date</small>  
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                     <input 
                                            formControlName="enddate"
                                            placeholder="Select end date"
                                              type="date"
                                            [disabled]="endDate_disable" 
                                            [readonly] =  "endDate_disable"    
                                            [max]="max"               
                                            [attr.id]="'TenddtPicker' + i"
                                            class="form-control email-input select-placeholder" >                                     
                                        <!-- <mat-datepicker-toggle matSuffix [for]="picker1"
                                        style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle> -->
                                        <div *ngIf="(submitted1 && group['controls'].enddate.invalid) || group['controls'].enddate.dirty">
                                            <small *ngIf="group['controls'].enddate.errors?.required" class="text-danger">Please select End date</small>  
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input type="text" 
                                            formControlName="hours"  
                                            placeholder="Enter hours"
                                            (keypress)="noNumber($event)"
                                            maxlength="3"
                                            class="form-control email-input select-placeholder" >
                                            <div *ngIf="(submitted1 && group['controls'].hours.invalid) || group['controls'].hours.dirty">
                                                <small *ngIf="group['controls'].hours.errors?.required" class="text-danger">Please enter hours</small>  
                                            </div> 
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input type="text" 
                                            formControlName="minutes"
                                            placeholder="Enter minutes"
                                            maxlength="2"
                                            (keypress)="noNumber($event)"
                                            class="form-control email-input select-placeholder" >
                                            <div *ngIf="(submitted1 && group['controls'].minutes.invalid) || group['controls'].minutes.dirty">
                                                <small *ngIf="group['controls'].minutes.errors?.required" class="text-danger">Please enter minutes</small>  
                                            </div> 
                                    </div>
                                </td>
                                <td style="text-align: center;">
                                    <!-- <mat-icon class="done" (click)="doneRow(group)">done</mat-icon> -->
                                    <mat-icon class="delete" (click)="deleteRow(i)">delete</mat-icon>
                                </td>
                            </tr>
                            <tr *ngIf="!group.get('isEditable').value" >
                                <td>
                                    {{group.get('employee').value}}
                                </td>
                                <td>
                                    {{group.get('startdate').value}}
                                </td>
                                <td>
                                    {{group.get('enddate').value}}
                                </td>
                                <td  style="text-align: center;">
                                    <mat-icon class="edit material-icons-outlined" 
                                    (click)="editRow(group)">edit</mat-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="action-container">
                <button mat-raised-button class="btn create-button" 
                    style="float: right;color:white;margin-top: 15px;" 
                    type="submit" 
                    (click)="submitForm()">Submit</button>
            </div>
        </form>
    </div>
    <div *ngIf="employee_by_task">
        <form [formGroup]="assignformbytask">
            <div class="row">
                <div class="col-md-4">
                    <div id="loginform">
                        <label class="form-control-label">Select Project<span class="text-danger">*</span></label>
                        <mat-select 
                            class="form-control email-input select-placeholder"
                            formControlName="projectname1" 
                            placeholder="Select project">
                            <mat-option *ngFor="let project of projectList" 
                                [value]="project.projectId"  
                                (click)="projectData(project)">
                                {{project.projectName}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(submitted2 && projectname1.invalid) || projectname1.dirty">
                            <small *ngIf="projectname1.errors?.required" class="text-danger">Please select project</small>
                        </div>                                 
                    </div>
                </div>
                <div class="col-md-4">
                    <div id="loginform">
                        <label class="form-control-label">Select Employee<span class="text-danger">*</span></label>
                        <mat-select 
                            class="form-control email-input select-placeholder"
                            formControlName="employee1"
                            placeholder="Select employee">
                            <mat-option *ngFor="let employee of employeeProjects" 
                                [value]="employee.employee.employeeId">{{employee.employee.employeeName}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(submitted2 && employee1.invalid) || employee1.dirty">
                            <small *ngIf="employee1.errors?.required" class="text-danger">Please select employee</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <button mat-raised-button type="button" 
                        (click)="addRow1()"
                        class="btn create-button float-right m-2 btn-res">Add Row</button>
                </div>
            </div>   
            <div class="table-responsive" style="max-height: 275px;overflow: auto;">
                <table class="table table-border table-responsive">
                    <thead>
                        <th>Task<span class="text-danger">*</span><span class="resizer"></span></th>
                        <th>Start Date<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>End Date<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>Hours<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>Minutes<span class="text-danger">*</span><span class="resizer1"></span></th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <ng-container formArrayName="tableRows1" *ngFor="let group of getFormControls1.controls ; let i=index">
                        <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                            <td id="date">
                                <mat-select 
                                    class="form-control email-input select-placeholder"
                                    formControlName="taskname1" 
                                    placeholder="Select task">
                                    <mat-option *ngFor="let task of taskList"
                                    [value]="task.taskId"
                                    (click)="taskDataBytask(task ,group['controls'] , i )">{{task.taskName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="(submitted2 && group['controls'].taskname1.invalid) || group['controls'].taskname1.dirty">
                                    <small *ngIf="group['controls'].taskname1.errors?.required" class="text-danger">Please select taskname</small>  
                                </div>  
                            </td>
                            <td id="date">
                                <div>
                                    <!-- <input 
                                        [matDatepicker]="picker" 
                                        readonly
                                        formControlName="startdate1"
                                        placeholder="Select start date"      
                                        (dateChange)="mintaskendDate($event)"
                                        [min]="'min" [max]="max"                 
                                        [disabled]="startDate_disable" 
                                        readonly
                                        [attr.id]="'dtPicker' + i"
                                        class="form-control email-input select-placeholder" >
                                    <mat-datepicker-toggle matSuffix [for]="picker"
                                    style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker> -->
                                    <input                      
                                    
                                    formControlName="startdate1"
                                    placeholder="Select start date"      
                                    (change)="mintaskendDate($event ,i)"      
                                    [disabled]="startDate_disable"      
                                    [readonly] =  "startDate_disable"            
                                    [attr.id]="'dtPicker' + i"
                                    type="date"
                                    class="form-control email-input select-placeholder" >
                                    <div *ngIf="(submitted2 && group['controls'].startdate1.invalid) || group['controls'].startdate1.dirty">
                                        <small *ngIf="group['controls'].startdate1.errors?.required" class="text-danger">Please select start date</small>  
                                    </div>
                                </div>
                            </td>
                            <td id="date">
                                <div >
                                    <input 
                                    
                                        formControlName="enddate1"
                                        placeholder="Select end date"                                  
                                        [disabled]="endDate_disable" 
                                        [readonly] =  "endDate_disable"       
                                        [attr.id]="'enddtPicker' + i"
                                        type="date"
                                        class="form-control email-input select-placeholder" >   
                                    <div *ngIf="(submitted2 && group['controls'].enddate1.invalid) || group['controls'].enddate1.dirty">
                                        <small *ngIf="group['controls'].enddate1.errors?.required" class="text-danger">Please select end date</small>  
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" 
                                        formControlName="hours1"  
                                        placeholder="Enter hours"
                                        (keypress)="noNumber($event)"
                                        maxlength="3"
                                        class="form-control email-input select-placeholder" >
                                        <div *ngIf="(submitted2 && group['controls'].hours1.invalid) || group['controls'].hours1.dirty">
                                            <small *ngIf="group['controls'].hours1.errors?.required" class="text-danger">Please enter hours</small>  
                                        </div> 
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" 
                                        formControlName="minutes1"
                                        placeholder="Enter minutes"
                                        maxlength="2"
                                        (keypress)="noNumber($event)"
                                        class="form-control email-input select-placeholder" >
                                        <div *ngIf="(submitted2 && group['controls'].minutes1.invalid) || group['controls'].minutes1.dirty">
                                            <small *ngIf="group['controls'].minutes1.errors?.required" class="text-danger">Please enter minutes</small>  
                                        </div> 
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <!-- <mat-icon class="done" (click)="doneRow1(group)">done</mat-icon> -->
                                <mat-icon class="delete" (click)="deleteRow1(i)">delete</mat-icon>
                            </td>
                        </tr>
                        <tr *ngIf="!group.get('isEditable').value" >
                            <td>
                                {{group.get('taskname1').value}}
                            </td>
                            <td>
                                {{group.get('startdate1').value}}
                            </td>
                            <td>
                                {{group.get('enddate1').value}}
                            </td>
                            <td style="text-align: center;">
                                <mat-icon class="edit material-icons-outlined" 
                                (click)="editRow1(group)">edit</mat-icon>
                            </td>
                        </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="action-container">
                <button mat-raised-button 
                    class="btn create-button" 
                    style="float: right;color:white;margin-top: 15px;" 
                    type="submit" 
                    (click)="submitForm1()">Submit</button>
            </div>
        </form>
    </div>
</div>


<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing" style="height: 527vh!important;"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border table-spinner" role="status"></div>
</div>

<div class="text-center" *ngIf="success">
    <div class="alert alert-success toast-msg" role="alert">
        <strong>Success!</strong><br>{{sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
    <strong>Error!</strong><br>{{error_msg}}
    <button *ngIf="importerror"  (click)="showerrormessage()"  class="btn btn-danger">View Error Records
    </button>
</div>