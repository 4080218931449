import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-time-settings',
  templateUrl: './time-settings.component.html',
  styleUrls: ['./time-settings.component.css']
})
export class TimeSettingsComponent implements OnInit {
  taskForm: FormGroup;
  maxWorkHours:any;
  organzationid :any;
  getdata:any;
  organizationEmail:any;
  workhours:any;

  constructor(private gs: GlobalserviceService,
    private route: ActivatedRoute,
    private router: Router) { 
      const data = JSON.parse(localStorage.getItem('UserData'))
      console.log(data);
      this.organizationEmail = data.organizationEmail;
      this.organzationid = data.organization.organizationId;
      console.log("organzationid" +this.organzationid);
    }

  ngOnInit(): void {
    this.taskForm = new FormGroup(
      {
        maxWorkHours : new FormControl('',[Validators.required,Validators.pattern(/^[0-9]*$/)])
      })
    this.gs.Getmaxworkhours().subscribe(
      (x:any)=>{
        console.log(x);
        this.getdata=x;
        this.taskForm.patchValue({
          maxWorkHours: this.getdata.maxWorkHours,
        })
        this.maxWorkHours = this.getdata.maxWorkHours;
        console.log(this.maxWorkHours);
      }
    )
  }
  get f() {
    return this.taskForm.controls;
  }
  WorkHours(e){
    this.workhours = e.target.value;
  }
  onSubmit() {
    if (this.taskForm.invalid) {
      return
    }
    var maxWorkHours =
    { 
      maxWorkHours : this.taskForm.value.maxWorkHours, 
      maxWorkHoursId: this.getdata.maxWorkHoursId,
      maxWorkHoursStatus : this.getdata.maxWorkHoursStatus,
      organization:{organizationId:this.getdata.organization.organizationId,
        organizationName:this.getdata.organization.organizationName
      },
    };
    console.log(maxWorkHours);
    this.gs.updatemaxworkhours(this.organzationid,maxWorkHours).subscribe(
      (data:any) =>{
        console.log(data);
        Swal.fire({
          text:data.message,
          icon:'success'
        })
       window.location.reload()
      },
      err=>{
       Swal.fire({
         text:err.error,
         icon:'error'
       })
      }
    )
   }
  }
