import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-update-mom',
  templateUrl: './update-mom.component.html',
  styleUrls: ['./update-mom.component.css']
})
export class UpdateMomComponent implements OnInit {

  submitted:boolean = false;
  myForm: FormGroup;
  @ViewChild('select') select: MatSelect;

  allSelected=false;
  employeeList:any = []
  myProject = [
    {
      id:1,
      value:'Project'
    },
    {
      id:2,
      value:'Non Project'
    }
  ]
  momId:number
  projectList:any = []
  isShow:Boolean = false;
  mombyId: any = [];
  deletedDiscussedpoints: any=[];
  discussedpoints: any;
  deletedactionItems: any =[];
  actionItems:any;
  deletedpointsAgreed: any =[];
  pointsAgreed:any;
  customerList:any
  momdate: any;
  modifiedmomdate: string;
  modifiedTargetDate: string;
  tomorrow = new Date();

  constructor(private gs:GlobalserviceService,
    public dialogRef: MatDialogRef<UpdateMomComponent>,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder,
    private router:Router,
    private datepipe : DatePipe,) { }

  ngOnInit() {
    console.log(this.myProject);
    console.log(this.data);
    const myData = JSON.parse(localStorage.getItem('UserData'));
    console.log(myData);
    this.momId = this.data.momId;
    // this.gs.get_projects().subscribe(
    //   (a:any)=>{
    //     this.projectList = a
    //   }
    // )

    this.gs.getEmpProjectList(myData.employeeId)
    .subscribe((pa:any)=>{
      this.projectList = pa;
    });

    this.gs.Get_Customer().subscribe((x:any)=>{
        this.customerList = x;
        console.log(this.customerList);
      })

    this.myForm = this.fb.group({
      momName:['',Validators.required],
      momAttendees:['',Validators.required],
      client:['',Validators.required],
      meetingPlace:['',Validators.required],
      momDate:['',Validators.required],
      projectValue:['',Validators.required],
      project:[''],
      pointsDiscussed: this.fb.array([
       // this.newContent()
      ]),
      pointsAgreed:this.fb.array([
       // this.newAgree()
      ]),
      actionItem:this.fb.array([
        // this.fb.group({
        //   actionItemId:[''],
        //   actionItemName: [''],
        //   actionItemTargetDate:[''],
        //   actionItemOwner:['']
        // })
      ])

    });

    if(this.data){
      console.log("momid");
      this.gs.get_momById(this.data).subscribe(
        (a:any)=>{
          this.mombyId = a
          console.log(this.mombyId);

          if(this.mombyId.project){
            this.isShow = true;
            this.myForm.patchValue({
              momName:this.mombyId.momName,
              momAttendees:this.mombyId.momAttendees,
              momDate:this.mombyId.momDate,
              client:this.mombyId.client,
              projectValue:1,
              project:this.mombyId.project.projectId,
              meetingPlace:this.mombyId.meetingPlace,
              //pointsDiscussed:this.mombyId.pointsDiscussed,
              ///actionItem:this.mombyId.actionItem,
              //pointsAgreed:this.mombyId.pointsAgreed,
            });
            const pointsDiscussedcontrol = this.myForm.get('pointsDiscussed') as FormArray;
            this.mombyId.pointsDiscussed.forEach((element,index)=> {
              this.add();
              pointsDiscussedcontrol.at(index).patchValue({
                pointsDiscussedId:element.pointsDiscussedId,
                pointsDiscussedDetails:element.pointsDiscussedDetails,
                status:element.status
              });         
            });
            const pointsAgreedcontrol = this.myForm.get('pointsAgreed') as FormArray;
            this.mombyId.pointsAgreed.forEach((element,index)=> {
              this.addAgree();
              pointsAgreedcontrol.at(index).patchValue({
                pointsAgreedId:element.pointsAgreedId,
                pointsAgreedDetails:element.pointsAgreedDetails,
                status:element.status
              });             
            });
            if(this.mombyId.actionItem.length != 0)
            {
              const actionItemcontrol = this.myForm.get('actionItem') as FormArray;
              this.mombyId.actionItem.forEach((element,index)=> {
                this.addItem();
                actionItemcontrol.at(index).patchValue({
                  actionItemId:element.actionItemId,
                  actionItemName:element.actionItemName,
                  actionItemOwner:element.actionItemOwner,
                  actionItemTargetDate:element.actionItemTargetDate,
                  actionItemStatus:element.actionItemStatus,
                  status:element.status
                });             
              });
            }
          }
          else{
            console.log("else");
            this.myForm.patchValue({
              momName:this.mombyId.momName,
              momAttendees:this.mombyId.momAttendees,
              momDate:this.mombyId.momDate,
              client:this.mombyId.client,
              projectValue:2,
              project:'',
              meetingPlace:this.mombyId.meetingPlace,
              // pointsDiscussed:this.mombyId.pointsDiscussed,
              // actionItem:this.mombyId.actionItem,
              // pointsAgreed:this.mombyId.pointsAgreed
            })
            const pointsDiscussedcontrol = this.myForm.get('pointsDiscussed') as FormArray;
            console.log(this.mombyId.pointsDiscussed.length);
            this.mombyId.pointsDiscussed.forEach((element,index)=> {
              console.log(index);
              this.add();
              pointsDiscussedcontrol.at(index).patchValue({
                pointsDiscussedId:element.pointsDiscussedId,
                pointsDiscussedDetails:element.pointsDiscussedDetails,
                status:element.status
              });             
            });

            const pointsAgreedcontrol = this.myForm.get('pointsAgreed') as FormArray;
            this.mombyId.pointsAgreed.forEach((element,index)=> {
              this.addAgree();
              pointsAgreedcontrol.at(index).patchValue({
                pointsAgreedId:element.pointsAgreedId,
                pointsAgreedDetails:element.pointsAgreedDetails,
                status:element.status
              });             
            });
            if(this.mombyId.actionItem.length != 0)
            {
              const actionItemcontrol = this.myForm.get('actionItem') as FormArray;
              this.mombyId.actionItem.forEach((element,index)=> {
                this.addItem();
                actionItemcontrol.at(index).patchValue({
                  actionItemId:element.actionItemId,
                  actionItemName:element.actionItemName,
                  actionItemOwner:element.actionItemOwner,
                  actionItemTargetDate:element.actionItemTargetDate,
                  actionItemStatus:element.actionItemStatus,
                  status:element.status
                });             
              });
            }
          }
        }
      )
    }



    //  if(this.momId){
    //    if(this.data.project){
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       momDate:['',Validators.required],
    //       project:{
    //         projectId:Number(this.data)
    //       },
    //       content: this.fb.array([
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemId:[''],
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //     this.myForm.patchValue({
    //       momName:this.data.momName,
    //       momAttendees:this.data.momAttendees,
    //       momDate:this.data.momDate,
    //       client:this.data.client,
    //       project:{
    //         projectId:this.data.project.projectId,
    //       },
    //       meetingPlace:this.data.meetingPlace,
    //       content:  this.data.content.forEach(
    //         e=>{
    //           this.content().push(
    //             this.fb.group({
    //               contentId:e.contentId,
    //               contentTitle: e.contentTitle,
    //               subContent: this.setProjects(e)
    //             })
    //           )
    //         }
    //       ),
    //       actionItem:this.data.actionItem
    //     })
    //    }
    //    else{
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       momDate:['',Validators.required],
    //       content: this.fb.array([
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemId:[''],
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //     this.myForm.patchValue({
    //       momName:this.data.momName,
    //       momAttendees:this.data.momAttendees,
    //       momDate:this.data.momDate,
    //       client:this.data.client,
    //       meetingPlace:this.data.meetingPlace,
    //       content:  this.data.content.forEach(
    //         e=>{
    //           this.content().push(
    //             this.fb.group({
    //               contentId:e.contentId,
    //               contentTitle: e.contentTitle,
    //               subContent: this.setProjects(e)
    //             })
    //           )
    //         }
    //       ),
    //       actionItem:this.data.actionItem
    //     })
    //    }
    //   // this.employeeList = this.data.momAttendees.split(',')


    // }
    // else{
    //   if(this.data == ''){
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       momDate:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       content: this.fb.array([
    //         this.newContent()
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //   }
    //   else{
    //     this.myForm = this.fb.group({
    //       momName:['',Validators.required],
    //       momAttendees:['',Validators.required],
    //       momDate:['',Validators.required],
    //       client:['',Validators.required],
    //       meetingPlace:['',Validators.required],
    //       project:{
    //         projectId:Number(this.data)
    //       },
    //       content: this.fb.array([
    //         this.newContent()
    //       ]),
    //       actionItem:this.fb.array([
    //         this.fb.group({
    //           actionItemName: ['',Validators.required],
    //           actionItemTargetDate:['',Validators.required],
    //           actionItemOwner:['',Validators.required]
    //         })
    //       ])
    //     });
    //   }

    // }
  }

  dateChange(event) {
    console.log(event.value);
    this.momdate = event.value;
  }
  setProjects(x) {
    let arr = new FormArray([]);
    x.subContent.forEach(y => {
      arr.push(
        this.fb.group({
          subContentId:y.subContentId,
          subContentTitle: y.subContentTitle
        })
      );
    });
    return arr;
  }


  content(): FormArray {
    return this.myForm.get('pointsDiscussed') as FormArray;
  }
  agree(): FormArray {
    return this.myForm.get('pointsAgreed') as FormArray;
  }
  actionItem(): FormArray {
    return this.myForm.get('actionItem') as FormArray;
  }

  newContent(): FormGroup {
    return this.fb.group({
      pointsDiscussedId : [''],
      pointsDiscussedDetails: ['',Validators.required],
      status:1
      //subContent: this.fb.array([])
    });
  }

  newAgree(): FormGroup {
    return this.fb.group({
      pointsAgreedId : [''],
      pointsAgreedDetails: ['',Validators.required],
      status:1
      //agreeTitle: ['',Validators.required],
    });
  }

  newAction(): FormGroup {
    return this.fb.group({
      actionItemId : [''],
      actionItemName: ['',Validators.required],
      actionItemTargetDate:['',Validators.required],
      actionItemOwner:['',Validators.required],
      actionItemStatus:'Pending',
      status:1
       
    });
  }

  get f(){
    return this.myForm.controls;
  }

  add() {
    this.content().push(this.newContent());
  }

  addAgree(){
    this.agree().push(this.newAgree());
  }

  addItem() {
    this.actionItem().push(this.newAction());
  }

  onChange(e){
   if(e == 1){
     this.isShow = true
   }
   else{
    this.isShow = false
   }
  }

  remove(index: number , group) {
    console.log(group);
    // if(index == 0){
    //   return
    // }
    // else{
    //   this.content().removeAt(index);
    // }
    if(this.data)
    {
      let pointsDiscussedId =  group.value.pointsDiscussedId;
      console.log("pointsDiscussedId" + pointsDiscussedId);

      if(pointsDiscussedId)
      {
        this.discussedpoints = {
          ['pointsDiscussedId'] : group.value.pointsDiscussedId,
          ['pointsDiscussedDetails']: group.value.pointsDiscussedDetails,
          ['status'] : 4
        }
        this.deletedDiscussedpoints.push(this.discussedpoints);
        console.log(this.deletedDiscussedpoints);
        const control =  this.myForm.get('pointsDiscussed') as FormArray;
        control.removeAt(index);
      }else{
        const control =  this.myForm.get('pointsDiscussed') as FormArray;
        control.removeAt(index);
      }
    }
  }

  removeItem(index: number, action) {
    console.log(action);
    // if(index == 0){
    //   return
    // }
    // else{
    //   this.actionItem().removeAt(index);
    // }
    if(this.data)
    {
      let actionItemId =  action.value.actionItemId;
      console.log("actionItemId" + actionItemId);

      if(actionItemId)
      {
        this.actionItems = {
          ['actionItemId'] : action.value.actionItemId,
          ['actionItemName']: action.value.actionItemName,
          ['actionItemTargetDate'] : action.value.actionItemTargetDate,
          ['actionItemOwner'] : action.value.actionItemOwner,
          ['status'] : 4
        }
        this.deletedactionItems.push(this.actionItems);
        console.log(this.deletedactionItems);
        const control =  this.myForm.get('actionItem') as FormArray;
        control.removeAt(index);
      }else{
        const control =  this.myForm.get('actionItem') as FormArray;
        control.removeAt(index);
      }
    }
  }

  removeAgree(index: number,group) {
    // if(index == 0){
    //   return
    // }
    // else{
    //   this.agree().removeAt(index);
    // }
    if(this.data)
    {
      let pointsAgreedId =  group.value.pointsAgreedId;
      console.log("pointsAgreedId" + pointsAgreedId);

      if(pointsAgreedId)
      {
        this.pointsAgreed = {
          ['pointsAgreedId'] : group.value.pointsAgreedId,
          ['pointsAgreedDetails']: group.value.pointsAgreedDetails,
          ['status'] : 4
        }
        this.deletedpointsAgreed.push(this.pointsAgreed);
        console.log(this.deletedpointsAgreed);
        const control =  this.myForm.get('pointsAgreed') as FormArray;
        control.removeAt(index);
      }
      else{
        console.log("else");
        const control =  this.myForm.get('pointsAgreed') as FormArray;
        control.removeAt(index);
      }
    }
  }

  myContent(index: number): FormArray {
    return this.content()
      .at(index)
      .get('subContent') as FormArray;
  }

  newSkill(): FormGroup {
    return this.fb.group({
      subContentTitle: ['',Validators.required],
    });
  }

  keyPress(event: any) {
    const pattern = /^[a-zA-Z0-9_. -]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  addsubContent(index: number) {
    this.myContent(index).push(this.newSkill());
  }

  removesubContent(index: number, i: number) {
    this.myContent(index).removeAt(i);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  // addEmpList(){
  //   let x = this.myForm.value;
  //   if(x.momAttendees == ''){
  //     alert("Please select the options")
  //   }
  //   else{
  //     this.select.close()
  //   }
  // }

  // cancel(){
  //   this.myForm.patchValue({momAttendees:''})
  //   this.select.close()
  // }
  cancel(){
    this.router.navigate(['./MOM-report'])
  }

  onSubmit() {
    this.submitted=true;
    console.log(this.submitted);

    this.modifiedmomdate= this.datepipe.transform(this.myForm.value.momDate , 'YYYY-MM-dd');
    console.log(this.modifiedmomdate);
    this.myForm.value['momDate'] =this.modifiedmomdate;

    this.myForm.value.actionItem.forEach(
      e=>{
        this.modifiedTargetDate= this.datepipe.transform(e.actionItemTargetDate, 'YYYY-MM-dd');
        e['actionItemTargetDate'] =this.modifiedTargetDate;
      })

    if(this.myForm.invalid){
      return
    }
    let x = this.myForm.value;
    console.log(x);

    // let text =  x.momAttendees.toString();
    // x.momAttendees = text

    if(this.data){
      console.log("is data");
      let x = this.myForm.value;
      console.log(x);
      x['momId'] = this.data;
      
      if(x.projectValue == 2){
        delete x.project;
      }
      this.deletedDiscussedpoints.forEach((element,index)=>{
        console.log(element);
        console.log(index);
        x.pointsDiscussed.push(element);
        console.log(x.pointsDiscussed);       
      })
      x.pointsDiscussed = x.pointsDiscussed

      this.deletedpointsAgreed.forEach((element,index)=>{
        console.log(element);
        console.log(index);
        x.pointsAgreed.push(element);
        console.log(x.pointsAgreed);  
      })
      x.pointsAgreed = x.pointsAgreed

      this.deletedactionItems.forEach((element,index)=>{
        console.log(element);
        console.log(index);
        x.actionItem.push(element);
        console.log(x.actionItem);
      })
      x.actionItem =  x.actionItem
      console.log(x);
      // console.log(JSON.stringify(x))
      //return
      this.gs.Update_mom(this.data,x).subscribe(
        (d:any)=>{
          Swal.fire({
            text:d.description,
            icon:'success'
          })
          window.location.reload();
        },
        err=>{
          Swal.fire({
            text:err.error.message,
            icon:'error'
          })
         window.location.reload();
        }
      )
    }
    else{
      if(x.projectValue == 1 && x.project == ''){
        this.myForm.controls['project'].setErrors({ 'required': true })
        return
      }
      if(x.projectValue == 1){
        x['project'] = {
          projectId : Number(x.project)
        }
      }
      if(x.projectValue == 2){
      //  x.removeControl('project');
        x['project'] = {
          projectId : null
        }
      }
      console.log(JSON.stringify(x))
      return
        this.gs.Create_mom(this.myForm.value).subscribe(
          (d:any)=>{
            Swal.fire({
              text:d.description,
              icon:'success'
            })
            //window.location.reload()
          },
          err=>{
            Swal.fire({
              text:err.error.message,
              icon:'error'
            })
            //window.location.reload()
          }
        )
    }
  }

}

