//Test
  //export const url =`http://38.242.135.217:6009/timeentry/`;
//Local
//  export const url=`http://192.168.2.57:6009/timeentry/`;
// export const url = `http://192.168.18.11:6009/timeentry/`

// export const url = `http://192.168.18.25:6009/timeentry/`
 // export const url = `http://localhost:6009/timeentry/`

// export const url = `http://192.168.18.38:6009/timeentry/`

//Contabo Production
// export const url=`http://173.249.36.244:6009/timeentry/`
export const url=`https://projectmanagement.jesperapps.com:6009/timeentry/`

export const globalTimeOut = 80000;
export const responseMsgTimeOut = 200000;

  // http://38.242.135.217:6009/timeentry/task/project/startdate/enddate/37/2021-07-01/2021-07-05

//   http://localhost:6009/timeentry/task/project/employee/startdate/enddate/{projectId}/{employeeId}/{taskStartDate}/{taskEndDate}