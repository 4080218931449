import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { AddProjectApproversComponent } from '../add-project-approvers/add-project-approvers.component';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-manage-project-approvers',
  templateUrl: './manage-project-approvers.component.html',
  styleUrls: ['./manage-project-approvers.component.scss']
})
export class ManageProjectApproversComponent implements OnInit {

  isData: boolean;
  user_type: string;
  search: any;
  approverList: any = []
  userType: any;
  p: any
  beforesearchpage: any;
  nodata=false;
  loader = true; 

  public searchFilter: any = '';
  query: any = '';
  beforepage :any;
  mySelect:any =5;

  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.isData = false;
    const Data = JSON.parse(localStorage.getItem('UserData'))
    this.gs.getResourceById(Data.employeeId).subscribe(
      (d:any)=>{
        this.userType = d.userType.userType;
        this.user_type = d.userType.userType;
       this.gs.getProjectApprover(1).subscribe(
          (pa:any)=>{
            this.loader = false;
          
            this.approverList = pa;
            console.log(this.approverList);

                  if(this.approverList.length == 0)
            {
              this.nodata = true;
              console.log("nodata")
            }
          },
          (error) => {
            this.nodata = true;
            this.loader = false;
            console.log("error")
            if(error.status == 404){
              this.nodata = true;
              console.log("404")
            }
          }
        );
      },
      (error) => {
        this.nodata = true;
        this.loader = false;
        console.log("error")
        if(error.status == 404){
          this.nodata = true;
          console.log("404")
        }
      }
    )

  }

  editprojectapprover(approver){
     
    console.log(approver);

    const dialogRef = this.dialog.open(AddProjectApproversComponent, {
      width: '500px',
      height:'fit-content',
      data : approver
    })
  }

  changepage(){
    if(this.search.length != 0)
    {
      this.p = 1;
    }else{
      this.p = this.beforesearchpage;
    }
  }
  
  viewProject(e){
    const dialogRef = this.dialog.open(AddProjectApproversComponent, {
      width: '500px',
      height:'fit-content',
      data : e
    })
  }

  sortData(sort: Sort) {
    const data = this.approverList.slice();
    if (!sort.active || sort.direction === '') {
      this.approverList = data;
      return;
    }
  
    this.approverList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'rulename':
          return this.compare(a.approvers.rulename, b.approvers.rulename, isAsc);     
          case 'projectName':
            return this.compare(a.project.projectName, b.project.projectName, isAsc);             
        default:
          return 0;
      }
    });
  }
  
   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
