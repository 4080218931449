<div class="conyainer-fluid">
  <div class="container-fluid">
    <mat-toolbar color="primary" class="toolbar">
        <p>Jesperapps</p>
    </mat-toolbar>
  <div class="row">
    <div class="col-lg-8">
      <div class="card">
        <div class="card-header">
          Register
        </div>
        <div class="card-body">
          <form action="" [formGroup]="registerForm" (ngSubmit)="save()">
            <!-- organization -->
            <div formGroupName="organization">
              <div class="form-group">
                <label for="">Organization Name</label>
                <input type="text" class="form-control" placeholder="Enter Organization Name"
                  formControlName="organizationName"
                   [ngClass]="{ 'is-invalid':submitted && this.f.organization.invalid }">
                  <small class="invalid-feedback" *ngIf="submitted && this.f.organization.invalid">
                      This field is required</small>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <!--resourceName-->
                <div class="form-group">
                  <label for="">User Name</label>
                  <input type="text" formControlName="resourceName" placeholder="Enter Resource Name"
                  [ngClass]="{ 'is-invalid': submitted && f.resourceName.errors }"
                    class="form-control">
                    <div *ngIf="submitted && f.resourceName.errors" class="invalid-feedback">
                      <div *ngIf="f.resourceName.errors.required">This field is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <!--pic-->
                <label for="">Image</label>
                <input type="file" (change)="fileChange($event)" name="image" placeholder="Upload file"
                accept=".png,.jpg, .jpeg">
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <!--dateOfBirth-->
                <div class="form-group">
                  <label for="">Dob</label>
                  <input type="date" placeholder="Select Date Of Birth" class="form-control"
                    formControlName="dateOfBirth" [ngClass]="{ 'is-invalid': submitted && this.f.dateOfBirth.invalid }">
                    <div *ngIf="submitted && this.f.dateOfBirth.invalid" class="invalid-feedback">
                     This field is required
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <!--gender-->
                <div class="form-group">
                  <label for="">Gender</label>
                  <select class="form-control" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                    <option value="Male">Male</option>
                    <option value="Male">Female</option>
                  </select>
                  <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                    <div *ngIf="f.gender.errors.required">This field is required</div>
                </div>
                </div>
              </div>
              <div class="col-sm">
                <!--nickName-->
                <div class="form-group">
                  <label for="">Nick Name</label>
                  <input type="text" placeholder="Enter User Nick Name" formControlName="nickName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.nickName.errors }">
                  <div *ngIf="submitted && f.nickName.errors" class="invalid-feedback">
                    <div *ngIf="f.nickName.errors.required">This field is required</div>
                </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <!--phoneNumber-->
                <div class="form-group">
                  <label for="">Phone Number</label>
                  <input type="text" class="form-control" placeholder="Enter Phone Number" (keypress)="noNumber($event)"
                    formControlName="phoneNumber" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }">
                    <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                      <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                      <div *ngIf="f.phoneNumber.errors">Phone Number Must Be 10 Digit</div>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <!--personalEmail-->
                <div class="form-group">
                  <label for="">Personal Email-Id</label>
                  <input type="email" class="form-control" placeholder="Enter Personal Email-Id"
                    formControlName="personalEmail" [ngClass]="{ 'is-invalid': submitted && f.personalEmail.errors }">
                    <div *ngIf="submitted && f.personalEmail.errors" class="invalid-feedback">
                      <div *ngIf="f.personalEmail.errors.required">This field is required</div>
                      <div *ngIf="f.personalEmail.errors.pattern">Invalid Email Address</div>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <!--organizationEmail-->
                <div class="form-group">
                  <label for="">Organization Email-Id</label>
                  <input type="email" class="form-control" placeholder="Enter Organization Mail-Id"
                    formControlName="organizationEmail" [ngClass]="{ 'is-invalid': submitted && f.organizationEmail.errors }">
                    <div *ngIf="submitted && f.organizationEmail.errors" class="invalid-feedback">
                      <div *ngIf="f.organizationEmail.errors.required">This field is required</div>
                      <div *ngIf="f.organizationEmail.errors.pattern">Invalid Email Address</div>
                  </div>
                </div>
              </div>
            </div>
            <!--password-->
            <div class="form-group">
              <label for="">New Password</label>
              <input type="password" class="form-control" placeholder="Enter Password" formControlName="password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
            </div>
            <!--Confirm Password-->
            <div class="form-group">
              <label for="">Confirm Password</label>
              <input type="password" placeholder="Enter Confirm Password" class="form-control"
                formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" #confirmPassword>
                <!-- <mat-icon (click)="toggle(confirmPassword)">{{isShow? 'visibility': 'visibility_off'}}
                </mat-icon> -->
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback" >
                  <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch">Must match with passeord</div>
              </div>
            </div>
            <!--userType-->
            <div formArrayName="userType">
              <div *ngFor="let a of userTypeArray.controls;let i = index">
                <div class="form-group" [formGroupName]="i">
                  <label for="">UserType</label>
                  <select class="form-control" formControlName="userTypeId">
                    <option [value]="1">{{'Manager'}}</option>
                    <!-- *ngFor="let u of userTypeList" -->
                  </select>
                </div>
              </div>
              <!--button-->
            </div>
            <div class="form-group float-right">
              <button class="btn btn-info" type="button" routerLink="/login">Cancel</button> &nbsp;
              <button class="btn btn-secondary" type="reset">Reset</button> &nbsp;
              <button mat-raised-button type="submit">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
