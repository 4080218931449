import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { EmployeeProjectImportComponent } from '../employee-project-import/employee-project-import.component';
import { responseMsgTimeOut} from '../../app/providers/properties';
import { AssignEmployeeErrorComponent } from '../assign-employee-error/assign-employee-error.component';

@Component({
  selector: 'app-assign-emp-project',
  templateUrl: './assign-emp-project.component.html',
  styleUrls: ['./assign-emp-project.component.css']
})
export class AssignEmpProjectComponent implements OnInit {

  @ViewChild('select') select: MatSelect;

  allSelected=false;
  employeeForm:FormGroup;
  employeeList:any = [];
  projectList:any = [];
  arrayData:any = [];
  ProjectName:any;
  project:any =[];
  roleList:any = [];
  empData:boolean
  projectId:any
  submitted=false;

  today:any= new Date();
  employeelastdate:any ;
  success = false;
  error:any=false;
  sucess_msg:any;
  errormessagejson: any;
  importerror: boolean;
  error_msg:any;
  constructor(
    private dialog:MatDialog,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs:GlobalserviceService ,
    private datepipe : DatePipe) { }

  ngOnInit() {
  //   console.log(this.data)
  //   this.ProjectName = this.data.projectName;
    this.employeeForm = this.fb.group({
      employee:['',Validators.required],
      rolename:['',Validators.required],
      project:['',Validators.required]
    })

    const Data = JSON.parse(localStorage.getItem('UserData'))
    this.gs.getProjectByOrganization(Data.organization.organizationId).subscribe((projectslist:any ) => {
              this.projectList = projectslist;
    })

  //   this.projectId = this.data.projectId

    this.gs.getResource().subscribe(
      (x:any)=>{
         console.log(x);
        this.today =this.datepipe.transform(this.today, 'YYYY-MM-dd');
          for(let a of x){
            console.log(a.employeeLastWorkingDate);

            if(a.employeeLastWorkingDate != undefined){
              console.log("IF 1");

              this.employeelastdate = a.employeeLastWorkingDate
              console.log(this.employeelastdate);

              if(this.employeelastdate >= this.today){
                console.log("IF 2");
                this.employeeList.push(a);
                console.log(this.employeeList); 
              }
            }
            else{
              this.employeeList.push(a);
              console.log(this.employeeList); 
            }
          }
          console.log( this.employeeList);
      }
    )

    this.gs.getRole().subscribe(
      (x:any)=>{
        this.roleList = x;
      }
    )

    // if(this.data.empprojectId){
    //   this.gs.getEmp_Project(this.data.empprojectId).subscribe(
    //     (d:any)=>{
    //       this.projectId = d.project.projectId
    //     }
    //   )
    //   this.empData = true
    //   this.employeeForm.patchValue({
    //     employee:this.data.employee.employeeId,
    //     rolename:this.data.userRole.userroleId
    //   })
    // }
  }

  get f(){
    return this.employeeForm.controls;
  }

  _Employee(){
    return this.f.employee.hasError('required') ? 'Please select Employees' :''
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  add(){
    let x = this.employeeForm.value;
    if(x.employee == ''){
      alert("Please select the options")
    }
    else{
      this.select.close()
    }
  }

  cancel(){
    this.employeeForm.patchValue({employee:''})
    this.select.close()
  }


  save(){
    this.submitted=true;
    if(this.employeeForm.invalid){
      return
    }
    
         let x = this.employeeForm.value;

      x.employee.map(
        e => {
          let data;
          if (e) {
            data = {
              employee: {
                employeeId: e
              },
              project: {
                projectId: x.project
              },
              userRole: {
                userroleId: x.rolename
              }
            }
          }
          this.arrayData.push(data)
        }
      )
      console.log(JSON.stringify(this.arrayData))
      this.gs.AssignEmpRole(this.arrayData).subscribe(
        (e:any)=>{
        //   alert("success");
          Swal.fire({
            text:e.message,
            icon:'success'
          })
          window.location.reload();
        },err =>{
           //alert("error")
          //  console.log(err.error);
          // Swal.fire({
          //   icon: 'error',
          //   text: err.error.message
            
          // })
      //    window.location.reload();
       this.arrayData.length = 0;
        }
      )
  }

  importEmployee() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(EmployeeProjectImportComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
    // height: 'fit-content',
    // maxWidth: '90%', 
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      //this.departmentList();
      if(result.statusCode == 200)
      {
        this.success = true;
				this.sucess_msg = result.description;
        setTimeout(() => {this.success  = false;},responseMsgTimeOut)
      }
      else 
      {
        if(result.length != 0)
       {

        this.error = true;
        // this.error_msg = result.message;
        this.importerror = true;
        this.errormessagejson = result;
        // this.showerrormessage();
        // setTimeout(() => {this.error = false}, responseMsgTimeOut)
       }
        else
        {
        if(result.statusCode > 400)
        {
          this.error = true;
        
          this.importerror = false;
        }
        }
      
      }
    });
  }

  showerrormessage()
  {
    const dialogRef = this.dialog.open(AssignEmployeeErrorComponent, {
      //height: 'auto',width: '600px',
      width: '600px',height:'fit-content',data: this.errormessagejson
    });
    this.error = false;
  }

}