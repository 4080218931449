<div class="main-content">
    <div class="container-fluid">
      <div class="top-content row">
        <div class="col-md-5 col-sm-5">
          <h4>Task Group</h4>
          <span class="sub-cont" [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Task group</span>
        </div>
        <div class="col-md-5 col-sm-5">
            <ul class="unstyled inbox-pagination">
                <div class="sidebar-search">
                    <div class="input-group">
                        <input type="text" class="form-control search-menu" placeholder="Search..." [(ngModel)]="search"  (keyup) = "filterByValue()" name="searchText">
                        <div class="material-icons-outlined search-icon">search</div>
                    </div>
                </div>
            </ul>
        </div>
        <div class="col-md-2 col-sm-2">
            <button type="button" mat-raised-button class="btn" (click)="addtaskgroup()">
                <span>Add TaskGroup</span>
            </button>
        </div>  
    </div>
    <mat-card class="card-content" *ngIf="taskgroupList.length > 0">
        <div class="card-body table-responsive">
            <div class="row p-0 m-0" *ngIf="taskgroupList.length > 5">
                <div class="col-xl-12 col-lg-12">
                    <div class="select-pagination">
                        <span>Show : </span>
                        <select class="select_list new-select_list" [(ngModel)]="mySelect">
                            <option class="form-control" value="5"> 5</option>
                            <option class="form-control" value="10">10</option>
                            <option class="form-control" value="15">15</option>
                            <option class="form-control" value="20">20</option>
                            <option class="form-control" value="25">25</option>
                        </select>
                    </div>
                </div>  
              </div>
            <table class="table"  matSort (matSortChange)="sortData($event)" >
                <thead>
                    <th style="text-align: center;">S.No</th>
                    <th mat-sort-header="taskGroupName" >TaskGroup Name</th>
                    <th mat-sort-header="taskGroupDescription"  >TaskGroup Description</th>
                    <th mat-sort-header="statusName" style="text-align: center;" >Status</th>
                    <th style="text-align: center;">Action</th>
                </thead>
                <tbody>
                    <tr *ngFor="let e of taskgroupList  |paginate:{ itemsPerPage: mySelect, currentPage: p }; let i = index">
                        <td style="text-align: center;" *ngIf="p">{{((p-1)*mySelect)+(i+1)}}</td>
                        <td style="text-align: center;"  *ngIf="!p">{{i+1}}</td>
                        <td>{{e.taskGroupName}}</td>
                        <td>{{e.taskGroupDescription}}</td>
                        <td *ngIf="e.statusName =='Active'" style="color:green;padding-right:30px;text-align: center;">{{e.statusName}}</td>
                        <td align="center">
                        <span  class="material-icons" (click)="updatetaskgroup(e)">
                            edit
                        </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event ; beforesearchpage = $event"
            *ngIf="taskgroupList.length > 5" align="end"></pagination-controls>
        </div>
    </mat-card>
    <mat-card class="card-content" *ngIf="nodata">
        <div class="card-body">
            <div class="alert alert-primary">
            <h4 align="center">Task group Not Found</h4>
            </div>
        </div>
    </mat-card>
    </div>
  </div>
  <div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>