import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardGuard } from './components/signin/login-service/guard.guard';
import { RegisterComponent } from './components/register/register.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { OtpComponent } from './otp/otp.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-layout/main-layout.module')
      .then(m => m.MainLayoutModule), canActivate: [GuardGuard]
  },
  {
    path: 'login', loadChildren: () => import('../app/components/signin/login/login.module')
      .then(L => L.LoginModule)
  },
  { path:'forget-password', component:ForgetPasswordComponent},
  { path:'otp/:organizationEmail',component:OtpComponent},
  { path:'new-password/:employeeId', component:NewPasswordComponent},
  {
    path: 'register', component: RegisterComponent
  },
  { path: 'main-component', loadChildren: () => import('./main-component/main-component.module')
     .then(m => m.MainComponentModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
