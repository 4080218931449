import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router,ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ShiftemployeeComponent } from '../my-components/shiftemployee/shiftemployee.component';


@Component({
  selector: 'app-show-error-employee',
  templateUrl: './show-error-employee.component.html',
  styleUrls: ['./show-error-employee.component.css']
})
export class ShowErrorEmployeeComponent implements OnInit {

  p:number = 1;
  clientList: any;
  noRecordsFound: boolean;
  nodatafound: boolean;
  tableSearch : any;
  pagePerItem:any=10;
  data: any;

  constructor(@Inject(MAT_DIALOG_DATA) public resultData : any , private dialogref : MatDialogRef<ShiftemployeeComponent> ) { }

  ngOnInit(): void {
    // let data=this.resultData;
     console.log('this.resultData',this.resultData);
     this.data=this.resultData;
     console.log(this.data);
    //   this.resultData = [
    //     {
    //         "statusCode": 409,
    //         "description": "EmployeeClient Alredy Assing StartDate 2023-06-26 EndDate 2023-06-27",
    //         "employeeNumber": "1041",
    //         "firstLogin": false,
    //         "createdBy": 0,
    //         "lastUpdatedBy": 0
    //     }
    // ]
  }

  closedialog()
  {
     this.dialogref.close();
  }

}
