import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalserviceService } from "../providers/globalservice.service";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.css"],
})
export class ForgetPasswordComponent implements OnInit {
  emailForm: FormGroup;
  create_sucess_msg: any;
  update_data_error_msg: any;
  loader: any = false;
  success: any = false;
  error: any = false;
  backdrop: any = false;
  IsDisabled: boolean = false;
  emaildata: any;

  constructor(
    private router: Router,
    private passwordservice: GlobalserviceService
  ) {}

  ngOnInit(): void {
    this.IsDisabled = true;

    this.emailForm = new FormGroup({
      organizationEmail: new FormControl(this.emailForm, [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
    });
  }

  submitData() {
    if (this.emailForm.invalid) {
      return;
    }
    var emaildata = {
      organizationEmail: this.emailForm.value.organizationEmail,
    };
    console.log(emaildata);
    this.backdrop = true;
    this.loader = true;
    this.passwordservice.forgetpassword(emaildata).subscribe(
      (data: any) => {
        this.backdrop = false;
        this.loader = false;
        this.success = true;
        this.create_sucess_msg = data.description;
        setTimeout(() => {
          this.router.navigate([
            "otp/" + this.emailForm.value.organizationEmail,
          ]);
        }, 3000);
      },
      (err) => {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
        this.update_data_error_msg = err.error.message;
        console.log(this.update_data_error_msg);
      }
    );
  }
  get organizationEmail() {
    return this.emailForm.get("organizationEmail");
  }
}
// /* If browser back button was used, flush cache */
// (function () {
//   window.onpageshow = function(event) {
//     if (event.persisted) {
//       console.log("if");
//       window.location.reload();
//     }
//     else{
//       console.log("else");
//     }
//   };
// })();
