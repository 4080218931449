import { Component, OnInit } from '@angular/core';
import { MustMatch } from '../components/register/password-match.validator';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from '../providers/globalservice.service';


@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  usertype:any;
  employeeId :string;
  passwordResetdata:any;
  create_sucess_msg:any;
  update_error_msg:any;
  loader:any= false;
  success:any=false;
  error:any=false;
  newpasswordForm: FormGroup;
  backdrop:any= false;
  IsDisabled: boolean = false;

  constructor(private formbuilder:FormBuilder,
              private router:Router ,
              private newPasswordservice:GlobalserviceService, 
              private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    
    this.employeeId = this.activatedRoute.snapshot.params.employeeId;
    console.log(this.employeeId);


    this.newpasswordForm = this.formbuilder.group(
      {
        newpassword : ['', [Validators.required,Validators.minLength(6)]],
        confirm_newpassword : ['', Validators.required]
      },
      {
        validators :  MustMatch('newpassword', 'confirm_newpassword')
      });
  }
  submitData(){
    if(this.newpasswordForm.invalid)
    {
      return;
    }
    var passwordResetdata =
    { 
      employeeId: this.employeeId,
      password :this.newpasswordForm.value.newpassword,     
    };
    console.log(passwordResetdata);
    this.loader=true;
    this.newPasswordservice
    .resetpassword(passwordResetdata , this.employeeId)
    .subscribe( (data:any) =>
    {
      this.passwordResetdata= data;
      console.log(this.passwordResetdata);
      this.loader = false;
      this.success = true;
      this.create_sucess_msg = this.passwordResetdata.description;
      console.log(this.create_sucess_msg);
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000)                
    },
    (err) =>
    {
        this.loader = false;
        this.error = true;
        this.update_error_msg = err.error.message;
        console.log(this.update_error_msg);
    });
  }
   //////get values from form //////
   get f()
   {
     return this.newpasswordForm.controls;
   }

}

