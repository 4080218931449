import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { GlobalserviceService } from '../../providers/globalservice.service';
import Swal from 'sweetalert2';
import {MustMatch}  from './password-match.validator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  userTypeList: any = [];
  isShow=false

  constructor(private fb: FormBuilder,
    public global: GlobalserviceService, private router:Router ) {

  }
  ngOnInit() {
    this.registerForm = this.fb.group({
      organization: this.fb.group({
        organizationName: ['',Validators.required]
      }),
      resourceName: ['',Validators.required],
      nickName: ['',Validators.required],
      dateOfBirth: ['',Validators.required],
      gender: ['Male',Validators.required],
      phoneNumber: ['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      personalEmail: ['',[Validators.required,Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      organizationEmail: ['',[Validators.required,Validators.pattern(/^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      password: ['',[Validators.required,Validators.minLength(6)]],
      confirmPassword: ['',Validators.required],
      userType: this.fb.array([this.usertypeGroup()])
    },
    {
      validator: MustMatch('password', 'confirmPassword')
  });
    this.global.getUserType().subscribe(
      d => {
        this.userTypeList = d
      }
    )
    // this.registerForm.patchValue({
    //   resourceName: 'Manikandan',
    //   nickName:'mani',
    //   dateOfBirth: '19-02-2020',
    //   gender: 'Male',
    //   phoneNumber: '98745632145',
    //   personalEmail: 'mk@gmail.com',
    //   organizationEmail: 'manikandan.ramappa@jesperapps.com',
    //   password: '123456',
    //   confirmPassword: '123456'
    // })
  }
  usertypeGroup() {
    return this.fb.group({
      userTypeId: [1]
    })
  }
  get userTypeArray(): FormArray {
    return this.registerForm.get('userType') as FormArray
  }
  // image file
  File: any
  testfile: any;
  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.File = fileList[0];
      this.testfile = this.File;
    }
  }
  get f() { return this.registerForm.controls }
  toggle(i) {

    if (i.type === "text") {
      this.isShow = false
      i.type = "password"
    }
    else {
      this.isShow = true
      i.type = "text"
    }
  }


  //save
  save() {
    this.submitted=true;
    if(this.registerForm.invalid){
      return
    }

   let y = this.registerForm.value
    delete y.confirmPassword
    let x =JSON.stringify(this.registerForm.value)

    let formData:FormData = new FormData()
    formData.append('json', x);
    formData.append('file',this.testfile)
    console.log((x))
    this.global.createResource(formData).subscribe(
      d => {
        Swal.fire({
          icon: 'success',
          text: 'You Have Successfully Registered '
        });
        this.router.navigate(['login'])
      }
    )
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
