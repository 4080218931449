import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-employee-timesheet-viewdescription',
  templateUrl: './employee-timesheet-viewdescription.component.html',
  styleUrls: ['./employee-timesheet-viewdescription.component.css']
})
export class EmployeeTimesheetViewdescriptionComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // Access 'this.data' to get the passed data
    console.log('Data in EmployeeTimesheetViewdescriptionComponent:', this.data);
  }

}
