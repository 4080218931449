<div class="container-fluid">
  <div style="margin-top: -30px" class="d-flex justify-content-end">
    <mat-icon class="material-icons-outlined remove" (click)="remove()">
      highlight_off
    </mat-icon>
  </div>
  <h4 class="font-weight-bold" style="color: #03c9d7; margin-top: 10px">
    Employee Timesheet
  </h4>

  <div class="mt-7" style="margin-top: 50px">
    <mat-card class="card-content mt-7">
      <div class="card-body">
        <div class="row m-0">
          <ng-container *ngIf="timesheetreport.length > 0">
            <div class="col-xl-10 col-lg-9 col-lg-6 col-sm-8 col-md-9">
              <span class="badge badge-light totaltime"
                >Total Time : {{ totalTime }}</span
              >
            </div>
          </ng-container>
          <ng-template #alignend>
            <div class="col-xl-12 col-lg-12">
              <span class="badge badge-light totaltime"
                >Total Time : {{ tasktotalTime }}</span
              >
            </div>
          </ng-template>
          <div
            class="col-xl-2 col-lg-3 col-md-3 col-sm-4"
            *ngIf="timesheetreport.length > 5"
          >
            <div class="select-pagination">
              <span>Show : </span>
              <select
                class="select_list new-select_list"
                [(ngModel)]="mySelect"
                (change)="changefirstpage()"
              >
                <option class="form-control" value="5">5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
        </div>
        <div class="table-responsive" *ngIf="timesheetreport.length > 0">
          <table class="table"   matSort (matSortChange)="sortData($event)">
            <thead>
              <tr class="tr"  >
        
                <th mat-sort-header="employeeName" scope="col">
                  Employee&nbsp;Name
                </th>
                <th mat-sort-header="projectName" scope="col">
                  Project&nbsp;Name
                </th>
                <th mat-sort-header="taskName" scope="col">Task&nbsp;Name</th>
                <th
                  mat-sort-header="date"
                  scope="col"
                  style="text-align: center"
                >
                  Date
                </th>

                <th
                  mat-sort-header="workedHours"
                  scope="col"
                  style="text-align: center"
                >
                  Worked&nbsp;Hours
                </th>
                <th
                  mat-sort-header="taskStatusName"
                  scope="col"
                  style="text-align: center"
                >
                  Task&nbsp;Status
                </th>
                <th
                  mat-sort-header="approvalStatus"
                  scope="col"
                  style="text-align: center"
                >
                  Approval &nbsp; Status
                </th>

                <th style="text-align: center" scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr *ngIf="nodata">
                <td colspan="9" style="text-align: center">No records found</td>
              </tr> -->
              <tr
                *ngFor="
                  let e of timesheetreport
                    | paginate : { itemsPerPage: mySelect, currentPage: p };
                  let i = index
                "
              >
             
                <td>
                  {{ e.employee.employeeName | titlecase }}
                </td>
                <td>{{ e.project?.projectName | titlecase }}</td>
                <td>{{ e.task?.taskName | titlecase }}</td>
                <td style="text-align: center; padding-right: 20px">
                  {{ e.date }}
                </td>

                <td style="text-align: center; padding-right: 30px">
                  {{ e.workedHours }}
                </td>
                <td style="text-align: center; padding-right: 30px" [style.color]="withDrawStatus(e)">
                  {{ e.task.taskStatusName }}
                </td>
                <td style="text-align: center; padding-right: 30px" [style.color]="approvalStatus(e)">
                  {{ e.approvalStatusName }}
                </td>
              
                <td class="more" align="center">
                  <span
                    class="material-icons"
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    more_vert
                  </span>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="description(e)">
                      <mat-icon class="edit-icon">date_range</mat-icon>
                      <span class="edit-form">View Description</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
         
        </div>
        <mat-card class="card-content" *ngIf="nodata">
          <div class="card-body">
            <div class="alert alert-primary">
              <h4 align="center">Timesheet Not Found</h4>
            </div>
          </div>
        </mat-card>
      </div>
  
      <pagination-controls
        (pageChange)="p = $event"
        *ngIf="timesheetreport.length > 5"
        align="end"
      ></pagination-controls>
    
    </mat-card>

    <!-- <mat-card class="card-content" *ngIf="nodata">
      <div class="card-body">
        <div class="alert alert-primary">
          <h4 align="center">Timesheet Not Found</h4>
        </div>
      </div>
    </mat-card> -->
  </div>
</div>

<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
