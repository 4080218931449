<div class="container-fluid">
  <div class="top-content row">
    <div class="">
      <h4>Assign Employee Project</h4>
      <span class="sub-cont" [routerLink]="['/admin-dashboard']">Dashboard</span> / <span class="sub-cont1">Assign Employee Project</span>
    </div>

    <div align="end" mat-dialog-actions>
      <!-- <button class="btn create-button mb-2" color="primary" type="submit">
        {{this.data.empprojectId ? 'Update' : 'Assign'}}</button> -->
        <button       (click)="importEmployee()" class="btn create-button mb-2" color="primary" type="submit">
        Import</button>
    </div>
</div>
<div class="col-md-12">
  <div class="row">
    <div class="card main-card">
        <div class="row">
          <div class="col-md-11">
            <h4 align="center">Assign Employee</h4>
          </div>
        </div>

        <form [formGroup]="employeeForm" (ngSubmit)="save()">
            <div class="row">
              <div class="col-md-12" id="loginform">
                  <label class="form-control-label">Project<span class="text-danger">*</span></label>
                  <mat-select formControlName="project" 
                    placeholder="Select Project Name"
                    class="form-control email-input select-placeholder">
                    <!-- <mat-option> -- Select Project --</mat-option> -->
                    <mat-option *ngFor="let project of projectList" [value]="project.projectId" >
                      {{project.projectName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.project.hasError('required') && submitted">
                    Project   is required
                  </mat-error>
                </div>

              <div class="col-md-12" *ngIf="!empData"  id="loginform">
                <label class="form-control-label">Employee Name<span class="text-danger">*</span></label>
                <mat-select
                  formControlName="employee"
                  placeholder="Select Employee Name"
                  #select multiple
                  class="form-control email-input select-placeholder">
                  <div class="select-all">
                    <mat-checkbox
                      [(ngModel)]="allSelected"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="toggleAllSelection()">Select All</mat-checkbox>
                  </div>
                  <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId">
                    {{emp.employeeName}}
                  </mat-option>
                  <button
                    mat-raised-button
                    color="primary"
                    style="margin: 0 18px 18px 18px"
                    (click)="add()"  class="btn create-button">
                    Add
                  </button>
                  <button mat-raised-button color="warn" 
                    (click)="cancel()"
                    class="btn cancel-button">
                    Cancel
                  </button>
                </mat-select>
                <mat-error *ngIf="f.employee.hasError('required') && submitted">
                  Please select Employee Name
                </mat-error>
              </div>
        
              <div class="col-md-12" *ngIf="empData" id="loginform">
                <label class="form-control-label">Employee Name<span class="text-danger">*</span></label>
                <mat-select formControlName="employee" 
                  placeholder="Select Employee Name"
                  class="form-control email-input select-placeholder">
                  <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId" >
                    {{emp.employeeName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.employee.hasError('required') && submitted">
                  Employee Name is required
                </mat-error>
              </div>
        
              <div class="col-md-12" id="loginform">
                <label class="form-control-label">Role Name<span class="text-danger">*</span></label>
                <mat-select formControlName="rolename" 
                  placeholder="Role Name"
                  class="form-control email-input select-placeholder">
                  <mat-option *ngFor="let emp of roleList" 
                  [value]="emp.userroleId" >
                    {{emp.rolename}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.rolename.hasError('required') && submitted">
                  Please select Role Name
                </mat-error>
              </div>
            </div>
        
            <div align="end" mat-dialog-actions>
              <!-- <button class="btn create-button mb-2" color="primary" type="submit">
                {{this.data.empprojectId ? 'Update' : 'Assign'}}</button> -->
                <button class="btn create-button mb-2" color="primary" type="submit">
                Assign</button>
            </div>
        </form>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"99
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>