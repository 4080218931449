import { Component, EventEmitter, Inject, OnInit ,Output} from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { DatePipe} from '@angular/common/';
import { StatusPipe} from '../../status.pipe';
import {Sort} from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCard } from "@angular/material"
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { element } from 'protractor';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};



@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  projectCount:any = 0;
  clientCount:any = 0;
  employeeCount:any = 0;
  taskCount:any = 0;
  clientList:any = []
  page:any;
  nodata = false;
  loader = true;
  search: any;

  public searchFilter: any = '';
  query: any = '';
  beforesearchpage :any;
  mySelect:any =5;
  p:any;
  timesheetdate:any;
  notsubmittedtaskreport :any = [];
  projectBudgetHourslist : any = [];
  projectAcutalHourslist : any = [];
  projectNameList : any = [];
  public chartOptions: Partial<ChartOptions>;
  projectList: any;
  nodatachart = false;


  constructor(private gs:GlobalserviceService,
    private datePipe: DatePipe) { }

    public barChartOptions:any = {
      scaleShowVerticalLines: false,
      responsive: true
    };
  
   
  ngOnInit(): void {

  

    this.loader = true;
    var currentdate = new Date();
    this.timesheetdate = this.datePipe.transform(currentdate, 'yyyy-MM-dd');
    console.log("timesheetdate" + this.timesheetdate);

    this.gs.getnotsubmittedTaskReportByEmpolyee(this.timesheetdate).subscribe(
    (d:any)=>{
        this.notsubmittedtaskreport = d;
        console.log('this.notsubmittedtaskreport',this.notsubmittedtaskreport);
    }
    )

      this.getprojectlist();
   

    this.gs.getTaskList().subscribe(
      (d:any)=>{
        this.taskCount = d.length
      }
    )

    this.gs.getResource().subscribe(
      (d:any)=>{
        this.employeeCount = d.length
      }
    )

    this.gs.Get_Customer().subscribe(
      (d:any)=>{
        this.clientCount = d.length
        this.loader = false;
        this.clientList = d;
        if(this.clientList.length == 0)
        {
          this.nodata = true;
          console.log("nodata")
        }
      },
      (error) => {
        this.loader = false;
        this.nodata = true;
        console.log("error")
        if(error.status == 404){
          this.nodata = true;
          console.log("404")
        }
      }
    )
  }

  getproject(event :any)
  {
      console.log(event);
      this.projectBudgetHourslist = [];
      this.projectAcutalHourslist =  [];
      this.projectNameList =  [];
         if(event.value != 0)
         {
              this.gs.getprojectByID(event.value).subscribe(
                (d:any)=>{

                  console.log('ddddd',d);
                  
                    if(d.projectBudgetHours)
                    {
                    this.projectBudgetHourslist.push(d.projectBudgetHours);
                    this.projectAcutalHourslist.push(d.projectAcutalHours.substring(0,2));
                    this.projectNameList.push(d.projectName);

                    console.log('this.projectBudgetHourslist',  this.projectBudgetHourslist);
                    console.log('this.projectAcutalHourslist',  this.projectAcutalHourslist);
                     
                    }
                    if(this.projectNameList.length == 0)
                    {
                      this.nodatachart = true;                 
                    }
                    else{
                      this.nodatachart = false;
                     }
                     
                    this.chartOptions = {
                      series: [
                        {
                          name: "Budget Hours",
                          data: this.projectBudgetHourslist
                        },
                        {
                          name: "Acutal Hours",
                          data:  this.projectAcutalHourslist
                        },
                      ],
                      chart: {
                        type: "bar",
                        height: 350
                      },
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          columnWidth: "55%",
                          endingShape: "rounded"
                        }
                      },
                      dataLabels: {
                        enabled: false
                      },
                      stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"]
                      },
                      xaxis: {
                        categories: this.projectNameList
                      },
                      yaxis: {
                        title: {
                          text: "(Hours)"
                        }
                      },
                      fill: {
                        opacity: 1
                      },
                      tooltip: {
                        y: {
                          formatter: function(val) {
                            return  val + " Hours";
                          }
                        }
                      }
                    };
                }
              )
         }else{
                console.log("else");
                   this.getprojectlist();
          
         }
  
  }

  getprojectlist()
  {
    this.gs.getProjectList().subscribe(
      (d:any)=>{
        this.projectCount = d.length
        this.projectList = d;
      console.log('this.projectList;;;;',   this.projectList);



        d.forEach(element => {

           if(element.projectBudgetHours)
           {
           this.projectBudgetHourslist.push(element.projectBudgetHours);
           this.projectAcutalHourslist.push(element.projectAcutalHours.substring(0,2));
           this.projectNameList.push(element.projectName);
          
           }         
        });

           if(this.projectNameList.length == 0)
           {
            this.nodatachart = true;
           }else{
            this.nodatachart = false;
           }


        this.chartOptions = {
          series: [
            {
              name: "Budget Hours",
              data: this.projectBudgetHourslist
            },
            {
              name: "Acutal Hours",
              data:  this.projectAcutalHourslist
            },
          ],
          chart: {
            type: "bar",
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: this.projectNameList
          },
          yaxis: {
            title: {
              text: "(Hours)"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return  val + " Hours";
              }
            }
          }
        };
      }
    )
  }

  changepage(){
    if(this.search.length != 0)
    {
      this.p = 1;
    }else{
      this.p = this.beforesearchpage;
    }
  }

  sortData(sort: Sort) {
    const data = this.notsubmittedtaskreport.slice();
    if (!sort.active || sort.direction === '') {
      this.notsubmittedtaskreport = data;
      return;
    }

    this.notsubmittedtaskreport = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       
        case 'employeeName':
          return this.compare(a.employeeName, b.employeeName, isAsc);
        case 'organizationEmail':
          return this.compare(a.organizationEmail, b.organizationEmail, isAsc);
        default:
          return 0;
      }
    });
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
