<div >
    <mat-icon style="color: red !important;float: right;" class="material-icons-outlined" (click)="closedialog()">close</mat-icon>          
</div>
<div class="table-responsive">
    <p><b>Error Records</b></p>
    <ng-container *ngIf="data.at(0).employeeNumber; else clientcode">
        <table class="table  --table-bordered table-border-new" style="width: 100%;">
            <thead class="t-head">
                <tr>
                    <!-- <th class="text-center">S.No</th> -->
                     <th>Employee Number</th> 
                    <th >Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of resultData  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                    <!-- <td class="text-center">{{i+1}}</td> -->
                     <td width="30%">{{row.task.taskName}}</td> 
                    <!-- <td width="30%">
                        <ng-container *ngIf="row.employeeNumber; else clientcode">
                          {{ row.employeeNumber }}
                        </ng-container>
                        <ng-template #clientcode>
                          {{ row.clientCode }} 
                        </ng-template>
                      </td> -->
                    
                    <td width="70%">{{row.description}}</td>      
                    <!-- capitalizeWordsName -->
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-template #clientcode>
        <table class="table  --table-bordered table-border-new" style="width: 100%;">
            <thead class="t-head">
                <tr>
                    <!-- <th class="text-center">S.No</th> -->
                     <!-- <th>Task Name</th>  -->
                    <th >Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of resultData  | paginate: { itemsPerPage: pagePerItem, currentPage: p } | searchFilter: tableSearch; let i=index;">
                    <!-- <td class="text-center">{{i+1}}</td> -->
                     <!-- <td width="30%">{{row.task.taskName}}</td>  -->
                    <!-- <td width="30%">
                        <ng-container *ngIf="row.employeeNumber; else clientcode">
                          {{ row.employeeNumber }}
                        </ng-container>
                        <ng-template #clientcode>
                          {{ row.clientCode }} 
                        </ng-template>
                      </td> -->
                    
                    <td width="70%">{{row.description}}</td>      
                    <!-- capitalizeWordsName -->
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td style="text-align: center;" colspan="12" *ngIf="noRecordsFound">No Records Found!</td>
                </tr>
            </tbody>
        </table>
    </ng-template>
    <div class="row">
        <div class="col-md-3">
            <div class="showing-record">
                {{resultData.length}} rows
            </div>
        </div>
        <div class="col-md-9 text-right">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
    </div>
 </div>