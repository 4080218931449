import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router,ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-task-group',
  templateUrl: './task-group.component.html',
  styleUrls: ['./task-group.component.css']
})
export class TaskGroupComponent implements OnInit {

  taskGroup : FormGroup;
  submitted : Boolean = false;
  createdata : any;
  userData : any;
  taskgroupdata : any;
  taskgroupid : any;
  updatedata : any;

  constructor(
    public dialogRef: MatDialogRef<TaskGroupComponent>,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs:GlobalserviceService,
    private router:Router,
  ) { }

  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem("UserData"));

    console.log(this.data);
    this.taskgroupid = this.data.taskGroupId;
    
    this.taskGroup = this.fb.group({
      taskgroupname : ['',Validators.required],
      description : ['',Validators.required],
      status:[],
      organization:{
        organizationId:['',Validators.required]
      }
    })

    if(this.taskgroupid){
      this.gs
      .gettaskgroupbyId(this.taskgroupid)
      .subscribe((data:any) =>
      {
        this.taskgroupdata = data;
        console.log(this.taskgroupdata)

        this.taskGroup.patchValue({
          taskgroupname : this.taskgroupdata.taskGroupName,
          description : this.taskgroupdata.taskGroupDescription
        })
      })
    }
  }

  onSubmit(){
    this.submitted = true;
    if(this.taskGroup.invalid){
      return
    }

    if(this.taskgroupid) {
      console.log(this.taskgroupid)
      console.log( "taskgroupname initcaps"  + this.capitalizeWordsName(this.taskGroup.value.taskgroupname));
      console.log( "description initcaps"  + this.capitalizeWordsDescription(this.taskGroup.value.description));
      var updatetaskgroup = 
      {
        taskGroupId : this.taskgroupid,
        taskGroupName : this.capitalizeWordsName(this.taskGroup.value.taskgroupname),
        taskGroupDescription :  this.capitalizeWordsDescription(this.taskGroup.value.description),
        status : this.data.status,
        organization:{
          organizationId:this.data.organization.organizationId
        }
      }
      console.log(updatetaskgroup);
      this.gs
      .updatetaskgroup(updatetaskgroup, this.taskgroupid)
      .subscribe( (data) =>
      {
         this.updatedata = data;
         console.log(this.updatedata)
         Swal.fire({
          text:"TaskGroup Updated Successfully",
          icon:'success'
        })
        setTimeout(() => {
          this.router.navigate(['pro/taskgroup-list']); 
        }, 3000)
        window.location.reload()
      },
      err=>{
        Swal.fire({
          text:err.error.message,
          icon:'error'
        })
      })
    }
    else{
       
      //  console.log( "taskgroupname initcaps"  + this.capitalizeWordsName(this.taskGroup.value.taskgroupname));
      //  console.log( "description initcaps"  + this.capitalizeWordsDescription(this.taskGroup.value.description));
 
      var createtaskgroup =
      {
        taskGroupName : this.capitalizeWordsName(this.taskGroup.value.taskgroupname),
        taskGroupDescription :  this.capitalizeWordsDescription(this.taskGroup.value.description),
        status : 1,
        organization:{
          organizationId:this.userData.organization.organizationId
        }
      }
      console.log(createtaskgroup);
      this.gs
      .createtaskgroup(createtaskgroup)
      .subscribe( (data:any) =>
      {
        this.createdata = data;
        console.log(this.createdata);
        Swal.fire({
          text:"TaskGroup Created Successfully",
          icon:'success'
        })
        setTimeout(() => {
          this.router.navigate(['pro/taskgroup-list']); 
        }, 3000)
        window.location.reload();
      },
      err=>{
        Swal.fire({
          text:err.error.message,
          icon:'error'
        })
      } )
    }

  }

  capitalizeWordsName(text){
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };

  capitalizeWordsDescription(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  
  get taskgroupname(){
    return this.taskGroup.get('taskgroupname');
  }
  get description(){
    return this.taskGroup.get('description');
  }
}
