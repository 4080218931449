<div class="resource-background">
    <div class="card resource-card">
        <mat-tab-group class="content" [(selectedIndex)]="selectedProjectIndex">
            <mat-tab>
                <ng-template mat-tab-label>
                    &nbsp;&nbsp;Add Task&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </ng-template>
                <div [ngStyle]="{'display': selectedProjectIndex === 0 ? 'block' : 'none'}" >
                    <form [formGroup]="taskForm" style="margin-top: 15px;">
                        <div class="row">                                                    
                            <!-- <div class="col-md-6" id="loginform">
                                <label class="form-control-label">Task<span class="text-danger">*</span></label>
                                <mat-select class="form-control email-input select-placeholder" 
                                        appearance="fill"
                                        placeholder="Select Task" 
                                        formControlName="task">
                                    <mat-option value="Newtask">New task</mat-option>
                                    <mat-option value="Existingtask">Existing task</mat-option>
                                </mat-select>
                                <div *ngIf="(task.invalid && task.touched) || task.dirty">
                                    <small *ngIf="task.errors?.required" class="text-danger">Please Enter Task</small>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6" id="loginform">
                                <label class="form-control-label">Task Name<span class="text-danger">*</span></label>
                                <mat-select class="form-control email-input select-placeholder" appearance="fill" 
                                placeholder="Select TaskName"  formControlName="taskName" 
                                (selectionChange) = "validateprojecttask()">
                                    <mat-option *ngFor="let task of taskType" 
                                    [value]="task.commontaskName" 
                                    (click)="change(task.commontaskId,task.taskDescription)">
                                    {{task.commontaskName}}
                                </mat-option>
                                </mat-select>
                                <div *ngIf="(taskName.invalid && taskName.touched) || taskName.dirty">
                                    <small *ngIf="taskName.errors?.required" class="text-danger">Please Enter Task Name</small>
                                </div> 
                            </div> -->

                            <div class="col-md-6" id="loginform">
                                <label class="form-control-label">Task Name<span class="text-danger">*</span></label>
                                <input matInput 
                                    formControlName="taskName" 
                                    placeholder="Enter task name"
                                    autocomplete="off" 
                                    (keyup) = "validateprojecttask()"
                                    class="form-control email-input select-placeholder" />
                                <div *ngIf="(submitted1 && taskName.invalid) || taskName.dirty">
                                    <small *ngIf="taskName.errors?.required" class="text-danger">Please enter task name</small>
                                </div>
                                <span *ngIf="projecttaskexist" class="span-error"> Task already exist</span>
                            </div>
                            <div class="input-width col-md-6" id="loginform" appearance="fill">
                                <label class="form-control-label">Employee<span class="text-danger">*</span></label>
                                <mat-select 
                                    placeholder="Select employee name" 
                                    formControlName="employee"                                  
                                    class="form-control email-input select-placeholder">
                                    <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId"
                                        (click)="empData(emp)">{{ emp.employeeName }} </mat-option>
                                </mat-select>
                                <div *ngIf="(employee.invalid && submitted1) || employee.dirty">
                                    <small *ngIf="employee.errors?.required" class="text-danger">Please enter employee name</small>
                                </div>
                            </div>
                          </div>  
                          <div class="row">   
                            <div class="col-md-6" id="loginform">
                              <label class="form-control-label">Project<span class="text-danger">*</span></label>
                              <mat-select 
                                class="form-control email-input select-placeholder" 
                                appearance="fill" 
                                placeholder="Select project" 
                                formControlName="projects" 
                                (selectionChange) = "projectapprovervalidate()">
                                    <mat-option *ngFor="let project of projectList" 
                                    [value]="project.projectId"
                                    (click)="projectData(project)">
                                        {{project.projectName}}
                                    </mat-option>
                              </mat-select>
                              <div *ngIf="(projects.invalid && submitted1) || projects.dirty">
                                  <small *ngIf="projects.errors?.required" class="text-danger">Please enter project</small>
                              </div>
                            </div>                                                    
                            <div class="col-md-6">
                                <label class="form-control-label">Task Description<span class="text-danger">*</span></label>
                                <textarea rows="1" 
                                class="form-control email-input select-placeholder" 
                                formControlName="taskDescription"
                                [(ngModel)]="description" 
                                placeholder="Task description"></textarea>
                                <div *ngIf="(taskDescription.invalid && submitted1) || taskDescription.dirty">
                                    <small *ngIf="taskDescription.errors?.required" class="text-danger">Please enter task description</small>
                                </div>
                            </div>
                          </div> 
                          <div class="row">  
                            <div class="col-md-6" id="loginform">
                              <label class="form-control-label">StartDate<span class="text-danger">*</span></label>
                              <input matInput 
                                [matDatepicker]="picker1" 
                                (click)="picker1.open()" 
                                formControlName="taskStartDate"
                                (dateChange)="assignsomeMethodName($event)" 
                                [min]="min" [max]="max"
                                class="form-control email-input select-placeholder"
                                placeholder="Enter start date">
                                <mat-datepicker-toggle matSuffix [for]="picker1" 
                                style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 touchUi></mat-datepicker>
                                <div *ngIf="(taskStartDate.invalid && submitted1 ) || taskStartDate.dirty">
                                    <small *ngIf="taskStartDate.errors?.required" class="text-danger">Please select start date</small>
                                </div> 
                            </div>   
                                              
                            <div class="col-md-6" id="loginform">
                                <label class="form-control-label">EndDate<span class="text-danger">*</span></label>
                                <input matInput 
                                [matDatepicker]="picker2" 
                                (click)="picker2.open()" 
                                formControlName="taskEndDate" 
                                [min]="minStart" [max]="max"
                                [disabled]="endDate_disable" 
                                class="form-control email-input select-placeholder"
                                placeholder="Enter end date">
                                <mat-datepicker-toggle matSuffix [for]="picker2" 
                                style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 touchUi></mat-datepicker>
                                <div *ngIf="(taskEndDate.invalid &&  submitted1 ) || taskEndDate.dirty">
                                    <small *ngIf="taskEndDate.errors?.required" class="text-danger">Please select end date</small>
                                </div>
                            </div>
                          </div> 
                          <div class="row">
                            <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Hours<span class="text-danger">*</span></label>
                            <input matInput 
                            formControlName="hours" 
                            placeholder="Enter hours" 
                            maxlength="2" 
                            (keypress)="noNumber($event)"
                            autocomplete="off" 
                            class="form-control email-input select-placeholder" />
                                <div *ngIf="(hours.invalid &&  submitted1 ) || hours.dirty">
                                    <small *ngIf="hours.errors?.required" class="text-danger">Please enter hours</small>
                                </div>
                            </div>

                            <div class="col-md-6" id="loginform">
                            <label class="form-control-label">Minutes<span class="text-danger">*</span></label>
                            <input matInput 
                            formControlName="minutes" 
                            placeholder="Enter Minutes" 
                            maxlength="2" 
                            (keypress)="noNumber($event)"
                            autocomplete="off" 
                            class="form-control email-input select-placeholder"/>
                                <div *ngIf="(minutes.invalid &&  submitted1 ) || minutes.dirty">
                                    <small *ngIf="minutes.errors?.required" class="text-danger">Please enter minutes</small>
                                </div>
                            </div>
                        </div>

                        <div class="row form-content2 m-0 float-right">
                            <div class="form-cancel">
                                <Button type="submit" 
                                  style="padding: 7px 21px 7px 21px; font-weight:500" 
                                  class="btn create-button"
                                mat-raised-button (click)="changeProjectTab(1)">Next</Button>
                            </div>
                        </div>
                    </form>
                </div>    
            </mat-tab>
            <mat-tab [disabled]="disableTab">
                <ng-template mat-tab-label>
                    &nbsp;&nbsp;Assign Employee Task&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </ng-template>
                <div [ngStyle]="{'display': selectedProjectIndex === 1 ? 'block' : 'none'}">
                    <form [formGroup]="taskassignForm" style="margin-top: 15px;">
                        <!-- Total Hours -->
                        <div class="row">
                            <div class="full-width col-sm-3" id="loginform" appearance="fill">
                              <label class="form-control-label">Total Hours<span class="text-danger">*</span></label>
                              <input
                                matInput
                                formControlName="totalhours"
                                placeholder="Total hours"
                                maxlength="3"
                                (keypress)="assignnoNumber($event)"
                                autocomplete="off"
                                readonly
                                class="form-control email-input select-placeholder"
                              />
                            </div>
                            <div class="full-width col-sm-3" id="loginform" appearance="fill">
                              <label class="form-control-label">Total Minutes<span class="text-danger">*</span></label>
                              <input
                                matInput
                                formControlName="totalminutes"
                                placeholder="Total minutes"
                                maxlength="2"
                                (keypress)="assignnoNumber($event)"
                                autocomplete="off"
                                readonly
                                class="form-control email-input select-placeholder"
                              />
                            </div>
                  
                            <div class="full-width col-sm-3" id="loginform" appearance="fill">
                              <label class="form-control-label">Consumed Hours<span class="text-danger">*</span></label>
                              <input
                                matInput
                                formControlName="consumedhours"
                                placeholder="Enter consumed hours"
                                maxlength="3"
                                (keypress)="assignnoNumber($event)"
                                autocomplete="off"
                                readonly
                                class="form-control email-input select-placeholder"
                              />
                            </div>
                  
                            <div class="full-width col-sm-3" id="loginform" appearance="fill">
                              <label class="form-control-label">Consumed Minutes<span class="text-danger">*</span></label>
                              <input
                                matInput
                                formControlName="consumedminutes"
                                placeholder="Enter consumed minutes"
                                maxlength="2"
                                (keypress)="assignnoNumber($event)"
                                autocomplete="off"
                                readonly
                                class="form-control email-input select-placeholder"
                              />
                            </div>
                        </div>
                        <!-- Assigned Hours -->
                        <div class="row">
                            <div class="full-width col-sm-3" id="loginform" appearance="fill">
                              <label class="form-control-label">Available Hours<span class="text-danger">*</span></label>
                              <input
                                matInput
                                formControlName="avaliablehours"
                                placeholder="Enter available hours"
                                maxlength="3"
                                (keypress)="assignnoNumber($event)"
                                readonly
                                class="form-control email-input select-placeholder"
                              />
                            </div>
                            <div class="full-width col-sm-3" id="loginform" appearance="fill">
                              <label class="form-control-label">Available Minutes<span class="text-danger">*</span></label>
                              <input
                                matInput
                                formControlName="avaliableminutes"
                                placeholder="Enter available minutes"
                                maxlength="2"
                                (keypress)="assignnoNumber($event)"
                                readonly
                                class="form-control email-input select-placeholder"
                              />
                            </div>
                            <ng-container *ngIf="empTaskId;else elseTemplate">
                              <div class="full-width col-sm-3" id="loginform" appearance="fill">
                                <label class="form-control-label">Assigned Hours<span class="text-danger">*</span></label>
                                <input
                                  matInput
                                  formControlName="assignedhours"
                                  placeholder="Enter assigned hours"
                                  maxlength="2"
                                  (keypress)="noNumber($event)"
                                  autocomplete="off"
                                  (change)="set($event)"
                                  class="form-control email-input select-placeholder"
                                />
                                <div *ngIf="(assignedhours.invalid &&  submitted2 ) || assignedhours.dirty">
                                    <small *ngIf="assignedhours.errors?.required" class="text-danger">Assigned hours is required</small>
                                </div>
                              </div>
                  
                              <div class="full-width col-sm-3" id="loginform" appearance="fill">
                                <label class="form-control-label">Assigned Minutes<span class="text-danger">*</span></label>
                                <input
                                  matInput
                                  formControlName="assignedminutes"
                                  placeholder="Enter assigned minutes"
                                  maxlength="2"
                                  (keypress)="noNumber($event)"
                                  autocomplete="off"
                                  (change)="set($event)"
                                  class="form-control email-input select-placeholder"
                                />
                                <div *ngIf="(assignedminutes.invalid && submitted2) || assignedminutes.dirty">
                                    <small *ngIf="assignedminutes.errors?.required" class="text-danger">Assigned minutes is required</small>
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #elseTemplate>
                              <div class="full-width col-sm-3" id="loginform" appearance="fill">
                                <label class="form-control-label">Assign Hours<span class="text-danger">*</span></label>
                                <input
                                  matInput
                                  formControlName="assignedhours"
                                  placeholder="Enter assign hours"
                                  maxlength="2"
                                  (keypress)="noNumber($event)"
                                  autocomplete="off"
                                  (change)="set($event)"
                                  class="form-control email-input select-placeholder"
                                />
                                <div *ngIf="(assignedhours.invalid && submitted2 ) || assignedhours.dirty">
                                    <small *ngIf="assignedhours.errors?.required" class="text-danger">Assigned hours is required</small>
                                </div>
                              </div>
                  
                              <div class="full-width col-sm-3" id="loginform" appearance="fill">
                                <label class="form-control-label">Assign Minutes<span class="text-danger">*</span></label>
                                <input
                                  matInput
                                  formControlName="assignedminutes"
                                  placeholder="Enter assign minutes"
                                  maxlength="2"
                                  (keypress)="noNumber($event)"
                                  autocomplete="off"
                                  (change)="set($event)"
                                  class="form-control email-input select-placeholder"
                                />
                                <div *ngIf="(assignedminutes.invalid && submitted2 ) || assignedminutes.dirty">
                                    <small *ngIf="assignedminutes.errors?.required" class="text-danger">Assigned minutes is required</small>
                                </div>
                              </div>
                            </ng-template>
                  
                          </div>
                  
                          <div class="row">
                            <!-- Task Start Date -->
                            <div class="input-width col-sm-6" id="loginform">
                              <label class="form-control-label"> StartDate<span class="text-danger">*</span></label>
                              <input
                                matInput
                                [matDatepicker]="picker3"
                                (click)="picker3.open()"
                                formControlName="startdate"
                                [min]="minData"
                                [max]="maxdata"
                                (dateChange)="assignsomeMethodName($event)"
                                class="form-control email-input select-placeholder"
                                placeholder="Enter startdate"
                              />
                              <mat-datepicker-toggle matSuffix [for]="picker3" style="float: right;position: relative;bottom: 42px;">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker3 touchUi></mat-datepicker>
                                <div *ngIf="(startdate.invalid && submitted2 ) || startdate.dirty">
                                    <small *ngIf="startdate.errors?.required" class="text-danger">Start date is required</small>
                                </div>
                            </div>
                  
                            <!-- Task End date -->
                            <div class="input-width col-sm-6" id="loginform">
                              <label class="form-control-label">EndDate<span class="text-danger">*</span></label>
                              <input
                                matInput
                                [matDatepicker]="picker4"
                                (click)="picker4.open()"
                                formControlName="enddate"
                                [min]="minStart"
                                [max]="maxdata"
                                [disabled]="endDate_disable"
                                class="form-control email-input select-placeholder"
                                placeholder="Enter enddate"
                              />
                              <mat-datepicker-toggle matSuffix [for]="picker4" style="float: right;position: relative;bottom: 42px;"></mat-datepicker-toggle>
                              <mat-datepicker #picker4 touchUi></mat-datepicker>
                                <div *ngIf="(enddate.invalid && submitted2 ) || enddate.dirty">
                                    <small *ngIf="enddate.errors?.required" class="text-danger">End date is required</small>
                                </div>
                            </div>
                        </div>
                        <div class="row form-content2 m-0 float-right">
                            <div class="form-previous">
                              <button type="submit" class="btn" mat-raised-button (click)="changeProjectTab(0)"
                              style="width:100%;background-color: #fb9778;color:white">Previous</button>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="">
                              <button type="submit" class="btn" mat-raised-button (click)="Create_task()" *ngIf="disableButton"
                              style="width:100%;background-color: #03c9d7;color:white">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>  
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
