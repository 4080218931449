<body class="overflow: auto; background-color:#fafbfb!important;">
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <mat-card class="card-content">
            <div>
              <span class="material-icons dashboard-icon">dashboard</span>
            </div>
            <div class="card__stats__item card__stats__item--req" align="end">
              <p>{{ projectCount }}</p>
              <span class="card__stats__type">Projects</span>
            </div>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <mat-card class="card-content">
            <div>
              <span class="material-icons badge-icon">badge</span>
            </div>
            <div class="card__stats__item card__stats__item--req" align="end">
              <p>{{ clientCount }}</p>
              <span class="card__stats__type">Clients</span>
            </div>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <mat-card class="card-content">
            <div>
              <span class="material-icons task-icon1">task</span>
            </div>
            <div class="card__stats__item card__stats__item--req" align="end">
              <p>{{ taskCount }}</p>
              <span class="card__stats__type">Tasks</span>
            </div>
          </mat-card>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <mat-card class="card-content">
            <div>
              <span class="material-icons">people_alt</span>
            </div>
            <div class="card__stats__item card__stats__item--req" align="end">
              <p>{{ employeeCount }}</p>
              <span class="card__stats__type">Employees</span>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-card class="chart-card">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <mat-select
                    class="form-control email-input select-placeholder"
                    placeholder="Select Project"
                    (selectionChange)="getproject($event)"
                  >
                    <mat-option [value]="0">--Select Project--</mat-option>
                    <mat-option
                      *ngFor="let project of projectList"
                      [value]="project.projectId"
                    >
                      {{ project.projectName }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
            <div *ngIf="projectBudgetHourslist.length != 0">
              <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [yaxis]="chartOptions.yaxis"
                [legend]="chartOptions.legend"
                [fill]="chartOptions.fill"
                [stroke]="chartOptions.stroke"
                [tooltip]="chartOptions.tooltip"
                [xaxis]="chartOptions.xaxis"
              ></apx-chart>
            </div>
            <div class="card-body" *ngIf="nodatachart">
              <!-- <ng-template #elseProject> -->
              <div class="alert alert-primary">
                <h4 align="center">No Budget Hours Found</h4>
              </div>
              <!-- </ng-template> -->
            </div>
          </mat-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-card class="card-content">
            <div
              class="table-responsive"
              *ngIf="notsubmittedtaskreport.length > 0"
            >
              <div class="row p-0 m-0">
                <div class="col-xl-12 col-lg-12">
                  <div
                    class="select-pagination"
                    *ngIf="notsubmittedtaskreport.length > 5"
                  >
                    <span>Show : </span>
                    <select
                      class="select_list new-select_list"
                      [(ngModel)]="mySelect"
                    >
                      <option class="form-control" value="5">5</option>
                      <option class="form-control" value="10">10</option>
                      <option class="form-control" value="15">15</option>
                      <option class="form-control" value="20">20</option>
                      <option class="form-control" value="25">25</option>
                    </select>
                  </div>
                </div>
              </div>
              <table class="table" matSort (matSortChange)="sortData($event)">
                <thead>
                  <tr class="tr">
                    <th style="text-align: center">Sl.No</th>
                    <th mat-sort-header="employeeName">
                      Empolyee&nbsp;&nbsp;Name
                    </th>
                    <th mat-sort-header="organizationEmail">
                      Empolyee&nbsp;&nbsp;Email
                    </th>
                    <th style="text-align: center">
                      Time&nbsp;Sheet&nbsp;Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let e of notsubmittedtaskreport
                        | paginate : { itemsPerPage: mySelect, currentPage: p };
                      let i = index
                    "
                  >
                    <td style="text-align: center" *ngIf="p">
                      {{ (p - 1) * mySelect + (i + 1) }}
                    </td>
                    <td style="text-align: center" *ngIf="!p">{{ i + 1 }}</td>
                    <td>{{ e.employeeName }}</td>
                    <td>{{ e.organizationEmail }}</td>
                    <td class="more">
                      <span class="material-icons-outlined icon-red">
                        cancel
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination-controls
                (pageChange)="p = $event"
                *ngIf="notsubmittedtaskreport.length > 5"
                align="end"
              ></pagination-controls>
            </div>
            <div class="card-body" *ngIf="nodata">
              <div class="alert alert-primary">
                <h4 align="center">No Employees Assigned</h4>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</body>
<div *ngIf="loader" class="align-items-center text-center">
  <div
    class="spinner-border"
    style="width: 3rem; height: 3rem"
    role="status"
  ></div>
</div>
